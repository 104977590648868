import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import {NumberInput} from '../../../../elements/Input';
import Select from '../../../../elements/Select';

const autoTimelogOptions = [{ value: 'enabled', label: 'ON auto start', title: 'ON auto start' },
                            { value: 'disabled', label: 'OFF auto start', title: 'OFF auto start' },
                            { value: 'gps', label: 'GPS check current position, Auto login', title: 'GPS check current position, Auto login' },
                            { value: 'gpsmanual', label: 'GPS check current position, Approve login manually',title: 'GPS check current position, Approve login manually' }]

const autoLogoutOptions = [{ value: 'current', label: 'Save current time', title:'Save current time' },
                            { value: 'old', label: 'Save time when left the workplace',title:'Save time when left the workplace' },]

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class LoginRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginRules: {
                autoLogout: 'current',
                autoTimelogs:'gps',
                gpsAllowedDistance: 0,
                gpsAllowedTime: 0,
            },
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this); 
        this.handleUserDataChange = this.handleUserDataChange.bind(this); 
        this.translateLabels = this.translateLabels.bind(this); 
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {                
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const {loginRules} = this.state;
        if (currentEntity.client.data && currentEntity.client.data.loginRules) {
            const _loginRules = Object.assign({}, currentEntity.client.data.loginRules);
            loginRules.autoLogout = _loginRules.autoLogout?_loginRules.autoLogout:'current';
            loginRules.autoTimelogs = _loginRules.autoTimelogs?_loginRules.autoTimelogs:'gps';
            loginRules.gpsAllowedDistance = _loginRules.gpsAllowedDistance?_loginRules.gpsAllowedDistance:0;
            loginRules.gpsAllowedTime = _loginRules.gpsAllowedTime?_loginRules.gpsAllowedTime:0;
            this.setState({loginRules})
        }
    }

    handleUserDataChange = (name,value) =>{
        console.log(name,value);
        const {currentEntity} = this.props.clientStore;
        if(currentEntity.client.data.loginRules){
            currentEntity.client.data.loginRules[name]=value;
        }else{
            console.log("No login rules, creating new.");
            let loginRules = {} ;
            loginRules[name]=value;
            currentEntity.client.data.loginRules = loginRules;
        }
    }

    handleChange = (name, value) => {
        const { loginRules } = this.state;
        loginRules[name] = value;
        this.handleUserDataChange(name, value)
        this.setState({ loginRules });
    }

    translateLabels(){
        const {t} = this.props;
        autoTimelogOptions.map(item=>{
            item.label = t(item.title)
        })
        autoLogoutOptions.map(item=>{
            item.label = t(item.title)
        })
    }

    render() {
        const { loginRules } = this.state;
        const { t } = this.props;
        this.translateLabels()
        return (
            <div>
                <Row>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('GPS max allowed distance (m)')}`}                        
                            name="gpsAllowedDistance"
                            value={loginRules.gpsAllowedDistance}
                            onChange={evt => {
                                this.handleChange('gpsAllowedDistance', parseInt(evt.target.value,10));
                            }}
                        />
                    </Col>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('GPS max allowed time (min)')}`}
                            name="gpsAllowedTime"
                            value={loginRules.gpsAllowedTime}
                            onChange={evt => {
                                this.handleChange('gpsAllowedTime', parseInt(evt.target.value,10));
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <label className="control-label">{t('Automatic timelogs enabled')}</label>
                        <Select
                            options={autoTimelogOptions}
                            value={autoTimelogOptions.find(item=> item.value === loginRules.autoTimelogs)}                        
                            onChange={selectedItem => this.handleChange('autoTimelogs',selectedItem.value )}                            
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={6}>
                        <label className="control-label">{t('Automatic Logout')}</label>
                        <Select
                            options={autoLogoutOptions}
                            value={autoLogoutOptions.find(item=> item.value === loginRules.autoLogout)}                       
                            onChange={selectedItem => this.handleChange('autoLogout',selectedItem.value )}                            
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default LoginRules;
