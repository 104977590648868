import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import _get from 'lodash/get';

import withLocalization from '../../../hoc/withLocalization';

import config from '~/library/config';
import getBase64 from '~/utils/getBase64';

@inject('userStore', 'commonStore')
@withLocalization
@observer
class AttachmentsWidgetMobx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uiAttachments: [],
        };
    }

    componentDidMount() {
        this.pushAttachmentFromProps();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.options.imageContext) !== JSON.stringify(this.props.options.imageContext)) {
            this.pushAttachmentFromProps();
        }
    }

    getUrl = file => {
        return `${config.UPLOADS_API_ENDPOINT}/${file}`;
    };

    onUpload = e => {
        const {
            onChange, onUpload, options, userStore, commonStore, t
        } = this.props;
        const { files } = e.target;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const { name } = file;
            if (this.isImage(name) && options.checkDate) {
                // console.log("???", name, this.isImage(name), options.checkDate, file);
                // console.log(EXIF.getData(file));
                /* if (!file.exifData
          || !file.exifData.DateTime
          || moment(new Date()).diff(moment(new Date(file.exifData.DateTime)), 'hour') > 2) {
          commonStore.addNotification(t('Allowed to use files created no earlier than 2 hours ago'), null, 'error');
          return;
        } */
            }

            getBase64(file)
                .then(attachData => {
                    userStore
                        .fileUpload({
                            to_model: options.imageContext.model,
                            to_id: options.imageContext.id,
                            user_id: userStore.currentUser.id,
                            filename: name,
                            filetype: options.imageContext.fileType,
                            content: attachData,
                            data: options.imageContext.data || {},
                        })
                        .then(newId => {
                            if (onUpload) onUpload(name);
                            const arrayIds = this.props.value ? this.props.value.split(',') : [];
                            arrayIds.push(newId);
                            onChange(`${arrayIds.join(',')}`);

                            const uiAttachment = {
                                id: newId,
                                filename: name,
                                filetype: options.imageContext.fileType,
                            };
                            this.addUiAttachment(uiAttachment);
                        });
                    this.fileInput.value = '';
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    addUiAttachment = attachment => {
        // Clone Array
        const uiAttachments = this.state.uiAttachments.slice(0);

        uiAttachments.push(attachment);
        this.setState({
            uiAttachments,
        });
    };

    removeUiAttachment = id => {
        // Clone Array
        const uiAttachments = this.state.uiAttachments.slice(0);

        uiAttachments.filter(attachment => String(attachment.id) !== String(id));
        this.setState({
            uiAttachments,
        });
    };

    removeAttachment = removedId => {
        const { onChange, value } = this.props;
        const arrayIds = value ? value.split(',') : [];
        const newArrayIds = arrayIds.filter(id => String(id) !== String(removedId));
        onChange(`${newArrayIds.join(',')}`);
        this.removeUiAttachment(removedId);
    };

    pushAttachmentFromProps = () => {
        const { existingAttachment } = this.props.options.imageContext;
        if (existingAttachment) {
            this.setState({
                uiAttachments: existingAttachment,
            });
        }
    };

    isImage = filename => {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        return !!allowedExtensions.exec(filename);
    };

    renderItem = attachment => (
        <div className="uploads-item" key={attachment.id}>
            <div className="uploads-item__file">
                {this.isImage(attachment.filename) ? (
                    <img
                        src={`${config.UPLOADS_API_ENDPOINT}/${attachment.id}`}
                        alt={attachment.filename}
                        className="uploads-item__img"
                        onClick={() => window.open(this.getUrl(attachment.id), '_blank')}
                    />
                ) : (
                    <i
                        className="uploads-item__icon pe-7s-file"
                        onClick={() => window.open(this.getUrl(attachment.id), '_blank')}
                    />
                )}
                {!this.props.disabled && (
                    <i
                        className="uploads-item__delete pe-7s-close-circle"
                        onClick={() => this.removeAttachment(attachment.id)}
                    />
                )}
            </div>
            <span className="uploads-item__title" title={attachment.filename}>
                {attachment.filename}
            </span>
        </div>
    );

    render() {
        const {
            value, groups = [], group: userGroup, t, disabled
        } = this.props;
        const { uiAttachments } = this.state;
        const attachmentsIds = value ? value.split(',') : [];
        const attachments = uiAttachments.filter(item => attachmentsIds.indexOf(String(item.id)) !== -1);
        if (!groups.length) {
            return (
                <div className="uploads">
                    {attachments && attachments.map(attachment => this.renderItem(attachment))}
                    {!disabled && (
                        <button className="uploads__add-button" type="button" onClick={() => this.fileInput.click()}>
                            <i className="pe-7s-cloud-upload" />
                        </button>
                    )}
                    {!disabled && (
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            ref={fileInput => {
                                this.fileInput = fileInput;
                            }}
                            onChange={this.onUpload}
                            multiple
                        />
                    )}
                </div>
            );
        }

        // GROPED ATTACHMENTS
        const allGroupKeys = groups.map(group => group.key);
        const ungroupedItems = attachments.filter(attach => {
            const group = _get(attach, 'data.group', 'ungrouped');
            if (!attach.user_id && allGroupKeys.indexOf(userGroup) !== -1) {
                return false;
            }
            return allGroupKeys.indexOf(group) === -1;
        });

        return (
            <div className="uploads uploads_with-groups">
                {groups.map(group => {
                    const groupedAttachments = attachments.filter(attach => {
                        if (!attach.user_id && userGroup === group.key) {
                            return true;
                        }
                        return attach.data && attach.data.group === group.key;
                    });
                    if (groupedAttachments.length) {
                        return (
                            <div className="input-area" key={group.key}>
                                <span className="input-area__label">{group.label}</span>
                                <div className="input-area__content">
                                    {groupedAttachments.map(attachment => this.renderItem(attachment))}
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
                {!!ungroupedItems.length && (
                    <div className="input-area">
                        <span className="input-area__label">{t('Other Attachments')}</span>
                        <div className="input-area__content">
                            {ungroupedItems.map(attachment => this.renderItem(attachment))}
                        </div>
                    </div>
                )}
                {!disabled && (
                    <button
                        className="uploads__add-button"
                        style={{ margin: '10px auto' }}
                        type="button"
                        onClick={() => this.fileInput.click()}
                    >
                        <i className="pe-7s-cloud-upload" />
                    </button>
                )}
                {!disabled && (
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        ref={fileInput => {
                            this.fileInput = fileInput;
                        }}
                        onChange={this.onUpload}
                        multiple
                    />
                )}
            </div>
        );
    }
}

@observer
class AttachmentsWidget extends Component {
    render() {
        return <AttachmentsWidgetMobx {...this.props} />;
    }
}

export default AttachmentsWidget;
