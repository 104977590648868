import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col } from 'react-bootstrap';

import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { minutesToTimeInput } from '../../../utils/timeCalc';

import FlexCard from '~/components/Card/FlexCard.jsx';

@inject('timelogStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class TimelogDashboardWidget extends Component {
    state = {
        status: null,
    };

    componentWillMount() {
        this.loadData();
    }

    async loadData() {
        const {
            timelogStore, id, add, mode, history, commonStore
        } = this.props;
        const status = await timelogStore.getCurrentDraftOrSkip('', '', true);
        this.setState({ status });
        return null;
    }

    async handleStop(action) {
        const {
            timelogStore, id, add, mode, history, commonStore, t
        } = this.props;
        const timelog = await timelogStore.finishByButton(this.state.status.timelog);
        commonStore.addNotification(t('Your timelog is now ready to save'), null, 'success');
        history.push(`/admin/timelogs/${timelog.id}`);
    }

    async handleStart() {
        const {
            timelogStore, id, add, mode, history, commonStore, t
        } = this.props;
        const { status } = this.state;
        if (!status.timelog && !status.shift) {
            commonStore.addNotification(t('Please choose the task and click Start'), null, 'warning');
            history.push('/admin/timelogs/add');
            return;
        }
        const draft = await timelogStore.getPossibleDraftId({
            project_id: status.shift.project_id,
            task_id: status.shift.task_id,
        });
        console.log(draft);
        this.setState({ status: draft });
        this.loadData();
        return null;
    }

    renderStartButton() {
        const { t } = this.props;
        return (
            <div className="flex-card-footer-buttons">
                <Button fill style={{ width: '100%' }} onClick={() => this.handleStart()}>
                    {t('Start')}
                </Button>
            </div>
        );
    }

    async onPause() {
        const { status } = this.state;
        const { timelogStore, commonStore, t } = this.props;
        const isPaused = status.timelog.tracker_status === 'break';
        await timelogStore.breakByButton(status.timelog.id, isPaused ? 'stop' : 'start', false);
        this.loadData();
    }

    renderStopAndBreak = () => {
        const { status } = this.state;
        const { t } = this.props;
        const isPaused = status.timelog.tracker_status === 'break';
        return (
            <div className="flex-card-footer-buttons">
                <Button fill onClick={() => this.handleStop()}>
                    {t('Finish')}
                </Button>
                <Button onClick={() => this.onPause()}>{isPaused ? t('Finish break') : t('Start break')}</Button>
            </div>
        );
    };

    render() {
        const { timelogStore, commonStore, t } = this.props;
        const { status } = this.state;

        //console.log('wut', status);
        if (!status) return null;

        return (
            <Fragment>
                <Col sm={6} lg={3}>
                    {!status.timelog && !status.shift && (
                        <FlexCard
                            title={t('Timelog')}
                            desc={t('No work right now')}
                            icon={<i className="pe-7s-clock text-warning" />}
                            footer={this.renderStartButton()}
                        />
                    )}
                    {!!status.shift && !status.timelog && (
                        <FlexCard
                            title={t('Timelog')}
                            desc={`${status.shift.project_name} / ${status.shift.task_name}`}
                            icon={<i className="pe-7s-clock text-warning" />}
                            footer={this.renderStartButton()}
                        />
                    )}
                    {!!status.timelog && (
                        <FlexCard
                            title={t('Timelog')}
                            desc={`${t('Started at')} ${minutesToTimeInput(status.timelog.from, true)}`}
                            icon={<i className="pe-7s-clock text-success" />}
                            footer={this.renderStopAndBreak()}
                        />
                    )}
                </Col>
            </Fragment>
        );
    }
}

export default TimelogDashboardWidget;
