import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import KilometerRules from './KilometerRules';
import VacationTypes from './VacationTypes';
import AbsentTypes from './AbsentTypes';
import ExpenseTypes from './ExpenseTypes';
import ExtraworkTypes from './ExtraworkTypes';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ExtendedRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subTab: 'kilometerRules',
        }
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillMount() {        
    }

    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    handleUserDataChange = (name,value) =>{
        console.log(name,value);
        const {currentEntity} = this.props.clientStore;
        if(currentEntity.client.data.extendedRules){
            currentEntity.client.data.extendedRules[name]=value;
        }
        else{
            console.log("No extended rules")
            // let basicRules = {} ;
            // basicRules[name]=value;
            // currentEntity.client.data.basicRules = basicRules;
        }
    }

    render() {
        const { subTab } = this.state;
        const { t } = this.props;
        return (
            <Tab.Container activeKey={subTab} id="ExtendedTabContainer" onSelect={(key)=>console.log(key)}>
                <div className="custom-card">
                    <Row>
                        <Col sm={3}>
                            <ul className="list-group" >
                                <li className={subTab === 'kilometerRules' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('kilometerRules')}>{t('Rules for Kilometers')}</li>
                                <li className={subTab === 'vacationTypes' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('vacationTypes')}>{t('Leave types')}</li>
                                <li className={subTab === 'absentTypes' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('absentTypes')}>{t('Absence types')}</li>
                                <li className={subTab === 'expenseTypes' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('expenseTypes')}>{t('Expense types')}</li>
                                <li className={subTab === 'extraworkTypes' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('extraworkTypes')}>{t('Extra Work Types')}</li>
                            </ul>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content >
                                <Tab.Pane eventKey="kilometerRules">
                                    <KilometerRules 
                                    handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="vacationTypes">
                                    <VacationTypes 
                                    handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="absentTypes">
                                    <AbsentTypes 
                                    handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="expenseTypes">
                                    <ExpenseTypes 
                                    handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="extraworkTypes">
                                    <ExtraworkTypes 
                                    handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </div>
            </Tab.Container>
        );
    }
}
export default ExtendedRules;
