import {
    observable, action, reaction, runInAction, computed
} from 'mobx';

import agent from '../agent';

class CommonStore {
    // @observable locale = window.localStorage.getItem('locale') || 'no';
    @observable locale = window.sessionStorage.getItem('locale') || 'no';

    @observable appName = 'NorskTime';

    @observable connectionError = false;

    @observable config = {};

    @observable systemAds = [];

    // @observable token = window.localStorage.getItem('jwt');
    @observable token = window.sessionStorage.getItem('jwt');

    @observable appLoaded = false;

    @observable currentGitRev = null;

    constructor() {
        reaction(
            () => this.token,
            token => {
                if (token) {
                    // window.localStorage.setItem('jwt', token);
                    window.sessionStorage.setItem('jwt', token);
                } else {
                    // window.localStorage.removeItem('jwt');
                    window.sessionStorage.removeItem('jwt');
                }
            }
        );
    }

    @action async getGitRev() {
        try {
            const rev = await agent.Users.gitRev();
            return rev.rev;
        } catch (e) {
            return 'local';
        }
    }

    @action setGitRev(rev) {
        this.currentGitRev = rev;
    }

    @action setToken(token) {
        this.token = token;
        if (token) this.setAppLoaded();
    }

    @action setAppLang(lang) {
        this.locale = lang;
    }

    @action setAppLoaded() {
        if (!this.token) {
            this.appLoaded = true;
            return null;
        }
        return agent.Auth.config()
            .then(
                action(config => {
                    this.config = config;
                    this.connectionError = false;
                    this.appLoaded = true;
                    return config;
                })
            )
            .catch(e => {
                this.connectionError = true;
                this.appLoaded = true;
            });
    }

    @action getConfig() {
        return this.config;
    }

    @action log(log) {
        agent.Auth.log(log);
    }

    notifications = observable.map();

    /** Track the `uid`s of `Notification`s which have been added (`onAdd` callback was triggered) */
    _displayedUids = observable.array();

    _newUid() {
        return new Date().getTime().toString();
    }

    @computed
    get PendingNotifications() {
        const pns = [];
        for (const notif of this.notifications.values()) {
            if (!this._displayedUids.includes(notif.uid)) {
                pns.push(notif);
            }
        }
        return pns;
    }

    /**
     * Create a notification displayed with `react-notification-system`
     *
     * @param message Message of the notification.
     * @param [title=null] Title of the notification.
     * @param [level="info"] Level of the notification. Available: success, error, warning and info.
     * @param [position="tc"] Position of the notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center).
     * @param [autoDismiss=5] Delay in seconds for the notification go away. Set this to 0 to not auto-dismiss the notification.
     */
    @action
    addNotification(message, title = null, level = 'info', position = 'tr', autoDismiss = 3) {
        const uid = this._newUid();

        if (this.notifications.values) {
            for (const notif of this.notifications.values()) {
                if (notif.message === message) return;
            }
        }

        this.notifications.set(uid, {
            uid,
            title,
            message,
            level,
            position,
            autoDismiss,
            onAdd: n => runInAction('rns onAdd', () => this._displayedUids.push(n.uid)),
            onRemove: n => runInAction('rns onRemove', () => {
                this.notifications.delete(n.uid);
                this._displayedUids.remove(n.uid);
            }),
        });
    }

    @action
    setLocale(lang) {
        agent.Auth.setLang(lang);
    }

    @action getUsername(id) {
        if (!this.config || !this.config.pms) return '';
        return this.config.pms[id];
    }

    @action sendContact(params) {
        return agent.Dashboard.contact(params);
    }
}

export default new CommonStore();
