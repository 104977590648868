


import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react'

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '~/hoc/withLocalization';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col,Modal } from 'react-bootstrap';
import MultiSelect from '@khanacademy/react-multi-select';
import AddBanner from './AddBanner';
// import { Input } from '../../elem';

//styles
import '../../../styles/css/styles.css';
import './Banner.css';


@inject('bannerStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class Banner extends Component {
    state = {
    }

    render() {
        const {t,banner_view,bannersInfo} = this.props;
        
        return (
            <div className='banner-view'>
                <Modal size="lg" show={banner_view} onHide={this.props.closeModal}>
                    <Modal.Header closeButton>
                       
                    </Modal.Header>
                    <Modal.Body>
                    {
                        bannersInfo.map(data=>{
                           return(
                               <>
                                    <h3 style={{textAlign:'center'}}>{data.name || 'No Name Found'}</h3>
                                    <div className='center-block' style={{ display: 'flex', alignItems: 'center',justifyContent:'center' }}>
                                        <ReactQuill
                                            theme={"bubble"}
                                            value={data.body || 'No Body found'}
                                            modules={{ toolbar: false, }}
                                            readOnly={true}
                                            style={{ border: '0px',width:'100%', minHeight:'100px', color:'black',marginTop:'0.5em'}}
                                            // preserveWhitespace={true}
                                        />
                                    </div>
                                    {
                                        data.banner_readers && data.banner_readers.length>0 &&(
                                            <div style={{marginTop:'20px'}}>
                                                <table className="table table-hover table-striped project-members-form">
                                                <thead>
                                                    <tr style={{ backgroundColor: '#447df7' }}>
                                                        <th style={{ color: 'white', fontWeight: '900' }}>{t('Member')}</th>
                                                        <th style={{ color: 'white', fontWeight: '900' }}>{t('Status')}</th>
                                                    </tr>
                                                </thead>
                                                    <tbody>
                                                        {data.banner_readers.map(member => (
                                                            <tr key={`${member.id}`}>
                                                                <td>{member.reader_name}</td>
                                                                <td>{member.status}</td>
                                                               
                                                            </tr>
                                                        ))}
                                                </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                               </>

                           )
                        })
                    }
                  
                    </Modal.Body>
                </Modal>
               
            </div> 
        )
    }
}

export default Banner;
