import { observable, action } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import _ from 'lodash';

import agent from '../agent';

let projectAddressThrottleTimer = null;

class ProjectStore {
    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable currentProjectsLookup = {};

    @observable loadingLookup = false;

    @observable gpsNowLoading = false;

    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @observable lastListLoadTime = null;

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @action loadList(params) {
        return agent.Projects.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action returnDefaultNew(params) {
        this.currentEntity = { project: { name: null, data: {} } };
        this.loading = false;
    }

    @action load(id) {
        this.loading = true;
        return agent.Projects.load(id)
            .then(
                action(response => {
                    if (!response.project.data) response.project.data = {};
                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action getProjectMembersIds(id) {
        this.loading = true;
        return agent.Projects.getProjectMembersIds(id)
            .then(
                action(response => {
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    
    @action getProjectPms(id) {
        this.loading = true;
        return agent.Projects.getProjectPms(id)
            .then(
                action(response => {
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }


    @action save(values, isAdd) {
        this.updating = true;
        return agent.Projects.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id, confirm) {
        const res = await agent.Projects.remove(id, confirm);
        if (confirm) {
            this.deleteSuccess = true;
            return 1;
        }
        return res;
    }

    @action async loadLookup(_mode, name, __module) {
        console.log('Loading lookup', _mode);
        const mode = _mode || 'all';
        const _module = __module || "no-module";
        this.loadingLookup = true;
        const list = await agent.Projects.lookupByName(mode, name, _module);
        this.currentProjectsLookup[mode] = list;
        this.loadingLookup = false;
        return list;
    }

    @action async performRecalcGpsForProject(newAddress) {
        this.gpsNowLoading = true;
        console.log('ugh...', newAddress);
        this.currentEntity.project.data.addressCache = newAddress;
        const tsk = _.cloneDeep(this.currentEntity.project);
        try {
            const data = await agent.Tasks.gpsAddress(newAddress);
            tsk.gps_data = data.result;
            tsk.data.addressCache = newAddress;
            this.currentEntity = { project: tsk };
            this.gpsNowLoading = false;
        } catch (e) {
            this.gpsNowLoading = false;
            throw e;
        }
    }

    @action async recalcGpsForProject(changes) {
        const entityToAddressString = e => {
            return `${e.address}, ${e.post_place}, ${e.post_number}`;
        };

        if (!changes.address || !changes.post_place) {
            // || !changes.post_number
            // not filled
            return changes;
        }

        const newAddress = entityToAddressString(changes);

        if (newAddress === this.currentEntity.project.data.addressCache) return changes;

        projectAddressThrottleTimer = new Date().getTime();
        setTimeout(() => {
            // throttle
            if (new Date().getTime() - projectAddressThrottleTimer < 1000) {
                return;
            }
            if (this.currentEntity.project.data.addressCache === newAddress) {
                console.log('address same');
                return;
            }
            this.performRecalcGpsForProject(newAddress);
        }, 1000);
        return changes;
    }
}

export default new ProjectStore();
