import { observable, action } from 'mobx';
import _ from 'lodash';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

import userStore from './userStore';

let addressThrottleTimer = null;

class TaskStore {
    @observable currentList = {};

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable currentTasksLookup = [];

    @observable loadingLookup = false;

    @observable lastListLoadTime = null;

    @observable gpsNowLoading = false;

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @observable filters = {
        status: '',
        name: '',
        project: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
        project: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action loadList(params) {
        return agent.Tasks.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action returnDefaultNew(params) {
        this.currentEntity = {
            task: {
                assignee_id: '',
                name: null,
                data: {},
                start_time: 8 * 60,
                end_time: 17 * 60,
                scheduled_days: '0,1,2,3,4',
            },
        };
        this.loading = false;
    }

    @action load(id) {
        this.loading = true;
        return agent.Tasks.load(id)
            .then(
                action(response => {
                    if (!response.task.data) response.task.data = {};
                    if (response.task.Members && response.task.Members.length) {
                        response.task.assignee_id = response.task.Members.map(r => r.user_id).join(',');
                    } else {
                        response.task.assignee_id = '';
                    }

                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        return agent.Tasks.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.Tasks.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    // @action async loadLookup(_mode, name) {
    //   const mode = 'all';
    //   this.loadingLookup = true;
    //   const list = await agent.Tasks.lookupByName(mode, name);
    //   this.currentTasksLookup[mode] = list;
    //   this.loadingLookup = false;
    //   return list;
    // }
    @action loadLookup(mode, name) {
        return agent.Tasks.lookupByName(name)
            .then(
                action(list => {
                    // this.requestParams = params;
                    this.currentTasksLookup = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action async performRecalcGpsForTask(newAddress, userInsteadOfTask) {
        //console.log('U2', userInsteadOfTask);
        this.gpsNowLoading = true;
        let tsk;
        if (!userInsteadOfTask) {
            this.currentEntity.task.data.addressCache = newAddress;
            tsk = _.cloneDeep(this.currentEntity.task);
        } else {
            userStore.currentEntity.user.data.addressCache = newAddress;
            tsk = _.cloneDeep(userStore.currentEntity.user);
        }
        try {
            const data = await agent.Tasks.gpsAddress(newAddress);
            tsk.gps_data = data.result;
            tsk.data.addressCache = newAddress;
            if (!userInsteadOfTask) {
                this.currentEntity = { task: tsk };
            } else {
                userStore.currentEntity = { user: tsk };
            }
            this.gpsNowLoading = false;
        } catch (e) {
            this.gpsNowLoading = false;
            throw e;
        }
    }

    @action async recalcGpsForTask(changes, userInsteadOfTask) {
        //console.log('U', userInsteadOfTask);        
        const entityToAddressString = e => {
            return `${e.address}, ${e.post_place}, ${e.post_number}`;
        };

        if (!changes.address || !changes.post_place) {
            // || !changes.post_number
            // not filled
            return changes;
        }

        const newAddress = entityToAddressString(changes);

        if (!userInsteadOfTask) {
            if (newAddress === this.currentEntity.task.data.addressCache) return changes;
        } else if (newAddress === userStore.currentEntity.user.data.addressCache) return changes;

        addressThrottleTimer = new Date().getTime();
        setTimeout(() => {
            // throttle
            if (new Date().getTime() - addressThrottleTimer < 1000) {
                return;
            }
            if (!userInsteadOfTask) {
                if (this.currentEntity.task.data.addressCache === newAddress) {
                    //console.log('address same');
                    return;
                }
            } else if (userStore.currentEntity.user.data.addressCache === newAddress) {
                //console.log('address same');
                return;
            }

            this.performRecalcGpsForTask(newAddress, userInsteadOfTask);
        }, 1000);
        return changes;
    }
}

export default new TaskStore();
