import v4 from 'uuid/v4';

import Clients from './containers/Admin/Clients/Clients';
import Dashboard from './containers/Admin/Dashboard';
import {
    PayrollReport,
    VacationReport,
    AssociateReport,
    WeeklyReport,
    GpsReport,
    EmployeeReport, EmployeeProjectReport, ProjectReport,
} from './containers/Admin/Reports/Reports';
import CompanyProfile from './containers/Admin/Clients/CompanyProfile';
import SendReport from './containers/Admin/SendReport/SendReport';
import LockedMode from './containers/Admin/Locked/LockedMode';
import RestaurantReport from './containers/Admin/Reports/RestaurantReport';
import DailyLogReport from './containers/Admin/Reports/DailyLogReport';
import Chat from './containers/Admin/Chat/Chat';
import UnderConstruction from './containers/Admin/UnderConstruction';
import Deviation from './containers/Admin/Deviation/Deviation';
import Permissions from './containers/Admin/Permissions/Permission';
import Documents from './containers/Admin/Document/Document';
import Visitors from './containers/Admin/Visitors/VisitorTable';
import VisitorAdd from './containers/Admin/Visitors/VisitorAdd';


import customerFeature from '~/utils/customerFeature';
import ControlPanel from '~/containers/Admin/Clients/ControlPanel';
import ControlPanelv2 from '~/containers/Admin/ControlPanel/ControlPanel';
import SuperAdmins from '~/containers/Admin/Users/SuperAdmins';
import BizTypesPage from '~/containers/Admin/BizTypes/BizTypes';
import Profile from '~/containers/Admin/Users/Profile';
import Files from '~/containers/Admin/Users/Files';
import Projects from '~/containers/Admin/Projects/Projects';
import Members from '~/containers/Admin/Users/Members';
import Tasks from '~/containers/Admin/Tasks/Tasks';
import Timelogs from '~/containers/Admin/Timelogs/Timelogs';
import { ExtraDocsContracts, ExtraDocsInternal } from '~/containers/Admin/ExtraDocs/ExtraDocs';

import EmployeeBoard from '~/containers/Admin/EmployeeBoard/EmployeeBoard';
import Absences from '~/containers/Admin/Absences/Absences';
import Vacations from '~/containers/Admin/Vacations/Vacations';
import Banner from './containers/Admin/Banner/Banner';
import BannerDetails from './containers/Admin/Banner/BannerDetails';

// "pm_hide_tasks":true, "pm_hide_vacations":true, "pm_hide_absences":true, "pm_no_register":true

const routes = [
    {
        collapse: true,
        collapseId: v4(),
        name: 'Time',
        icon: 'pe-7s-clock',
        views: [
            {
                path: '/timelogs',
                layout: '/admin',
                name: 'Working hours',
                component: Timelogs,
                allowed: user => user.user_type !== 'super-admin',
            },
            {
                path: '/absences',
                layout: '/admin',
                name: 'Absents',
                component: Absences,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (!customerFeature('allow_absents')) return false;
                    if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                    if (
                        (user.user_type === 'admin' || user.user_type === 'pm')
                        && customerFeature('pm_allow_editing_all')
                    ) return true;
                    if (user.user_type !== 'member' && customerFeature('pm_hide_absences')) return false;
                    return true;
                },
            },
            {
                path: '/vacations',
                layout: '/admin',
                name: 'Vacations',
                component: Vacations,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (!customerFeature('allow_vacations')) return false;
                    if (user.user_type === 'admin' && customerFeature('admin_hide_absences')) return false;
                    if (
                        (user.user_type === 'admin' || user.user_type === 'pm')
                        && customerFeature('pm_allow_editing_all')
                    ) return true;
                    if (user.user_type !== 'member' && customerFeature('pm_hide_vacations')) return false;
                    return true;
                },
            },
            {
                path: '/members',
                layout: '/admin',
                name: 'Users',
                icon: 'pe-7s-id',
                component: Members,
                allowed: user => user.user_type === 'admin' || user.user_type === 'pm',
            },
        ],
    },
    /* {
    path: '/managers',
    layout: '/admin',
    name: 'Contact Persons',
    icon: 'pe-7s-id',
    component: Managers,
    allowed: user => user.user_type === 'admin',
  }, */
    {
        collapse: true,
        collapseId: v4(),
        name: 'Projects',
        icon: 'pe-7s-wallet',
        views: [
            {
                path: '/projects',
                layout: '/admin',
                name: 'Projects',
                component: Projects,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (user.user_type === 'member' && !customerFeature('member_allow_see_projects')) return false;
                    return true;
                },
            },
            {
                path: '/tasks',
                layout: '/admin',
                name: 'Tasks',
                component: Tasks,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    // if (user.user_type !== 'member' && customerFeature('pm_hide_tasks')) return false;
                    return true;
                },
            },
        ],
    },    
    {
        collapse: true,
        collapseId: v4(),
        name: 'Control',
        icon: 'pe-7s-tools',
        views: [
            {
                path: '/deviation',
                layout: '/admin',
                name: 'Deviation',
                component: Deviation,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (!customerFeature('enable_deviations')) return false;
                    if (user.user_type === 'admin' && customerFeature('union_group')) return false;
                    return true;
                }, // disable to hide it from
            },
            {
                path: '/permissions',
                layout: '/admin',
                name: 'Permissions',
                component: Permissions,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    // if(user.user_type === 'admin') return false;
                    return true
                },
            },
            // {
            //     path: '/documents/templates',
            //     layout: '/admin',
            //     name: 'Documents',
            //     component: Documents,
            //     allowed: user => {
            //         // if (user.user_type === 'super-admin') return false;
            //         // if (!customerFeature('enable_deviations')) return false;
            //         // if (user.user_type === 'admin' && customerFeature('union_group')) return false;
            //         return true;
            //     },
            // },            
            // {
            //     path: '/documents',
            //     layout: '/admin',
            //     name: 'Documents',
            //     component: Documents,
            //     hide: true,
            //     allowed: user => {
            //         // if (user.user_type === 'super-admin') return false;
            //         // if (!customerFeature('enable_deviations')) return false;
            //         // if (user.user_type === 'admin' && customerFeature('union_group')) return false;
            //         return true;
            //     },
            // },
            // {
            //     path: '/documentAddEdit',
            //     layout: '/admin',
            //     name: 'DocumentAddEdit',
            //     component: DocumentAddEdit,
            //     hide: true,
            //     allowed: user => {
            //         // if (user.user_type === 'super-admin') return false;
            //         // if (!customerFeature('enable_deviations')) return false;
            //         // if (user.user_type === 'admin' && customerFeature('union_group')) return false;
            //         return true;
            //     },
            // },
            {
                path: '/internaldocs',
                layout: '/admin',
                name: 'Internal documents',
                component: ExtraDocsInternal,
                hide: true,
                allowed: user => true, // disable to hide it from
            },
            {
                path: '/contracts',
                layout: '/admin',
                name: 'Contracts',
                component: ExtraDocsContracts,
                hide: true,
                allowed: user => {
                    if (user.user_type === 'super-admin') return false;
                    if (customerFeature('union_group')) return false;
                    return true;
                }, // disable to hide it from
            },
            /* {
        path: '/logg',
        layout: '/admin',
        name: 'Logg',
        component: UnderConstruction,
        allowed: user => true, // disable to hide it from
      }, */
        ],
    },
    {
        path: '/chat',
        layout: '/admin',
        name: 'Chat',
        icon: 'pe-7s-users',
        component: Chat,
        allowed: user => user.user_type !== 'super-admin',
    },
    {
        path: '/banner',
        layout: '/admin',
        name: 'Banner',
        icon: 'pe-7s-news-paper',
        component: Banner,
        allowed: user => {
            if (user.user_type === 'super-admin') return false;
            if(user.user_type === 'member') return false;
            return true
        },
    },
    // {
    //     path: '/banner_details',
    //     layout: '/admin',
    //     name: 'BannerDetails',
    //     icon: 'pe-7s-users',
    //     component: BannerDetails,
    //     allowed: user => {
    //         if (user.user_type === 'super-admin') return false;
    //         if(user.user_type === 'member') return false;
    //         return true
    //     },
    // },
    {
        path: '/rules',
        layout: '/admin',
        name: 'Rules',
        icon: 'pe-7s-info',
        url: 'RULES',
        allowed: user => customerFeature('union_group'), // disable to hide it from
    },

    {
        path: '/presence_view',
        layout: '/admin',
        name: 'Employee board',
        icon: 'pe-7s-wallet',
        component: EmployeeBoard,
        allowed: user => false, // user => false && !!((user.user_type === 'admin' || user.user_type === 'pm') && customerFeature('presence_view')),
    },
    {
        path: '/clients',
        layout: '/admin',
        name: 'Clients',
        icon: 'pe-7s-id',
        component: Clients,
        allowed: user => user.user_type === 'super-admin',
    },
    {
        path: '/biztypes',
        layout: '/admin',
        name: 'Business Types',
        icon: 'pe-7s-car',
        component: BizTypesPage,
        allowed: user => user.user_type === 'super-admin',
    },
    {
        path: '/control_panel',
        layout: '/admin',
        name: 'Control Panel',
        icon: 'pe-7s-car',
        component: ControlPanel,
        hide: true,
        allowed: user => user.user_type === 'admin',
    },
    {
        path: '/control_panel_',
        layout: '/admin',
        name: 'Controls',
        icon: 'pe-7s-car',
        component: ControlPanelv2,
        hide: true,
        allowed: user => user.user_type === 'admin',
    },
    {
        path: '/superadmins',
        layout: '/admin',
        name: 'Super admins',
        icon: 'pe-7s-user',
        component: SuperAdmins,
        allowed: user => user.user_type === 'super-admin',
    },
    {
        path: '/send_report',
        layout: '/admin',
        name: 'Send Report',
        icon: 'pe-7s-paperclip',
        component: SendReport,
        hide: true,
        allowed: () => true, // disable to hide it from
    },
    {
        path: '/locked',
        layout: '/admin',
        name: 'Stamping mode',
        icon: 'pe-7s-wristwatch',
        component: LockedMode,
        allowed: user => !customerFeature('union_group')
            && (user.user_type === 'pm' || user.user_type === 'admin')
            && customerFeature('locked_mode'), // disable to hide it from
    },
    {
        path: '/profile',
        layout: '/admin',
        name: 'Profile',
        icon: 'pe-7s-settings',
        component: Profile,
        hide: true,
        allowed: () => true, // disable to hide it from
    },
    {
        path: '/files',
        layout: '/admin',
        name: 'My Files',
        icon: 'pe-7s-settings',
        component: Files,
        hide: true,
        allowed: () => true, // disable to hide it from
    },
    {
        path: '/companyprofile',
        layout: '/admin',
        name: 'Company profile',
        icon: 'pe-7s-settings',
        component: CompanyProfile,
        hide: true,
        allowed: user => user.user_type === 'admin', // disable to hide it from
    },
    {
        collapse: true,
        collapseId: v4(),
        name: 'Reports',
        icon: 'pe-7s-note2',
        views: [
            {
                path: '/reports/payroll',
                layout: '/admin',
                name: 'Payroll report',
                component: PayrollReport,
                allowed: user => user.user_type !== 'super-admin', // disable to hide it from
            },
            {
                path: '/reports/dailylog',
                layout: '/admin',
                name: 'Daily Log Report',
                component: DailyLogReport,
                allowed: user => user.user_type !== 'super-admin', // disable to hide it from
            },
            // {
            //     path: '/reports/vacation',
            //     layout: '/admin',
            //     name: 'Vacation report',
            //     component: VacationReport,
            //     allowed: user => user.user_type !== 'super-admin', // disable to hide it from
            // },
            // {
            //     path: '/reports/associate',
            //     layout: '/admin',
            //     name: 'Invoice report',
            //     component: AssociateReport,
            //     allowed: user => !customerFeature('union_group') && user.user_type !== 'super-admin', // disable to hide it from
            // },
            // {
            //     path: '/reports/rest',
            //     layout: '/admin',
            //     name: 'User report',
            //     component: RestaurantReport,
            //     allowed: user => !customerFeature('union_group')
            //         && customerFeature('rest_report')
            //         && (user.user_type === 'pm' || user.user_type === 'admin'), // disable to hide it from
            // },
            // {
            //     path: '/reports/gps',
            //     layout: '/admin',
            //     name: 'GPS report',
            //     component: GpsReport,
            //     allowed: user => customerFeature('gps_enabled') && user.user_type !== 'super-admin',
            // },
            // {
            //     path: '/reports/weekly',
            //     layout: '/admin',
            //     name: 'Weekly report',
            //     component: WeeklyReport,
            //     allowed: user => !customerFeature('union_group')
            //         && customerFeature('reports_weekly')
            //         && user.user_type !== 'super-admin', // disable to hide it from
            // },
            // {
            //     path: '/reports/project',
            //     layout: '/admin',
            //     name: 'Project Report',
            //     component: ProjectReport,
            //     allowed: user => user.user_type === 'pm' || user.user_type === 'admin'
            // },
            // {
            //     path: '/reports/employee',
            //     layout: '/admin',
            //     name: 'Employee report',
            //     component: EmployeeReport,
            //     allowed: user => (!customerFeature('union_group') && user.user_type === 'admin') || user.user_type === 'pm',
            // },
            // {
            //     path: '/reports/employee_project',
            //     layout: '/admin',
            //     name: 'Employee Payroll report',
            //     component: EmployeeProjectReport,
            //     allowed: user => (!customerFeature('union_group') && user.user_type === 'admin') || user.user_type === 'pm',
            // },
        ],
    },
    {
        path: '/visitors',
        layout: '/admin',
        icon: 'pe-7s-users',
        name: 'Visitors',
        component: Visitors,
        allowed: user => {
            if (user.user_type === 'super-admin') return true;
            // if (!customerFeature('enable_deviations')) return false;
            // if (user.user_type === 'admin' && customerFeature('union_group')) return false;
            return false;
        },
    },
    {
        path: '/guest',
        layout: '/admin',
        icon: 'pe-7s-users',
        name: 'Visitor Register',
        component: VisitorAdd,
        allowed: user => {
            if (user.user_type === 'super-admin') return false;
            if (user.user_type === 'member') return false;
            // if (!customerFeature('enable_deviations')) return false;
            // if (user.user_type === 'admin' && customerFeature('union_group')) return false;
            return true;
        },
    },
    {
        path: '/',
        layout: '/admin',
        name: 'Dashboard',
        icon: 'pe-7s-graph',
        component: Dashboard,
        order: 0.5,
        allowed: () => true,
    },
];
export default routes;
