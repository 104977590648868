import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import _ from 'lodash';

import withLocalization from '~/hoc/withLocalization';

@inject('absenceStore', 'userStore')
@withRouter
@withLocalization
@observer
class VacationsTotals extends Component {
    state = {
        loadedUser: null,
    };

    loadData() {
      
        if (!this.props.user) {
            this.setState({ loadedUser: this.props.userStore.currentUser });

        } else {
            this.props.userStore.pullUserById(this.props.user).then(user => {
                this.setState({ loadedUser: user });
            });
        }
    }

    componentDidMount() {
        this.loadData();
        console.log("props..",this.props );

    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.user, this.props.user)) {
            this.loadData();
        }
    }

    render() {
        const { t } = this.props;
        const { loadedUser } = this.state;
        if (!loadedUser) {
            return <div>...</div>;
        }
        const { Totals } = loadedUser;
        return (
            <div className="vacation-total">
                <div className="vacation-box">
                    <div className="vacation-box__item">
                        <span className="vacation-box__value">{moment(new Date()).year()}</span>
                        <span className="vacation-box__label">{t('Year')}</span>
                    </div>
                    <div className="vacation-box__item">
                        <span className="vacation-box__value">{loadedUser.employee_percent}%</span>
                        <span className="vacation-box__label">{t('Employment')}</span>
                    </div>
                </div>
                <div className="vacation-panel">
                    <div className="vacation-panel__item">
                        <span className="vacation-panel__value">
                            {/* {Totals.yearly.vacationsLeft + Totals.yearly.vacationDays} */}
                            {/* {loadedUser.vacation_days} */}
                            {this.props.commonStore.config.client.data.Vacation_setup ? this.props.commonStore.config.client.data.Vacation_setup.standared_vacation_days : 0}
                        </span>
                        <span className="vacation-panel__label">{t('Total Vacations')}</span>
                    </div>
                    <div className="vacation-panel__item">
                        <span className="vacation-panel__value">{Totals.yearly.vacationDays}</span>
                        <span className="vacation-panel__label">{t('Approved Vacations')}</span>
                    </div>
                    <div className="vacation-panel__item">
                        {/* <span className="vacation-panel__value">{(loadedUser.vacation_days - Totals.yearly.vacationDays)}</span> */}
                        <span className="vacation-panel__value">{(this.props.commonStore.config.client.data.Vacation_setup ? this.props.commonStore.config.client.data.Vacation_setup.standared_vacation_days - Totals.yearly.vacationDays : 0)}</span>

                        <span className="vacation-panel__label">{t('Rest Vacations')}</span>
                    </div>
                    <div className={`acation-panel__item status-block status-${this.props.currentvacation ? this.props.currentvacation.vacation.status : null}`} style={{ margin:'auto' ,marginRight: '20px' , height:"fit-content" }}>
                      {t(this.props.currentvacation ? this.props.currentvacation.vacation.status : null)}
                </div>
                </div>
            </div>
        );
    }
}
export default VacationsTotals;
