import _ from 'lodash';

import user from './user';

export const schema = {
    title: 'Client',
    type: 'object',
    required: ['name', 'tax_number'],
    properties: {
        id: { type: 'integer' },
        image: {
            type: ['string', 'null'],
        },
        status: {
            type: 'string',
            title: 'Active',
            default: 'active',
        },
        client_type: { type: ['string', 'null'] },
        name: {
            type: 'string',
            title: 'Name',
            isRequired: true,
        },
        address: {
            type: ['string','null'],
            title: 'Address',
        },
        post_number: { type: ['string', 'null'], title: 'Post no', format: 'postnumber' },
        post_place: { type: ['string', 'null'], title: 'Post place' },
        renew_date: {
            type: 'string',
            format: 'date',
            title: 'Renew date',
        },
        renew_manual: {
            type: 'boolean',
            title: 'Renew manual',
            anyOf: [
                {
                    title: 'Manual',
                    enum: [true],
                },
                {
                    title: 'Automatic',
                    enum: [false],
                },
            ],
        },
        allow_system_document: {
            type: 'boolean',
            title: 'Access Document',
            default:false,
            anyOf: [
                {
                    title: 'Yes',
                    enum: [true],
                },
                {
                    title: 'No',
                    enum: [false],
                },
            ],
        },
        allow_visitor_only: {
            type: 'boolean',
            title: 'Visitors only',
            default:false,
            anyOf: [
                {
                    title: 'Yes',
                    enum: [true],
                },
                {
                    title: 'No',
                    enum: [false],
                },
            ],
        },
        biztype: {
            type: 'integer',
            title: 'Business type',
        },
        max_users: {
            type: 'integer',
            title: 'Max users (plan)',
        },
        max_users_requested: {
            type: ['integer','null'],
            title: 'Max users (requested)',
        },
        tax_number: {
            type: 'string',
            title: 'Tax number',
            isRequired: true,
            format:'tax-number'
        },
        User: {
            type: 'object',
            title: 'User',
            required: ['username', 'first_name', 'last_name', 'email'],
            properties: {
                id: { type: 'integer' },
                username: {
                    title: 'Username',
                    type: 'string',
                },
                user_type: {
                    title: 'User type',
                    type: 'string',
                    anyOf: [
                        {
                            title: 'Member',
                            enum: ['member'],
                        },
                        {
                            title: 'PM',
                            enum: ['pm'],
                        },
                        {
                            title: 'Admin',
                            enum: ['admin'],
                        },
                    ],
                },
                first_name: {
                    title: 'First name',
                    type: 'string',
                },
                last_name: {
                    title: 'Last name',
                    type: 'string',
                },
                email: {
                    title: 'Email',
                    type: 'string',
                    format: 'email',
                },
                phone: {
                    title: 'Phone',
                    type: ['string', 'null'],
                    format: 'phone-no',
                },
                password: {
                    title: 'Password',
                    type: ['string', 'null'],
                },
                data: {
                    type: 'object',
                    properties: {
                        kids: {
                            type: 'array',
                            title: 'Info About Kids',
                            default: [],
                            items: {
                                type: 'object',
                                required: ['name', 'date', 'sex'],
                                properties: {
                                    name: {
                                        title: 'Name',
                                        type: 'string',
                                    },
                                    date: {
                                        title: 'Date of birth',
                                        type: 'string',
                                        format: 'date',
                                    },
                                    sex: {
                                        title: 'Sex',
                                        type: 'string',
                                        anyOf: [
                                            {
                                                title: 'Boy',
                                                enum: ['boy'],
                                            },
                                            {
                                                title: 'Girl',
                                                enum: ['girl'],
                                            },
                                        ],
                                        default: 'boy',
                                    },
                                },
                            },
                        },
                        nomines: {
                            type: 'array',
                            title: 'Nomines',
                            default: [],
                            items: {
                                type: 'object',
                                required: ['name', 'father', 'relation', 'contactNumber'],
                                properties: {
                                    name: {
                                        title: 'Name',
                                        type: 'string',
                                    },
                                    // father: {
                                    //     title: 'Fathers Name',
                                    //     type: 'string',
                                    // },
                                    // relation: {
                                    //     title: 'Relation',
                                    //     type: 'string',
                                    // },
                                    contactNumber: {
                                        title: 'Contact Number',
                                        type: 'string',
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};

export const getSchema = isProfile => {
    const userSchema = user.schema;
    const _schema = _.cloneDeep(schema);
    if (isProfile) _schema.properties.max_users.readOnly = true;

    // schema.properties.User = userSchema;
    return _schema;
};

export default getSchema;
