import React, { Component } from 'react';
import Moment from 'moment/min/moment-with-locales';
import { extendMoment } from 'moment-range';
import { withRouter } from 'react-router-dom';

import CustomRadio from '../CustomRadio/CustomRadio';
import DateRange from '../DateRange/DateRange';
import Button from '../CustomButton/CustomButton.jsx';
import withLocalization from '../../hoc/withLocalization';

const moment = extendMoment(Moment);

@withLocalization
@withRouter
class DidNotWork extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioValue: null,
        };
        //    console.log('!!!', this.state, this.state.date.start.contains, this.state.date.end.contains);
    }

    onSelect = date => {
        this.setState({ date });
    };

    handleRadioChange = e => {
        const { value } = e.target;
        this.setState({
            radioValue: value,
            date: moment.range(moment(this.props.start), moment(new Date())),
        });
    };

    sentInitState = () => {
        this.setState({
            radioValue: null,
            date: {
                start: null,
                end: null,
            },
        });
    };

    isValid() {
        const { radioValue, date } = this.state;
        return radioValue && date.start && date.end;
    }

    applyForm = () => {
        const { date, radioValue } = this.state;
        const { history, t } = this.props;
        if (radioValue == 'holiday') {
            this.props.applyHoliday(date.start, date.end);
            return;
        }
        const url = `/admin/${radioValue === 'vacation' ? 'vacations' : 'absences'}/add?from=${date.start.format(
            'YYYY-MM-DD'
        )}&to=${date.end.format('YYYY-MM-DD')}`;
        history.push(url);
    };

    render() {
        const { radioValue } = this.state;
        const { t, allow } = this.props;
        return (
            <div className="did-not-work">
                <div className="did-not-work__radios">
                    <span className="did-not-work__radio-title">{t('Did not work?')}</span>
                    <CustomRadio
                        label={t('Vacation')}
                        option="vacation"
                        onChange={this.handleRadioChange}
                        name="did-not-work"
                    />
                    <CustomRadio
                        label={t('Absence')}
                        option="absence"
                        onChange={this.handleRadioChange}
                        name="did-not-work"
                    />
                    {!allow && (
                        <CustomRadio
                            label={t('Holiday')}
                            option="holiday"
                            onChange={this.handleRadioChange}
                            name="did-not-work"
                        />
                    )}
                </div>
                {radioValue && <DateRange value={this.state.date} onSelect={this.onSelect} style={{ marginTop: 15 }} />}
                <div className="did-not-work__buttons">
                    {radioValue && (
                        <Button variant="secondary" onClick={this.sentInitState}>
                            {t('Cancel')}
                        </Button>
                    )}
                    {this.isValid() && (
                        <Button variant="primary" onClick={this.applyForm}>
                            {t('Apply')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}
export default DidNotWork;
