import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CustomRadio from '../../CustomRadio/CustomRadio';
import withLocalization from '../../../hoc/withLocalization';

@inject('commonStore')
@withLocalization
@observer
class AbsenceStatusesMobx extends Component {
    render() {
        const { value, onChange, t } = this.props;
        const { client } = this.props.commonStore.config;
        const statuses = client ? client.data.extendedRules.absent_types : false;
        if (!statuses) {
            return null;
        }
        return (
            <div className="task-statuses">
                {statuses.map(({ code, name }) => (
                    <CustomRadio
                        number={`${code}_${name}`}
                        name={code}
                        key={code}
                        onChange={() => onChange(code)}
                        checked={value === code}
                        option={code}
                        label={t(name)}
                    />
                ))}
            </div>
        );
    }
}

@observer
class AbsenceStatusesWidget extends Component {
    render() {
        return <AbsenceStatusesMobx {...this.props} />;
    }
}
export default AbsenceStatusesWidget;
