import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import UsersList from './UsersList';
import UsersForm from './UsersForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';

@inject('authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class UsersPage extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/superadmins/add');
    }

    onTableAction(id, type) {
        const { history, authStore } = this.props;
        if (type === 'loginas') {
            authStore
                .loginAs(id)
                .then(() => {
                    window.location.href = `${window.location.href.split('/admin')[0]}/admin/?reload`;
                    // history.push(`/admin`);
                })
                .catch(e => {
                    this.props.commonStore.addNotification(e.message || this.props.t('Error'), null, 'error');
                });
            return;
        }
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.userStore.remove(id).then(res => {
                    this.props.userStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        history.push(`/admin/superadmins/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    render() {
        const mode = this.editingId();
        const { userStore } = this.props;
        const userType = userStore.currentUser.user_type;
        if (userType == 'member') {
            return <div>Not Allowed</div>;
        }
        return (
            <div className="main-content">
                {!mode.editing && (
                    <UsersList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        mode="superadmins"
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && <UsersForm id={mode.id} add={mode.add} mode="superadmins" />}
            </div>
        );
    }
}
export default UsersPage;
