import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';

import Input from '../../../elements/Input';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import { MembersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import DatePicker from '../../../components/DatePicker/DatePicker';

import GpsMap from './GpsMap';

import config from '~/library/config';
import agent from '~/library/agent';
import customerFeature from '~/utils/customerFeature';
import Button from '~/components/CustomButton/CustomButton.jsx';
import withLocalization from '~/hoc/withLocalization';
import Modal from 'react-bootstrap/lib/Modal';
import Table from 'react-bootstrap/lib/Table';
import FormControl from 'react-bootstrap/lib/FormControl';

@inject('dashboardStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class SendReport extends Component {
    state = {
        mapOpen: false,
        email: null,
        dateRange: { start: moment(new Date()).startOf('month'), end: moment(new Date()) },
        sending: false,
        approved_only: false,
        include_vacation:false,
        include_absence:false,
        include_images: true,
        include_extra: true,
        include_distance: false,
        include_travel_expenses: false,
        include_other_expenses: false,
        include_weekend_hours: false,
        include_missing_hours: false,
        is_project_mode: false,
        include_extra_images: false,
        iframeURL: 'about:blank',
        gps: false,
        weekStart: null,
        users: [],
        all_members: false,
        project: { value: '', label: 'All' },
        showExcelModal: false,
        activityTypes: [
            { key: 'total_hours_normal', name: 'Total Hours' },
        ],
        invalidUsers: [],
        showMergeByProject: false,
    };

    constructor() {
        super();
        this.handleShowExcelModal = this.handleShowExcelModal.bind(this);
        this.handleCloseExcelModal = this.handleCloseExcelModal.bind(this);
        this.handleDownloadExcel = this.handleDownloadExcel.bind(this);
        this.handleShowZiriusReportModal = this.handleShowZiriusReportModal.bind(this);
    }

    async handleShowExcelModal() {
        const { commonStore, userStore, t } = this.props;
        if (this.state.users.length <= 0 && !this.state.all_members) {
            commonStore.addNotification(t('Choose users'), null, 'error');
            return;
        }

        const mode = this.getReportMode();
        const hydrated = this.getHydratedParams(commonStore, userStore, mode, { excel: true });

        const reportInfo = await userStore.getReportInfo(hydrated);

        const { activityTypes } = reportInfo;
        const { invalidUsers } = reportInfo;

        this.setState({
            activityTypes,
            invalidUsers,
            showExcelModal: true,
            isZirius: false,
            showMergeByProject: false
        });
    }


    async handleShowZiriusReportModal() {
        const { commonStore, userStore, t } = this.props;
        if (this.state.users.length <= 0 && !this.state.all_members) {
            commonStore.addNotification(t('Choose users'), null, 'error');
            return;
        }

        const mode = this.getReportMode();
        const hydrated = this.getHydratedParams(commonStore, userStore, mode, { excel: true, zirius: true });

        const reportInfo = await userStore.getReportInfo(hydrated);

        const { activityTypes } = reportInfo;
        const { invalidUsers } = reportInfo;

        this.setState({
            activityTypes,
            invalidUsers,
            showExcelModal: true,
            isZirius: true,
            showMergeByProject: true,
            isMergeByProject: true
        });
    }

    handleCloseExcelModal() {
        this.setState({ showExcelModal: false, isZirius: false, showMergeByProject: false });
    }

    handleDownloadExcel(e) {
        const zirius = this.state.isZirius;
        const isMergeByProject = this.state.isMergeByProject;
        const {
            t, commonStore
        } = this.props;
        e.preventDefault();
        const data = new FormData(e.target);
        const wageActivityToType = {};
        this.state.activityTypes.forEach(item => {
            wageActivityToType[item.key] = data.get('wageType[' + item.key + ']');
        });

        this.applyForm({ excel: true, wageActivityToType, zirius: zirius, isMergeByProject: isMergeByProject });
        this.setState({ showExcelModal: false, isZirius: false, isMergeByProject: false });

        if (this.state.invalidUsers.length > 0) {
            commonStore.addNotification(
                <>
                    {t('Employee ID is not filled for')}
                    {
                        this.state.invalidUsers.map(user =>
                            <div>{user.first_name} {user.last_name}</div>
                        )
                    }
                </>,
                null,
                'info',
                'tc',
                10
            );


        }
    }

    handleDownloadExcel2(e) {
        // const {
        //     t, commonStore
        // } = this.props;
        // e.preventDefault();
        // const data = new FormData(e.target);
        // const wageActivityToType = {};
        // this.state.activityTypes.forEach(item => {
        //     wageActivityToType[item.key] = data.get('wageType[' + item.key + ']');
        // });

        this.applyForm({ excel: true });
        this.setState({ showExcelModal: false });

        if (this.state.invalidUsers.length > 0) {
            commonStore.addNotification(
                <>
                    {t('Employee ID is not filled for')}
                    {
                        this.state.invalidUsers.map(user =>
                            <div>{user.first_name} {user.last_name}</div>
                        )
                    }
                </>,
                null,
                'info',
                'tc',
                10
            );


        }
    }

    getReportMode() {
        return this.props.mode || 'default';
    }

    getCustomValues() {
        const customValues = {};

        const mode = this.getReportMode();
        console.log('mode***',mode);

        if (mode === 'employee_project') {
            customValues['approved_only'] = true;
            customValues['include_weekend_hours'] = true;
            customValues['all_members'] = true;
            customValues['is_project_mode'] = true;
        } else if (mode === 'cooperation') {
            customValues['approved_only'] = true;
            customValues['consider_overtime'] = true;
        } else if (mode === 'project') {
            customValues['include_extra'] = false;
        }

        return customValues;
    }

    getMode(mode) {
        if (mode === 'employee_project' && !this.state.is_project_mode) {
            return 'employee';
        } else if (mode === 'project' && this.state.include_extra) {
            return 'extra';
        }
        return mode;
    }

    getHydratedParams(commonStore, userStore, mode, params) {
        const hydrated = {
            user_id: userStore.currentUser.id,
            mode: this.getMode(mode),
            mail: !!params.email,
            all_members: this.state.all_members,
            pdf: params.pdf,
            excel: !!params.excel,
            zirius: !!params.zirius,
            users: this.state.users.join(','),
            email: params.email ? this.state.email : '',
            approved_only: this.state.approved_only,
            project: this.state.project.value,
            gps: this.state.gps || null,
            consider_overtime: this.state.consider_overtime || null,
            projectname: this.state.project.label,
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD'),
            weekStart: this.state.weekStart.format('YYYY-MM-DD'),
            include_images: this.state.include_images || null,
            include_extra: this.state.include_extra || null,
            include_extra_images: this.state.include_extra_images || null,
            include_distance: this.state.include_distance || null,
            include_travel_expenses: this.state.include_travel_expenses || null,
            include_other_expenses: this.state.include_other_expenses || null,
            include_weekend_hours: this.state.include_weekend_hours,
            include_missing_hours: this.state.include_missing_hours,
            is_project_mode: this.state.is_project_mode,
            include_absence:this.state.include_absence,
            include_vacation:this.state.include_vacation
        };
        if (params.wageActivityToType) {
            hydrated.wageActivityToType = params.wageActivityToType;
        }
        if (params.zirius) {
            hydrated.zirius = params.zirius;
        }
        if (params.isMergeByProject) {
            hydrated.isMergeByProject = params.isMergeByProject;
        }
        hydrated.preview = !params.pdf && !params.excel;
        hydrated.authorization = commonStore.token;

        return hydrated;
    }

    loadData() {
        const { t, dashboardStore, userStore } = this.props;
        const { sending } = this.state;
        const userType = userStore.currentUser.user_type;
        //const userSelect = customerFeature('reports_not_for_me');
        const userSelect = userType === 'pm';
        console.log('userSelect',userSelect);

        const start = this.getReportMode() === 'gps' ? moment(new Date()) : moment(new Date()).startOf('month');
        this.setState({
            dateRange: { start, end: moment(new Date()) },
            weekStart: moment(new Date()).add(-7, 'days'),
        });

        // this.setState({users:[`${userStore.currentUser.id}`]});
        if (userType === 'pm' && userSelect) {
            this.setState({ users: /* userStore.currentUser.Manages.map(o => `${o}`) */[] });
        }
        if (!userSelect || userType === 'member') {
            this.setState({ users: [userStore.currentUser.id] });
        }
    }

    locale() {
        // let lang = window.localStorage.getItem('locale') || 'en';
        let lang = window.sessionStorage.getItem('locale') || 'en';
        if (lang === 'no') {
            lang = 'nb';
        }
        return lang;
    }

    componentDidMount() {
        this.loadData();
        const customValues = this.getCustomValues();
        if (Object.keys(customValues).length) {
            this.setState(customValues);
        }
    }

    onSelect = (date, field) => {
        const dr = this.state.dateRange;
        dr[field] = moment(date);
        this.setState({ dateRange: dr });
    };

    onEmailChange = e => {
        this.setState({ email: e.target.value });
    };

    onCheckboxChange = (name, value) => {
        this.setState({ [name]: value });
    };

    onWeekChange = day => {
        this.setState({ weekStart: day });
    };

    applyForm = (params = {}) => {
        const mode = this.getReportMode();
        const {
            t, userStore, commonStore
        } = this.props;
        if (this.state.users.length <= 0 && mode != 'vacation' && !this.state.all_members) {
            commonStore.addNotification(t('Choose users'), null, 'error');
            return;
        }
        if(this.state.project.value === ''){
            commonStore.addNotification(t('Choose project'), null, 'error');
            return;
        }
        if (mode === 'project' && !this.state.project.value) {
            commonStore.addNotification(t('Choose project'), null, 'error');
            return;
        }

        // this.setState({sending:true});
        const hydrated = this.getHydratedParams(commonStore, userStore, mode, params);
        if (params.pdf) {
            if (params.email && (!this.state.email || this.state.email.indexOf('@') < 0)) {
                commonStore.addNotification(t('Fill email'), null, 'error');
                return;
            }
            if (!params.email) {
                window.open(`${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`);
            }
            userStore
                .sendReport(hydrated)
                .then(res => {
                    this.setState({ sending: false });
                    if (params.email) commonStore.addNotification(t('Sent'), null, 'success');
                })
                .catch(err => {
                    this.setState({ sending: false });
                    commonStore.addNotification(err.message || t('Error'), null, 'error');
                });
        } else {
            this.setState({
                iframeURL: `${config.API_ROOT}/dashboard/send_report${agent.convertToGetParams(hydrated)}`,
            });
        }
    };

    gpsMap = () => {
        this.setState({ mapOpen: true });
    };

    onMemberChange = (id, user) => {
        //    const { users } = this.state;
        const users = user ? user.map(u => u.value) : [];
        this.setState({ users });
    };

    onProjectChange = (id, user) => {
        this.setState({ project: user });
    };

    render() {
        const { t, dashboardStore, userStore } = this.props;
        const { sending, mapOpen } = this.state;
        const userType = userStore.currentUser.user_type;
        const mode = this.getReportMode();
       // const userSelect = userType != 'member' && !!customerFeature('reports_not_for_me');
       const userSelect = userType === 'pm';
        const gpsEnabled = !!customerFeature('gps_enabled');

        const approve = customerFeature('allow_accepting');        
        return (
            <div>
                {mapOpen && <GpsMap params={this.state} onClose={() => this.setState({ mapOpen: false })} />}
                <div className="panel" style={{ margin: 20, padding: 20 }}>
                    <h4>
                        {mode == 'cooperation' && t('Payroll report')}
                        {mode == 'default' && t('Invoice report')}
                        {mode == 'vacation' && t('Vacation report')}
                        {mode == 'weekly' && t('Weekly')}
                        {mode == 'gps' && t('GPS report')}
                        {mode == 'employee' && t('Employee report')}
                        {mode == 'employee_project' && t('Employee Payroll report')}
                        {mode == 'project' && t('Project Report')}
                    </h4>
                    {mode == 'default' && (
                        <div>
                            {gpsEnabled && (
                                <CustomCheckbox
                                    label={t('Include GPS data')}
                                    onChange={v => this.onCheckboxChange('gps', v)}
                                    isChecked={this.state.gps}
                                />
                            )}
                            <CustomCheckbox
                                label={t('Include images')}
                                onChange={v => this.onCheckboxChange('include_images', v)}
                                isChecked={this.state.include_images}
                            />
                            <CustomCheckbox
                                label={t('Include extra work')}
                                onChange={v => this.onCheckboxChange('include_extra', v)}
                                isChecked={this.state.include_extra}
                            />
                            <CustomCheckbox
                                label={t('Include extra work images')}
                                onChange={v => this.onCheckboxChange('include_extra_images', v)}
                                isChecked={this.state.include_extra_images}
                            />
                        </div>
                    )}
                    {mode === 'cooperation' && (
                        <div className="row">
                            <div className="col-md-6">
                                {approve && (
                                    <CustomCheckbox
                                        label={t('Approved only')}
                                        onChange={v => this.onCheckboxChange('approved_only', v)}
                                        isChecked={this.state.approved_only}
                                    />
                                )}
                                <CustomCheckbox
                                    label={t('Include Overtime')}
                                    onChange={v => this.onCheckboxChange('consider_overtime', v)}
                                    isChecked={this.state.consider_overtime}
                                />
                                <CustomCheckbox
                                    label={t('Include missing hours')}
                                    onChange={v => this.onCheckboxChange('include_missing_hours', v)}
                                    isChecked={this.state.include_missing_hours}
                                />
                                <CustomCheckbox
                                    label={t('Show weekend hours')}
                                    onChange={v => this.onCheckboxChange('include_weekend_hours', v)}
                                    isChecked={this.state.include_weekend_hours}
                                />
                            </div>
                            <div className="col-md-6">
                                <CustomCheckbox
                                    label={t('Include extra work')}
                                    onChange={v => this.onCheckboxChange('include_extra', v)}
                                    isChecked={this.state.include_extra}
                                />
                                <CustomCheckbox
                                    label={t('Include Distance')}
                                    onChange={v => this.onCheckboxChange('include_distance', v)}
                                    isChecked={this.state.include_distance}
                                />
                                <CustomCheckbox
                                    label={t('Include Other Expenses')}
                                    onChange={v => this.onCheckboxChange('include_other_expenses', v)}
                                    isChecked={this.state.include_other_expenses}
                                />
                                <CustomCheckbox
                                    label={t('Include Vacation')}
                                    onChange={v => this.onCheckboxChange('include_vacation', v)}
                                    isChecked={this.state.include_vacation}
                                />
                                <CustomCheckbox
                                    label={t('Include Absence')}
                                    onChange={v => this.onCheckboxChange('include_absence', v)}
                                    isChecked={this.state.include_absence}
                                />
                            </div>
                        </div>
                    )}
                    {mode.includes('employee', 'employee_project') && (
                        <div>
                            {approve && (
                                <CustomCheckbox
                                    label={t('Approved only')}
                                    onChange={v => this.onCheckboxChange('approved_only', v)}
                                    isChecked={this.state.approved_only}
                                />
                            )}
                        </div>
                    )}
                    {mode !== 'gps' && (
                        <div className="row">
                            <div className="col-md-6">
                                {/* <h4>{t('From')}</h4> */}
                                <DatePicker
                                    selected={this.state.dateRange.start.toDate()}
                                    onChange={date => this.onSelect(date, 'start')}
                                    dateFormat="EEEE MMMM dd, yyyy"
                                    maxDate={new Date()}
                                    label={t('From')}   
                                />
                            </div>
                            <div className="col-md-6">
                                {/* <h4>{t('To')}</h4> */}
                                <DatePicker
                                    selected={this.state.dateRange.end.toDate()}
                                    onChange={date => this.onSelect(date, 'end')}
                                    dateFormat="EEEE MMMM dd, yyyy"
                                    maxDate={new Date()}
                                    label={t('To')}                                     
                                />
                            </div>
                        </div>
                    )}
                    {mode == 'gps' && (
                        <div>
                            <div style={{ paddingRight: '20px' }}>
                                {/* <h4>{t('For date')}</h4> */}
                                <DatePicker
                                    selected={this.state.dateRange.start.toDate()}
                                    onChange={date => this.onSelect(date, 'start')}
                                    dateFormat="EEEE MMMM dd, yyyy"
                                    maxDate={new Date()}
                                    label={t('For date')}
                                />
                            </div>
                            <CustomCheckbox
                                label={t('Collapse changes')}
                                onChange={v => this.onCheckboxChange('include_extra', v)}
                                isChecked={this.state.include_extra}
                            />

                            {userSelect && (
                                <div>
                                    <h4>{t('User')}</h4>
                                    <MembersWidget
                                        isMulti={false}
                                        value={this.state.users && this.state.users.length ? this.state.users[0] : null}
                                        onChange={(id, user) => this.onMemberChange(id, [user])}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {mode === 'project' && (
                        <div style={{ marginTop: 20 }}>
                            <CustomCheckbox
                                label={t('Include Overtime')}
                                onChange={v => this.onCheckboxChange('consider_overtime', v)}
                                isChecked={this.state.consider_overtime}
                            />
                            <CustomCheckbox
                                label={t('Include Distance')}
                                onChange={v => this.onCheckboxChange('include_distance', v)}
                                isChecked={this.state.include_distance}
                            />
                            <CustomCheckbox
                                label={t('Travel Expenses')}
                                onChange={v => this.onCheckboxChange('include_travel_expenses', v)}
                                isChecked={this.state.include_travel_expenses}
                            />
                            <br />
                            <b>{t('Show extra work')}</b>
                            <br />
                            <CustomCheckbox
                                label={t('Show extra work')}
                                onChange={v => this.onCheckboxChange('include_extra', v)}
                                isChecked={this.state.include_extra}
                            />
                            <CustomCheckbox
                                label={t('Include extra work images')}
                                onChange={v => this.onCheckboxChange('include_extra_images', v)}
                                isChecked={this.state.include_extra_images}
                            />
                        </div>
                    )}
                    {mode === 'employee_project' && (
                        <div style={{ marginTop: 20 }}>
                            <CustomCheckbox
                                label={t('Show weekend hours')}
                                onChange={v => this.onCheckboxChange('include_weekend_hours', v)}
                                isChecked={this.state.KM}
                            />
                            <CustomCheckbox
                                label={t('Show by project name')}
                                onChange={v => this.onCheckboxChange('is_project_mode', v)}
                                isChecked={this.state.is_project_mode}
                            />
                        </div>
                    )}
                    {mode != 'vacation' && mode != 'gps' && userSelect && ( 
                        <div style={{marginTop:'15px'}}>
                            {/* <CustomCheckbox
                                label={t('All members/employees')}
                                onChange={v => this.onCheckboxChange('all_members', v)}
                                isChecked={this.state.all_members}
                            /> */}
                            <MembersWidget
                                isMulti
                                value={this.state.users}
                                placeholder={t('Select Members') + '...'}
                                onChange={(id, user) => this.onMemberChange(id, user)}
                                showAdminPM={true}
                            />
                        </div>
                    )}
                    {userType === 'admin' && mode != 'vacation' && mode != 'gps' && userSelect && (
                        <div className="danger">
                            {t(
                                'Report for all employees can be too big. We recommend to select one employee at a time and try.'
                            )}
                        </div>
                    )}
                    {mode != 'vacation' && (
                        <div>
                            <h5>{t('Project')}</h5>
                            <ProjectsWidget
                                value={''}
                                placeholder={'Assigned members of projects'}
                                onChange={(id, user) => this.onProjectChange(id, user)}
                                // allowAll
                            />
                        </div>
                    )}
                    <div className="did-not-work__buttons">
                        {sending && <div>Sending</div>}
                        {mode == 'gps' && (
                            <Button variant="primary" onClick={() => this.gpsMap()}>
                                {t('GPS on map')}
                            </Button>
                        )}
                        {!sending && (
                            <Button variant="primary" onClick={() => this.applyForm()}>
                                {t('Apply')}
                            </Button>
                        )}
                    </div>
                </div>
                <div className="panel" style={{ margin: 20, padding: 20 }}>
                    <iframe style={{ width: '100%', height: '90vh', border: 0 }} src={this.state.iframeURL} />
                </div>
                <div className="panel" style={{ margin: 20, padding: 20 }}>
                    <Input label={t('To email')} value={this.state.email} onChange={this.onEmailChange} />
                    <Button variant="primary" onClick={() => this.applyForm({ pdf: true, email: false })}>
                        {t('Download as PDF')}
                    </Button>
                    <Button variant="primary" onClick={() => this.applyForm({ pdf: true, email: true })}>
                        {t('Send PDF by email')}
                    </Button>
                    {
                        this.props.isPayRollReport &&
                        <Button variant="primary" onClick={this.handleShowExcelModal}>
                            {t('Nettlonn Excel')}
                        </Button>
                    }
                    {
                        this.props.isPayRollReport &&
                        <Button variant="primary" onClick={() => this.applyForm({ excel: true, email: false })}>
                            {t('Excel report')}
                        </Button>
                    }
                    {
                        this.props.isPayRollReport &&
                        <Button variant="primary" onClick={this.handleShowZiriusReportModal}>
                            {t('Zirius report')}
                        </Button>
                    }
                    <Modal show={this.state.showExcelModal} onHide={this.handleCloseExcelModal}>
                        <form onSubmit={this.handleDownloadExcel}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('Please enter wage type')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {t('Fill corresponding Wage Type ID for Activity Type')}
                                {this.state.showMergeByProject && (
                                    <div>
                                        <CustomCheckbox
                                            onChange={v => this.onCheckboxChange('isMergeByProject', v)}
                                            isChecked={this.state.isMergeByProject}
                                            name="isMergeByProject"
                                            label={t('Merge by Project')} />
                                    </div>
                                )}

                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>{t('ACTIVITY TYPE')}</th>
                                            <th>{t('WAGE TYPE VALUE')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.activityTypes.map(type =>
                                                <tr key={type.key}>
                                                    <td>{type.name}</td>
                                                    <td>
                                                        <FormControl required={true} type={"text"} name={"wageType[" + type.key + "]"} placeholder={t("Wage Type ID")} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleCloseExcelModal}>
                                    {t("Close")}
                                </Button>
                                <Button variant="primary" type="submit">
                                    {t("Download")}
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>



                </div>
            </div>
        );
    }
}

@observer
export class AssociateReport extends Component {
    render() {
        return <SendReport {...this.props} mode="default" />;
    }
}

@observer
export class PayrollReport extends Component {
    render() {
        const props = { ...this.props };
        props.isPayRollReport = true;
        return <SendReport {...props} mode="cooperation" />;
    }
}

@observer
export class VacationReport extends Component {
    render() {
        return <SendReport {...this.props} mode="vacation" />;
    }
}

@observer
export class ProjectReport extends Component {
    render() {
        return <SendReport {...this.props} mode="project" />;
    }
}

@observer
export class WeeklyReport extends Component {
    render() {
        return <SendReport {...this.props} mode="weekly" />;
    }
}

@observer
export class EmployeeReport extends Component {
    render() {
        return <SendReport {...this.props} mode="employee" />;
    }
}

@observer
export class EmployeeProjectReport extends Component {
    render() {
        return <SendReport {...this.props} mode="employee_project" />;
    }
}

@observer
export class GpsReport extends Component {
    render() {
        return <SendReport {...this.props} mode="gps" />;
    }
}

export default SendReport;
