import { observable, action } from 'mobx';
// import moment from 'moment/min/moment-with-locales';

// // import getBase64 from '~/utils/getBase64';
// import queryString from 'query-string';

import agent from '../agent';


class LeaveDetailsStore {
   
    @action save(values) {
        
        return agent.LeaveDetailsStore.save(values)
            .then(
                action(response => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

   
}

export default new LeaveDetailsStore();
