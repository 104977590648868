import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import AcceptFormBlock from '../Timelogs/AcceptFormBlock';

import VacationsTotals from './VacationsTotals';

import { Modal,Row,Col } from 'react-bootstrap';
import { Input, Textarea } from '../../../elements/Input';
import MultiSelect from '@khanacademy/react-multi-select';
import Button from '../../../components/CustomButton/CustomButton.jsx';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/vacation';
import customerFeature from '~/utils/customerFeature';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            vacation_type: { md: 4 },
            project_id: { md: 4 },
            // 'status':{md:4},
        },
        {
            from: { md: 4 },
            to: { md: 4 },
            total_days: { md: 4 },
        },
        {
            comments: { md: 12 },
        },
        {
            attachments: { md: 12 },
        },
    ],
    vacation_type: {
        'ui:widget': 'VacationStatuses',
    },
    project_id: {
        'ui:widget': 'ProjectsWidget',
    },
    attachments: {},
    user_id: {
        'ui:widget': 'AllUsersWidget',
    },
};

@inject('vacationStore', 'commonStore', 'userStore','projectStore','leaveDetailsStore')
@withRouter
@withLocalization
@observer
class VacationsForm extends Component {
    state = {
        schema: getSchema(),
        uiSchema: defaultUiSchema,
        show_modal:false,
        vacation_note:'',
        project_email:'',
        error_msg:'',
        modal_id:0,
        from_date:'',
        to_date:''
    };

    getListUrl() {
        return '/admin/vacations';
    }

    async handleChangeStatus(action) {
        this.loadData();
    }

    async loadData() {        

        const {
            vacationStore, id, add, mode, location
        } = this.props;
        const { currentEntity } = vacationStore;
        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });

        if (add) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return vacationStore.returnDefaultNew({ location });
        }
       
        const vacation = await vacationStore.load(id, add);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, vacation.vacation) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore } = this.props;
        const { currentUser } = userStore;

        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Vacation',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        const showUser = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        // const showUser = currentUser.user_type === 'pm' && customerFeature('allow_vacation_not_me');
        if (showUser) {
            schema['ui:layout'][0].user_id = { md: 4 };
        }
        if (currentUser.user_type === 'member') {
            if (schema.user_id) schema.user_id.classNames = 'hidden';
        }

        return schema;
    }

    componentWillMount() {
        this.props.vacationStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
     
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

   async loadProjectDetails(id,values){
       const{projectStore} = this.props;
        const project = await projectStore.load(values.project_id);
        let comment = `I would like to have vacation from ${values.from} to ${values.to}`
        this.setState({show_modal:true,project_email:project.project.email,vacation_note:comment,modal_id:id,from_date:values.from,to_date:values.to});
    }

    openModal(id,values){
        this.loadProjectDetails(id,values);
    }

    onSave(values) {
        const {
            vacationStore, history, add, t, commonStore
        } = this.props;
        const { userStore } = this.props;
        const { currentUser } = userStore;
        // if (currentUser.user_type === 'member' || add) {
        if (!values.user_id) {
            values.user_id = currentUser.id;
        }
        const { currentEntity } = vacationStore;

        let allow = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (currentEntity && currentEntity.vacation && currentEntity.vacation.status == 'approved') allow = false;

        if (currentUser.id != values.user_id && !allow) {
            commonStore.addNotification(t('Can not save on behalf of others'), null, 'error');
            return false;
        }
        values.status = 'active';
        return vacationStore.save(values, add).then(result => {
            if (!result.vacation || !result.vacation.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            //this.setState({show_modal:true})
            this.openModal(result.vacation.id,values);
           // commonStore.addNotification(t('Saved'), null, 'success');
           
           // history.push(this.getListUrl());
            return true;
        })
       
    }

    applyVacation = async ()=>{
        const{project_email,error_msg,vacation_note,modal_id,from_date,to_date} = this.state;
        const {
            vacationStore, history, add, t, commonStore,projectStore,userStore,leaveDetailsStore
        } = this.props;
        const { currentUser } = userStore;
        if(vacation_note === '' || project_email === '' || project_email === null ){
             this.setState({error_msg:true});
            return;
        }
        else{
           let payloadData ={
            modal_id:modal_id,
            email:project_email,
            comment:vacation_note,
            modal_type:"Vacation",
            from_date:from_date,
            to_date:to_date
           }

           await leaveDetailsStore.save(payloadData).then(result=>{
               this.setState({
                show_modal:false,
                project_email:'',
                vacation_note:''
               },()=>{
                history.push(this.getListUrl());
               })
           })

        }
    }

    handleClosesApplyVacation = ()=>{
        this.setState({
            show_modal:false,
            project_email:'',
            vacation_note:''
           })
    }


    render() {
        const { vacationStore, add,t } = this.props;
        const { loading, currentEntity } = vacationStore;
        const { schema, uiSchema,show_modal,project_email,error_msg,vacation_note } = this.state;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting') && !add;
        let allow = (user_type == 'admin' || user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        allow = !add && currentEntity && currentEntity.vacation && currentEntity.vacation.user_id != userId && !allow;

        if (currentEntity && currentEntity.vacation && currentEntity.vacation.status == 'approved') allow = true;

        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <VacationsTotals user={currentEntity.vacation.user_id} currentvacation={vacationStore.currentEntity}/>
                <GenericForm
                    entity={currentEntity.vacation}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.vacation"
                    onChange={async data => vacationStore.recalc(data)}
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                    disallowSave={allow}
                />
                {showStatus && (
                    <AcceptFormBlock
                        params={{ id: this.props.id, status: currentEntity.vacation.status,data:vacationStore.currentEntity, entity: 'vacation' }}
                        afterSave={() => this.handleChangeStatus()}
                    />
                )}
                <Modal size="lg" show={show_modal} onHide={this.handleClosesApplyVacation}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Vacation')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <Input
                                className="custom-form__col"
                                label={`${t('To')} *`}
                                placeholder={t('Enter email address')}
                                type="text"
                                name="topm"
                                value={project_email!=''?project_email:''}
                                onChange={evt => {
                                   this.setState({project_email:evt.target.value});
                                }}
                                error={project_email ==='' || project_email === null}
                            />
                              {(project_email ==='' || project_email === null) &&(
                                  <span style={{color:'red'}}>{t('Email Address is required')}</span>  
                              ) }
                        <Textarea
                            label={t('Comment')}
                            value={vacation_note}
                            onChange={(e) => this.setState({vacation_note:e.target.value})}
                            //placeholder={t('Enter your comments')}
                            required
                            error={this.state.vacation_note === ''}
                        />    
                          {(vacation_note ==='' || vacation_note === null) &&(
                                  <span style={{color:'red'}}>{t('Comment is required')}</span>    
                              ) }
                       
                    </Modal.Body>
                    <Modal.Footer>
                                <div>
                                    <Button variant="secondary" onClick={this.applyVacation} className="btn-wd btn-fill">
                                        {t('Save')}
                                    </Button>
                                    <Button variant="secondary" onClick={this.handleClosesApplyVacation} className="btn-wd">
                                        {t('Close')}
                                    </Button>
                                </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default VacationsForm;
