import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { find, cloneDeep, sortBy } from 'lodash';
import MultiSelect from '@khanacademy/react-multi-select';

import { Input } from '../../../elements/Input';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RadioWidget from '../../../components/GenericForm/Widgets/RadioWidget';
import { ManagersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    classNames: 'no-border-around-lists',
    'ui:layout': [
        {
            members: {
                md: 12,
            },
        },
    ],
    members: {
        'ui:layout:hideframe': true,
        'ui:options': { orderable: false, removable: true, inline: true },
        className: 'form-chooser',
        items: {
            'ui:options': { orderable: false, removable: true, inline: true },
            className: 'form-chooser',
            user_id: {
                classNames: 'col-md-6',
                'ui:widget': 'MembersWidget',
            },
            additional_pay: { classNames: 'col-md-3', defaultValue: 0 },
            vacation_days: { classNames: 'col-md-3', defaultValue: 25 },
            hours: { classNames: 'col-md-6' },
            timeframe: {
                classNames: 'col-md-6',
                'ui:widget': 'Radio',
            },
        },
    },
};
const timeframeOptions = {
    enumOptions: [
        { value: 'daily', label: 'daily' },
        { value: 'weekly', label: 'weekly' },
        { value: 'monthly', label: 'monthly' },
    ],
};

@inject('projectStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ProjectsMembersForm extends Component {
    state = {
        users: null,
        members: null,
        selectedUsers: null,
        editingRowId: null,
        usersToAdd: null,
    };

    async loadData() {
        const { id, userStore } = this.props;
        if (!id) return;
        const user = await userStore.load(id);
        const users = await userStore.loadLookup('members', '');
        const members = this.getSortedMembers(cloneDeep(user.user.ManagesUsers), users);
        const membersIds = members.map(({ id }) => id);
        const selectedUsers = [ ...(new Set(membersIds)) ];
        const usersToAdd = this.getUsersToAdd(user.user.ManagesUsers, users);
        const userOptions = usersToAdd.map((r) => {
            return { value: r.id, label: r.fullname };
        });

        this.setState({
            users,
            selectedUsers,
            members,
            usersToAdd,
            userOptions,
        });
    }

    getSortedMembers(members, users) {
        members.forEach(member => {
            const user = find(users, r => r.id === member.user_id);
            member.fullname = user ? user.fullname : '???';
        });
        members = sortBy(members, r => r.fullname.toUpperCase());
        return members;
    }

    componentDidUpdate(previousProps) {
        const { id } = this.props;
        if (id !== previousProps.id) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async onSave() {
        const {
            userStore, id, t, commonStore, afterSave
        } = this.props;
        await userStore.save({
            id,
            data: {},
            ManagesUsers: this.state.selectedUsers.map(id => ({ id })),
        });
        commonStore.addNotification(t('Saved'), null, 'success');
        afterSave();
    }

    getUsersToAdd(members, users) {
        const toAdd = [];
        const memberIds = [];
        members.forEach(member => memberIds.push(member.user_id));
        users.forEach(user => {
            if (memberIds.indexOf(user.id) >= 0) return;
            toAdd.push(user);
        });
        return toAdd;
    }

    getUserNameById(id) {
        const user = find(this.state.users, r => r.id === id);
        if (!user) return '???';
        return `${user.fullname}`;
    }

    handleRemove(index) {
        const members = cloneDeep(this.state.members);
        members.splice(index, 1);
        this.setState({ members });
    }

    handleAdd(value, full) {
        console.log(value, full);
        const members = cloneDeep(this.state.members);
        members.unshift({
            id: parseInt(value),
            label: full.label,
        });
        const newToAdd = this.getUsersToAdd(members, this.state.users);
        this.setState({ members, usersToAdd: newToAdd });
    }

    async assignMembersByProject(id) {
        // var ids = [1,2,3,4,5]
        var userOptionsOld = this.state.userOptions;

        const {
            projectStore
        } = this.props;
        await projectStore.getProjectMembersIds(id).then(
            (data) => {
                let projectMemberIds = data.membersArray || [];
                this.setState({
                    selectedUsers: projectMemberIds,
                    members: userOptionsOld.filter(option => projectMemberIds.indexOf(option.value) !== -1).map(option => ({ id: option.value, label: option.label }))
                })
            }
        );
    }

    render() {
        const {
            users, members, editingRowId, usersToAdd, selectedUsers, userOptions
        } = this.state;
        const { id, t } = this.props;
        if (!users || !members || !id) {
            return <LoadingSpinner />;
        }

        // const userOptions = usersToAdd.map((r) => {
        //     return { value: r.id, label: r.fullname };
        // });

        return (
            <div style={{}}>

                <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="col-md-6">
                        <div className="form-group field field-string">
                            <label className="control-label">{t('Select Members')}</label>
                            <MultiSelect
                                options={userOptions}
                                selected={selectedUsers || []}
                                onSelectedChanged={(id) => {
                                    this.setState({ 
                                        selectedUsers: id,
                                        members: userOptions.filter(option => id.indexOf(option.value) !== -1).map(option => ({ id: option.value, label: option.label }))
                                    });
                                }}
                                overrideStrings={{
                                    selectSomeItems: t('Select people...'),
                                    allItemsAreSelected: t('All members are selected'),
                                    selectAll: t('Select All'),
                                    search: t('Search'),
                                }}
                            />
                        </div>
                    </div>
               
                    <div className="col-md-4">
                        <div className="form-group field field-string">
                            <label className="control-label">{t('Assigned members of projects')}</label>
                            <ProjectsWidget
                                value={''}
                                placeholder={'Assigned members of projects'}
                                onChange={(id) => this.assignMembersByProject(id)}
                            />
                        </div>
                    </div>
                    <div className="col-md-2">
                    <Button wd style={{marginTop: '1em'}} primary onClick={() => this.onSave()}>
                        {t('Save')}
                    </Button>
                    </div>
                </div>

                {/*
                <div style={{ display: 'flex', marginBottom: '15px' }}>
                    <span style={{ lineHeight: '30px', marginRight: '20px' }}>{t('Add user:')}</span>
                    <div style={{ minWidth: '300px' }}>
                        <ManagersWidget
                            directList={usersToAdd}
                            value={null}
                            onChange={(value, full) => this.handleAdd(value, full)}
                        />
                    </div>
                    <div style={{ width: '100%', textAlign: 'right' }}>
                        <Button primary onClick={() => this.onSave()}>
                            {t('Save')}
                        </Button>
                    </div>
                </div>
                */}
                <div style={{ overflowY: 'scroll', maxHeight: '500px' }}>
                    <table className="table table-hover table-striped project-members-form">
                    <thead>
                        <tr style={{ backgroundColor: '#447df7' }}>
                            <th style={{ color: 'white', fontWeight: '900' }}>{t('Member')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                        <tbody>
                            {members.map((member, rowIndex) => (
                                <tr key={`${member.user_id}`}>
                                    <td>{member.label ? member.label : `${member.first_name} ${member.last_name}`}</td>
                                    <td className="text-right">
                                        <Button className="btn btn-danger" onClick={() => {
                                            this.setState((state) => ({
                                                selectedUsers: state.selectedUsers.filter(id => id != member.id),
                                                members: state.members.filter(m => m.id != member.id)
                                            }))
                                        }} bsStyle="danger" simple icon>
                                            <i className="fa fa-times" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                </div>
            </div>
        );
    }
}

export default ProjectsMembersForm;
