import React, { Component } from 'react';
import _ from 'lodash';

import { Input, NumberInput, Textarea } from '../../elements/Input';
import AttachmentsWidget from '../GenericForm/Widgets/AttachmentsWidget';
import Signature from '../Signature/Signature';
import trashIcon from '../../assets/img/trash.svg';
import saveIcon from '../../assets/img/save.svg';
import Button from '../CustomButton/CustomButton.jsx';
import withLocalization from '../../hoc/withLocalization';
import config from '../../library/config';
import { Table } from 'react-bootstrap';

import Select from '~/elements/Select';

@withLocalization
class TimelogArray extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrayOfItems: [],
            entity: {
                type: '',
                value: '',
                signature: null,
                attachIds: '',
                comment: '',
            },
            wrongSubmit: false,
        };
    }

    componentDidMount() {
        const { initData } = this.props;
        this.setState({
            arrayOfItems: initData || [],
        });
    }

    addItem = e => {
        e.preventDefault();
        const state = Object.assign({}, this.state);
        const { arrayOfItems, entity } = state;

        if (entity.type && entity.value && entity.comment) {
            arrayOfItems.push(entity);
            state.entity = {
                type: entity.type,
                value: '',
                signature: null,
                attachIds: '',
                comment: '',
            };
            state.wrongSubmit = false;
            this.setState(state);
            this.props.onSave(arrayOfItems);
        } else {
            state.wrongSubmit = true;
            this.setState(state);
        }
    };

    removeItem = index => {
        const state = Object.assign({}, this.state);
        state.arrayOfItems = state.arrayOfItems.filter((item, i) => i !== index);
        this.setState(state);
        this.props.onSave(state.arrayOfItems);
    };

    handleSave = () => {
        this.props.onSave(this.state.arrayOfItems);
    };

    handleChangeEntity = (name, value) => {
        const state = Object.assign({}, this.state);
        state.entity[name] = value;
        this.setState(state);
    };

    getOptions = mode => {
        return this.props.options.map(o => {
            return { value: o.code, label: o.name };
        });
    };

    getUrl = file => {
        return `${config.UPLOADS_API_ENDPOINT}/${file}`;
    };

    render() {
        const { existingAttachment = [], t, mode } = this.props;
        const { entity, wrongSubmit, arrayOfItems } = this.state;
        const isExpence = mode == 'expenses';
        const options = this.getOptions(mode);

        const currentSelect = _.find(options, o => o.value == entity.type);

        return (
            <form className="timelog-array" onSubmit={this.addItem}>
                <div className="timelog-array__left">
                    <div className="timelog-array__left-inputs">
                        <Select
                            options={options}
                            value={currentSelect}
                            onChange={id => this.handleChangeEntity('type', id.value)}
                            style={{ width: 150 }}
                            placeholder={`${t('Type')} *`}
                        />
                        <NumberInput
                            label={`${t(isExpence ? 'Cost' : 'Hours')} *`}
                            value={entity.value}
                            onChange={e => this.handleChangeEntity('value', e.target.value)}
                            placeholder={t(isExpence ? 'Enter cost here' : 'Enter hours')}
                            className="timelog-array__input"
                            required
                        />
                        <Signature
                            onChange={signature => this.handleChangeEntity('signature', signature)}
                            initialSignature={entity.signature}
                            width="100%"
                            height={110}
                        />
                    </div>
                    <div className="timelog-array__attachment">
                        <div className="attachment-box">
                            <span className="attachment-box__label">{t('Upload Files')}</span>
                            <AttachmentsWidget
                                value={entity.attachIds}
                                onChange={attachIds => this.handleChangeEntity('attachIds', attachIds)}
                                options={{
                                    imageContext: {
                                        existingAttachment,
                                        fileType: 'docs',
                                        id: 0,
                                        model: 'Timelog',
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="timelog-array__comment">
                        <Textarea
                            label={`${t('Comment')} *`}
                            value={entity.comment}
                            onChange={e => this.handleChangeEntity('comment', e.target.value)}
                            placeholder={t('Enter comments here')}
                            required
                        />
                    </div>
                </div>
                <div className="timelog-array__right" style={{padding:'0'}}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('Type')}</th>
                                <th>{`${t(isExpence ? 'Other expances' : 'Extra work')} `}</th>
                                <th>{t('Amount')}</th>
                                <th>{t('Image')}</th>
                                <th>{t('Action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {arrayOfItems
                                && arrayOfItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.type}</td>
                                        <td style={{maxWidth:'80px',wordBreak:'break-all'}}>{item.comment}</td>
                                        <td style={{maxWidth:'80px',wordBreak:'break-all'}}>{item.value}</td>
                                        <td>{item.attachIds && item.attachIds.split(',').map(obj =>
                                            <img
                                                src={`${config.UPLOADS_API_ENDPOINT}/${obj}`}
                                                alt="N/A"
                                                className="uploads-item__img"
                                                onClick={() => window.open(this.getUrl(obj), '_blank')}
                                                style={{ paddingBottom: '0.1rem' }}
                                            />
                                        )}</td>
                                        <td><button
                                            type="button"
                                            className="timelog-array__col-action"
                                            onClick={() => this.removeItem(index)}
                                        >
                                            <img src={trashIcon} alt={t('Remove Item')} />
                                        </button></td>
                                    </tr>
                                ))}

                            {!arrayOfItems.length && (
                                <tr>
                                    {t('No rows found')}
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    {/* <div className="timelog-array__table">                       
                        <div className="row timelog-array__table">
                            <div className="col-sm-3 col-md-3">
                            #|   {t('Type')}
                            </div>
                             <div className="col-sm-3 col-md-3">
                              {`${t(isExpence ? 'Other expances' : 'Extra work')} `}
                            </div>
                            <div className="col-sm-2 col-md-2">
                                 {t('Amount')}
                            </div>
                            <div className="col-sm-2 col-md-2">
                                  image                                 
                            </div>
                            <div className="col-sm-2 col-md-2">
                                {t('Action')}
                            </div>
                        </div>
                     
                        {arrayOfItems
                            && arrayOfItems.map((item, index) => (
                                <div className="timelog-array__row" key={index}>
                                    <div className="timelog-array__col" style={{ width: '5%' }}>
                                        {index + 1}
                                    </div>
                                    <div
                                        className="timelog-array__col timelog-array__col_left"
                                        style={{ width: '35%' }}
                                    >
                                        {item.type}
                                    </div>
                                    <div
                                        className="timelog-array__col timelog-array__col_left"
                                        style={{ width: '35%' }}
                                    >
                                        {item.comment}
                                    </div>
                                    <div
                                        className="timelog-array__col timelog-array__col_left"
                                        style={{ width: '20%' }}
                                    >
                                        {item.value}
                                    </div>
                                    <div>
                                        {item.attachIds && item.attachIds.split(',').map(obj=>                                            
                                            <img
                                                src={`${config.UPLOADS_API_ENDPOINT}/${obj}`}
                                                alt="N/A"
                                                className="uploads-item__img"
                                                onClick={() => window.open(this.getUrl(obj), '_blank')}
                                                style={{paddingBottom:'0.1rem'}}
                                            />
                                        )}
                                    </div>
                                    <div className="timelog-array__col" style={{ width: '30%' }}>
                                        <button
                                            type="button"
                                            className="timelog-array__col-action"
                                            onClick={() => this.removeItem(index)}
                                        >
                                            <img src={trashIcon} alt={t('Remove Item')} />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        {!arrayOfItems.length && (
                            <div className="timelog-array__row timelog-array__row_not-found">{t('No rows found')}</div>
                        )}
                    </div> */}
                </div>
                <div className="timelog-array__footer">
                    <Button fill wd icon type="submit">
                        {this.props.t('Save and add new')} <i className="fa fa-plus" />
                    </Button>
                    {/* <Button fill wd icon onClick={this.handleSave}><img src={saveIcon} style={{paddingBottom: 3, marginRight: 5}} alt={t('Save')}/>{t('Save')}</Button>} */}
                </div>
            </form>
        );
    }
}

export default TimelogArray;
