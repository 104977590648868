import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import UsersList from './UsersList';
import UsersForm from './UsersForm';
import ImportUsers from './ImportUsers';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import { confirmAlert } from 'react-confirm-alert';

@inject('authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class UsersPage extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/members/add');
    }

    onTableAction(id, type) {
        const { history, authStore } = this.props;
        if (type === 'loginas') {
            authStore
                .loginAs(id)
                .then(() => {
                    window.location.href = `${window.location.href.split('/admin')[0]}/admin/?reload`;
                    // history.push(`/admin`);
                })
                .catch(e => {
                    this.props.commonStore.addNotification(e.message || this.props.t('Error'), null, 'error');
                });
            return;
        }
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.userStore.remove(id).then(res => {
                    this.props.userStore.resetLastListLoadTime();
                    return res;
                });
            });
        }

        if (type == 'active') {
            const str = `Are you sure , You want to active the user ?`;
            confirmAlert({
                title: this.props.t('Confirm to Active'),
                message: str,
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick:async () => {
                            await this.props.userStore.activeUser(id);
                            this.props.userStore.resetLastListLoadTime();
                            this.props.commonStore.addNotification(this.props.t('Saved'), null, 'success');
                        },
                    },
                    {
                        label: this.props.t('No'),
                        onClick: () => {},
                    },
                ],
            });
        }

        if (type !== 'active') {
        history.push(`/admin/members/${id}`);
        }
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return {
            editing: !!id,
            add: id === 'add',
            importing: id === 'import',
            id,
        };
    };

    render() {
        const mode = this.editingId();
        return (
            <div className="main-content">
                {!mode.editing && (
                    <UsersList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        mode="members"
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && !mode.importing && <UsersForm id={mode.id} add={mode.add} mode="members" />}
                {mode.importing && <ImportUsers />}
            </div>
        );
    }
}
export default UsersPage;
