import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import UserValidateBeforeAdd from './UserValidateBeforeAdd';
import AdminAddLimitBeforeAdd from './AdminAddLimitBeforeAdd';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/user';
import customerFeature from '~/utils/customerFeature';
import {getMinutesFromTimeInput} from '../../../utils/timeCalc'

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            image: { md: 4 },
            first_name: { md: 3 },
            last_name: { md: 3 },
            status: { md: 2 },
        },
        {
            email: { md: 3 },
            phone: { md: 3 },
            social_number: { md: 3 },
            birthday: { md: 3 },

        },
        {
            'ui:subtitle': 'Assecces',
            'ui:className': 'profile-edit-form__group',
            internal_number: { md: 4 },
            username: { md: 4 },
            password: { md: 4 },
        },
        {
            'ui:subtitle': 'System settings',
            'ui:className': 'profile-edit-form__group',
            payment_mode: { md: 4 },
            hourly_rate: { md: 4 },
        },
        {
            'ui:subtitle': 'Address information',
            'ui:className': 'profile-edit-form__group',
            address: { md: 4 },
            post_number: { md: 4 },
            post_place: { md: 4 },
        },
        {
            'ui:className': 'profile-edit-form__group',
            attachments: { md: 12 },
        },
    ],
    status: {
        'ui:widget': 'ActiveInactive',
    },
    image: {
        'ui:widget': 'ImageUpload',
    },
    disable_autolog: {
        'ui:widget': 'TrueFalse',
    },
    data: {
        'ui:field': 'layout',
        'ui:layout:hideframe': true,
        'ui:flex': true,
        'ui:layout': [
            {
                enable_pause_button: { className: 'col-md-4' },
                disable_manual_log: { className: 'col-md-4' },
            },
        ],
        enable_pause_button: {
            classNames: 'no-fieldset-title',
            'ui:widget': 'CheckBoxWidget',
        },
        disable_manual_log: {
            classNames: 'no-fieldset-title',
            'ui:widget': 'CheckBoxWidget',
        },
    },

    attachments: {
        'ui:widget': 'AttachmentsWidget',
        'ui:imageContext': {
            model: 'User',
            fileType: 'docs',
            id: 0,
        },
    },
    ManagesUsers: {
        items: {
            id: {
                'ui:widget': 'MembersWidget',
            },
        },
    },
    MemberInProjects: {
        // 'ui:widget': 'MemberInProjectsWidget',
        'ui:widget': 'MemberInProjectsWidgetMultiSelect',        
    },
    // AssignedDocuments:{
    //     'ui:widget': 'DocumentTemplate',
    // }
};

@inject('userStore', 'commonStore', 'documentStore')
@withRouter
@withLocalization
@observer
class UsersForm extends Component {
    state = {
        schema: null,
        uiSchema: defaultUiSchema,
        assignedDocuments: [],
    };

    getListUrl() {
        const { mode } = this.props;
        if (mode == 'superadmins') return '/admin/superadmins';
        if (mode == 'members') return '/admin/members';
        if (mode == 'managers') return '/admin/managers';
        if (mode == 'users') return '/admin/users';
        if (mode == 'profile') return '/admin/profile';
    }

    async loadData() {
        const { userStore, id, add, mode, history, commonStore } = this.props;
        const { loading, currentEntity } = userStore;
        await this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
                isAdd: add,
                mode,
            }),
        });
        
        const params = queryString.parse(history.location.search) || {};
        this.setState({
            uiSchema: this.prepareSchema(defaultUiSchema, currentEntity.user, mode, userStore.currentUser),
        });
        if (add) {
            if (mode !== 'superadmins') {
                await userStore.validateAddPossible();
            }

            let vacation_days = 25;
            if(commonStore.config && commonStore.config.client 
                && commonStore.config.client.data
                && commonStore.config.client.data.Vacation_setup 
                && commonStore.config.client.data.Vacation_setup.standared_vacation_days)
                vacation_days = commonStore.config.client.data.Vacation_setup.standared_vacation_days;

            return userStore.returnDefaultNew(mode, userStore.currentUser.client_id, params, userStore.currentUser, vacation_days);
        }
        userStore.load(id, add);
    }

    componentDidMount() {
        this.loadData();        
    }

    componentWillMount() {
        this.props.userStore.loading = true;
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    addAssignedDocuments = async (user_id) => {
        const {documentStore } = this.props;
        let data = {
            document_template_ids: this.state.assignedDocuments,
            user_id : user_id
        }        
        await documentStore.assignDocumentTemplatesToUser(data);
    }

    validateUser(formData,errors){        
        const {t} = this.props;
        // console.log("Checking form : ",formData,errors);
        if(formData.birthday && moment(formData.birthday).isSameOrAfter(moment())){                                   
            errors.birthday.addError(t("Please enter valid birthdate"));
        }         
        // if(formData.username == "" || ( formData.username && formData.username.trim() === '')){
        if(formData.username && formData.username.trim() === ''){
            errors.username.addError(t("is a required property"));
        }
        return errors;
    }


    onSave(values) {
        const { userStore, history, add, t, commonStore, mode } = this.props;    
        values.isAdminOrManager = true;  
        if(values.start_time)
            values.start_time = getMinutesFromTimeInput(values.start_time)
        if(values.end_time) 
            values.end_time = getMinutesFromTimeInput(values.end_time)
        //taking username from email if not provided//
        if(!values.username)
            values.username = values.email.split('@')[0];
        
        if(!values.MemberInProjects && values.user_type !== 'admin' && values.user_type !== 'super-admin'){
            commonStore.addNotification(t('Select projects for member'), null, 'error');
            return;
        }
        //console.log("VALUES ",values,commonStore);
        return userStore.save(values, add).then((result) => {
            if (!result.user || !result.user.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            if(result.user.id && result.user.status === 'active'){
                this.addAssignedDocuments(result.user.id);
            }                    
            commonStore.addNotification(t('Saved'), null, 'success');
            history.push(this.getListUrl());
            return true;
        });
    }

    prepareSchema(_schema, currentEntity, mode, currentUser) {
        const clientConfig = this.props.commonStore.config.client.data;
        const autoTimelogsEnabled =
            clientConfig && clientConfig.loginRules ? clientConfig.loginRules.autoTimelogs !== 'disabled' : false;

        const schema = cloneDeep(_schema);
        schema.image = {
            'ui:widget': 'ImageUpload',
            'ui:imageContext': {
                model: 'User',
                fileType: 'logo',
                id: currentEntity ? currentEntity.id : 0,
            },
        };
        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'User',
                fileType: 'docs',
                id: currentEntity ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        if (mode == 'members') {
            //  && mode != 'profile'
            const accessObj = {
                'ui:subtitle': 'Access Information',    
                'ui:className': 'profile-edit-form__group',
            };
            schema['ui:layout'][3].timelog_start_from = { md: 4 };
            schema['ui:layout'][3].employee_percent = { md: 4 };
            accessObj.username = { md: 4 };
            accessObj.user_type = { md: 4 };
            accessObj.user_title = { md: 4 };
            accessObj.internal_number = { md: 4 };
            if(currentUser.user_type !== 'pm' && currentUser.user_type !== 'admin'){
                accessObj.password = { md: 4 };
            }
           // accessObj.password = { md: 4 };
            if (currentUser.role !== 'member' && autoTimelogsEnabled) {
                //  && mode != 'profile'
                schema['ui:layout'][3].disable_autolog = { md: 4 };
            }
            if (customerFeature('locked_mode')) {
                //  && mode != 'profile'
                accessObj.generic_pin = { md: 4 };
            }
            if (currentUser.role !== 'member') {
                //  && mode != 'profile'
                schema['ui:layout'][3].MemberInProjects = { md: 4 };
                // schema['ui:layout'][3].AssignedDocuments = { md: 4 };
            }
            accessObj.vacation_days = { md: 4 };

            schema['ui:layout'][2] = accessObj;
        }
        if (mode == 'managers') {
            schema['ui:layout'].push({
                ManagesUsers: { md: 12 },
            });
        }
        return schema;
    }

    handleAssignDocuments = (ids) => {        
        this.setState({ assignedDocuments: ids });
    }

    render() {
        const { userStore, add, mode } = this.props;
        const { loading, currentEntity, addPossibility } = userStore;
        const { schema, uiSchema } = this.state;           
        if (loading) {
            return <LoadingSpinner />;
        }

        const canProceedEdit = !add || currentEntity.user.social_number || currentEntity.user.user_type !== 'member';        

        const addValid = !add || addPossibility.allowed;
        
        let assignDocument = false;
        if (userStore.currentUser.role !== 'member') {            
            assignDocument = true;
        }
        
        return (
            <div className="primary-page profile-edit-form">
                {!addValid && <AdminAddLimitBeforeAdd />}

                {/* removing social and number page as per request - 28/10/2021 */}
                {/* {addValid && !canProceedEdit && <UserValidateBeforeAdd />} */}
                
                {/* removed caneditProceed condition- 28/10/2021 */}
                {/* {addValid && canProceedEdit && ( */}
                
                {addValid && (
                    <GenericForm
                        entity={currentEntity.user}
                        uiSchema={this.prepareSchema(defaultUiSchema, currentEntity.user, mode, userStore.currentUser)}
                        schema={schema}
                        translationScope="forms.user"
                        onSave={(values) => this.onSave(values)}
                        listUrl={this.getListUrl()}
                        isAdding={add}
                        assignDocument={assignDocument}
                        handleAssignDocuments={(ids) => this.handleAssignDocuments(ids)}
                        customvalidate = {(formData,errors)=>this.validateUser(formData,errors)}
                    />
                )}
            </div>
        );
    }
}

export default UsersForm;
