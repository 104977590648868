import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col, Grid, Row } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';

import CustomRadio from '../../../components/DidNotWork/DidNotWork';
import Input from '../../../elements/Input';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import AttachmentsWidget from '../../../components/GenericForm/Widgets/AttachmentsWidget';

import DateRange from '~/components/DateRange/DateRange';
import Button from '~/components/CustomButton/CustomButton.jsx';
import StatsCard from '~/components/Card/StatsCard.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';
import withLocalization from '~/hoc/withLocalization';

@inject('dashboardStore', 'userStore', 'commonStore')
@withLocalization
@withRouter
@observer
class SendReport extends Component {
    state = {
        email: null,
        dateRange: null,
        sending: false,
        approved_only: false,
        include_images: true,
        include_extra: true,
        include_extra_images: false,
        attachments: '',
    };

    getReportMode() {
        const { search } = this.props.history.location;
        if (search.indexOf('mode=coop') >= 0) return 'cooperation';
        return 'default';
    }

    loadData() {
        const user = this.props.userStore.currentUser;
        if (user.user_type == 'member' && user.Managed && user.Managed.email) {
            this.setState({ email: user.Managed.email });
        }
        this.setState({ dateRange: moment.range(moment(new Date()).startOf('month'), moment(new Date())) });
    }

    componentDidMount() {
        this.loadData();
    }

    onSelect = dateRange => {
        this.setState({ dateRange });
    };

    onEmailChange = e => {
        this.setState({ email: e.target.value });
    };

    onCheckboxChange = (name, value) => {
        this.setState({ [name]: value });
    };

    applyForm = () => {
        const mode = this.getReportMode();
        if (!this.state.email || this.state.email.indexOf('@') < 0) {
            commonStore.addNotification(t('Fill email'), null, 'error');
            return;
        }
        this.setState({ sending: true });
        const {
            t, dashboardStore, userStore, commonStore
        } = this.props;
        const hydrated = {
            mail: true,
            user_id: userStore.currentUser.id,
            mode,
            email: this.state.email,
            start: this.state.dateRange.start.format('YYYY-MM-DD'),
            end: this.state.dateRange.end.format('YYYY-MM-DD'),
            include_images: this.state.include_images || null,
            include_extra: this.state.include_extra || null,
            include_extra_images: this.state.include_extra_images || null,
            attachments: this.state.attachments,
        };
        console.log(hydrated);
        userStore
            .sendReport(hydrated)
            .then(res => {
                this.setState({ sending: false });
                commonStore.addNotification(t('Sent'), null, 'success');
            })
            .catch(err => {
                this.setState({ sending: false });
                commonStore.addNotification(err.message || t('Error'), null, 'error');
            });
    };

    render() {
        const { t, dashboardStore, userStore } = this.props;
        const { sending } = this.state;
        const userType = userStore.currentUser.user_type;
        const mode = this.getReportMode();
        return (
            <div>
                <div className="panel" style={{ margin: 20, padding: 20 }}>
                    <h4>
                        {t('Send report')}
                        <br />
                        <small>
                            {mode == 'cooperation' && t('Cooperation')}
                            {mode == 'default' && t('Association')}
                        </small>
                    </h4>
                    <Input label={t('To email')} value={this.state.email} onChange={this.onEmailChange} />
                    {mode == 'default' && (
                        <div>
                            <CustomCheckbox
                                label={t('Include images')}
                                onChange={v => this.onCheckboxChange('include_images', v)}
                                isChecked={this.state.include_images}
                                inline
                            />
                            <CustomCheckbox
                                label={t('Include extra work')}
                                onChange={v => this.onCheckboxChange('include_extra', v)}
                                isChecked={this.state.include_extra}
                                inline
                            />
                            <CustomCheckbox
                                label={t('Include extra work images')}
                                onChange={v => this.onCheckboxChange('include_extra_images', v)}
                                isChecked={this.state.include_extra_images}
                                inline
                            />
                        </div>
                    )}
                    {mode == 'cooperation' && (
                        <div>
                            <CustomCheckbox
                                label={t('Approved only')}
                                onChange={v => this.onCheckboxChange('approved_only', v)}
                                isChecked={this.state.approved_only}
                                inline
                            />
                            <CustomCheckbox
                                label={t('Include images')}
                                onChange={v => this.onCheckboxChange('include_images', v)}
                                isChecked={this.state.include_images}
                                inline
                            />
                            <CustomCheckbox
                                label={t('Include extra work')}
                                onChange={v => this.onCheckboxChange('include_extra', v)}
                                isChecked={this.state.include_extra}
                                inline
                            />
                            <CustomCheckbox
                                label={t('Include extra work images')}
                                onChange={v => this.onCheckboxChange('include_extra_images', v)}
                                isChecked={this.state.include_extra_images}
                                inline
                            />
                        </div>
                    )}
                    <DateRange
                        numberOfCalendars={3}
                        value={this.state.dateRange}
                        onSelect={this.onSelect}
                        style={{ marginTop: 15 }}
                    />
                    <h4>
                        <small>{t('Extra documents')}</small>
                    </h4>
                    <AttachmentsWidget
                        value={this.state.attachments}
                        onChange={attachIds => this.setState({ attachments: attachIds })}
                        options={{
                            checkDate: false,
                            imageContext: {
                                existingAttachment: [],
                                fileType: 'docs',
                                id: 0,
                                model: 'Timelog',
                            },
                        }}
                    />
                    <div className="did-not-work__buttons">
                        {sending && <div>Sending</div>}
                        {!sending && (
                            <Button variant="primary" onClick={this.applyForm}>
                                {t('Send')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default SendReport;
