import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import queryString from 'query-string';

import LoadingSpinner from '../../../elements/LoadingSpinner';
import GenericForm, { isTrue } from '../../../components/GenericForm/GenericForm';
import withLocalization from '../../../hoc/withLocalization';

import getSchema from '~/library/schemas/client';

const schema = getSchema();
const listUrl = '/admin/clients';

const uiSchema = {
    'ui:field': 'layout',
    'ui:layout': [
        {
            name: { md: 4 },
            status: { md: 4 },
            image: { md: 4 },
        },
        {
            allow_visitor_only: { md: 12 },
        },
        {
            max_users: { md: 6 },
            max_users_requested: { md: 6 },
        },
        {
            renew_date: { md: 4 },
            renew_manual: { md: 4 },
            allow_system_document: { md: 4 },
        },
        {
            biztype: { md: 4 },
            address: { md: 4 },
            tax_number: { md: 4 },
        },
        /* {
      description: {
        md: 12,
        render: (props) => {
          const { formData } = props;
          const {   tax_number } = formData;

          return (
            <div>
              <b>Active users</b>: {tax_number}
            </div>
          )
        }
      }
    }, */
        {
            User: { md: 12 },
        },
    ],
    status: {
        'ui:widget': 'ActiveInactive',
    },
    biztype: {
        'ui:widget': 'BusinessTypesSelect',
    },
    image: {
        'ui:widget': 'ImageUpload',
        'ui:imageContext': {
            model: 'Client',
            fileType: 'logo',
            id: 0,
        },
    },
    renew_manual: {
        'ui:widget': 'Radio',
    },
    allow_system_document: {
        'ui:widget': 'Radio',
    },
    allow_visitor_only: {
        'ui:widget': 'Radio',
    },
    User: {
        'ui:field': 'layout',
        'ui:layout': [
            { first_name: { md: 4 }, last_name: { md: 4 }, email: { md: 4 } },
            { phone: { md: 4 }, username: { md: 4 }, password: { md: 4 } },
        ],
    },
};

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ClientsForm extends Component {
    loadData() {
        //console.log('LD');
        const {
            clientStore, id, add, location, match, commonStore
        } = this.props;
        const clientType = location.search.indexOf('type=') >= 0
            ? parseInt(queryString.parse(location.search).type)
            : commonStore.config.biztypes[0].id;
        if (add) return clientStore.returnDefaultNew({ biztype: clientType });
        clientStore.load(id, add);
    }

    componentWillMount() {
        this.props.clientStore.loading = true;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    onSave(values) {
        const {
            clientStore, history, add, t, commonStore
        } = this.props;
        values.User.user_type = 'admin';        
        return clientStore.save(values, add).then(result => {
            // console.log("!", result);
            if (!result.client || !result.client.id) {
                // some error
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            history.push(listUrl);
            return true;
        });
    }

    transformForSchema = entity => {
        const e = cloneDeep(entity);
        if (e.renew_date && typeof e.renew_date === 'string') e.renew_date = e.renew_date.split('T')[0];
        return e;
    };

    render() {
        const { clientStore, add } = this.props;
        const { loading, currentEntity } = clientStore;        
        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <GenericForm
                    entity={this.transformForSchema(currentEntity.client)}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.client"
                    onSave={values => this.onSave(values)}
                    listUrl={listUrl}
                    isAdding={add}
                />
            </div>
        );
    }
}

export default ClientsForm;
