import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col, Tab } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import General from './General';
import OverTimeCalculation from './OverTimeCalculation';
import RulesForStartTime from './RulesForStartTime';
import RulesForBreakTime from './RulesForBreakTime';
import WorklogValidation from './WorklogValidation';
import ParentalLeaves from './ParentalLeaves';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class BasicRules extends Component {
    constructor(props) {       
        super(props);          
        this.state = {
            subTab: 'general',            
        }
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
    }

    componentDidMount() {       
    }

    handleUserDataChange = (name,value) =>{
        console.log(name,value);
        const {currentEntity} = this.props.clientStore;
        if(currentEntity.client.data && currentEntity.client.data.basicRules){
            currentEntity.client.data.basicRules[name]=value;
        }else{
            let basicRules = {} ;
            basicRules[name]=value;
            currentEntity.client.data.basicRules = basicRules;
        }
    }
       
    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    render() {
        const { subTab} = this.state;
        const { t} = this.props;
        return (
            <Tab.Container activeKey={subTab} id="BasicTabContainer" onSelect={(key)=>console.log(key)} >
                <div className="custom-card">
                    <Row>
                        <Col sm={3}>                            
                            <ul className="list-group">
                                <li className={subTab === 'general' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('general')}>{t('General')}</li>
                                <li className={subTab === 'overTimeCal' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('overTimeCal')}>{t('Overtime Calculation')}</li>
                                <li className={subTab === 'rulesForStartTime' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('rulesForStartTime')}>{t('Rules for Start & Break Time')}</li>                                
                                <li className={subTab === 'workloadValidation' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('workloadValidation')}>{t('Worklog Validation')}</li>
                                <li className={subTab === 'parentalLeaves' ? 'list-group-item active' : 'list-group-item'}
                                    onClick={() => this.handleSubTabChange('parentalLeaves')}>{t('Parental Leaves')}</li>    
                            </ul>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="general">
                                    <General handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="overTimeCal">
                                    <OverTimeCalculation handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="rulesForStartTime">
                                    <RulesForStartTime handleUserDataChange = {this.handleUserDataChange}/>
                                    <RulesForBreakTime handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>                                
                                <Tab.Pane eventKey="workloadValidation">
                                    <WorklogValidation handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="parentalLeaves">
                                    <ParentalLeaves handleUserDataChange = {this.handleUserDataChange}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </div>
            </Tab.Container>
        );
    }
}
export default BasicRules;
