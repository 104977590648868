import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Col, Grid, Row } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';

import './dashboard.css';
import ChartistGraph from 'react-chartist';
import { Map, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import Button from '../../components/CustomButton/CustomButton.jsx';
import DatePicker from '../../components/DatePicker/DatePicker';
import withLocalization from '../../hoc/withLocalization';
import MapMarker from '../../elements/MapMarker';
import ChartPie from '../../elements/ChartPie';
import BannerView from './Banner/BannerView';

import TimelogDashboardWidget from './Timelogs/TimelogDashboardWidget';
import DeviationDashboardWidget from './Deviation/DeviationDashboardWidget';

// Leaflet

import { leafletStringToLocation, locationToString, googleCoordsToLeaflet } from '~/utils/geo';
import { minutesToTimeInput } from '~/utils/timeCalc';
import config from '~/library/config';
import * as core from '~/library/core';
import CompanyCard from '~/components/Card/CompanyCard.jsx';
import StatsCard from '~/components/Card/StatsCard.jsx';
import LoadingSpinner from '~/elements//LoadingSpinner';

const DEFAULT_LOCATION = [59.911442, 10.75905];

@inject('dashboardStore', 'userStore', 'commonStore','bannerStore')
@withLocalization
@withRouter
@observer
class Dashboard extends Component {
    state = {
        from: moment(new Date()).startOf('month').toDate(),
        to: moment(new Date()).toDate(),
        activeProject: null,
        banner_name:'',
        editorHTML: '',
        banner_view:false,
        theme : 'snow',
        modules: {
            toolbar: [
                
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        },
        formats: [
            // 'header', 'font', 'size',
            // 'bold', 'italic', 'underline', 'strike', 'blockquote',
            // 'list', 'bullet', 'indent',
            // 'link', 'image', 'video'
        ],

        placeholder: 'Write Something',
        bannersInfo:[]
    };

    componentDidMount() {
        this.loadData();
    }

    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };

    loadData() {
        const { currentUser } = this.props.userStore;
        const { dashboardStore, history } = this.props;
        const { from, to, } = this.state;
        let getBanners = [];
        dashboardStore.load({ from: moment(from).format('YYYY-MM-DD'), to: moment(to).format('YYYY-MM-DD') }).then(response=>{
            //console.log('banners length',response.banners);
            if(response.banners && response.banners.length >0){
                getBanners = response.banners;
                console.log('called');
                // let bannerName = response.banners[0].name;
                // let body = response.banners[0].body;
                // let id = response.banners[0].id
                this.setState({bannersInfo:getBanners,banner_view:true,});
            }
            else{
                this.setState({banner_view:false});
            }
        });
        if (currentUser && currentUser.is_now_locked) {
            history.push('/admin/locked');
        }
    }

    renderSuperAdmin() {
        const { t, dashboardStore, userStore } = this.props;
        const { loading, currentData } = dashboardStore;
        if (!currentData.clients) {
            return null;
        }
        const hoursChart = {
            type: 'Line',
            options: {
                showPoint: true,
                height: '260px',
                lineSmooth: true,
                axisX: {
                    showGrid: false,
                    showLabel: false,
                },
                axisY: {
                    offset: 40,
                },
                chartPadding: {
                    right: -18,
                },
            },
        };
        return (
            <div className="main-content">
                <Grid fluid>
                    {this.renderDatepicker()}
                    <Row>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="pe-7s-wallet text-warning" />}
                                statsText={t('Clients')}
                                statsValue={currentData.clients[0].count}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText=""
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="pe-7s-server text-warning" />}
                                statsText={t('Users')}
                                statsValue={currentData.users[0].count}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText=""
                            />
                        </Col>
                    </Row>
                    {this.renderChart()}
                </Grid>
            </div>
        );
    }

    renderDatepicker() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to } = this.state;

        return (
            <Row style={{ marginBottom: '20px' }}>
                <Col lg={12}>
                    <h4 className="dashboard-picker-title">{t('From')}</h4>

                    <DatePicker
                        selected={from}
                        onChange={(date) => this.handleChange('from', date)}
                        dateFormat="EEEE MMMM dd, yyyy"
                    />
                    <h4 className="dashboard-picker-title">{t('To')}</h4>

                    <DatePicker
                        selected={to}
                        onChange={(date) => this.handleChange('to', date)}
                        dateFormat="EEEE MMMM dd, yyyy"
                    />
                    <Button fill round wd onClick={() => this.loadData()} style={{ margin: '15px' }}>
                        {t('Submit')}
                    </Button>
                </Col>
            </Row>
        );
    }

    renderChart() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to } = this.state;

        if (!dashboard.timeList) return null;

        const hoursChart = {
            type: 'Line',
            options: {
                showPoint: true,
                height: '260px',
                lineSmooth: true,
                axisX: {
                    showGrid: false,
                    showLabel: false,
                },
                axisY: {
                    offset: 40,
                },
                chartPadding: {
                    right: -18,
                },
            },
        };

        return (
            <div className="card card-block">
                <div className="content">
                    <ChartistGraph data={{data:dashboard.timeList}} type={hoursChart.type} options={hoursChart.options} />
                </div>
            </div>
        );
    }

    renderProjects() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to } = this.state;

        return (
            <div className="card card-block">
                <div className="content">
                    <h5>{t('Projects')}</h5>
                    {dashboard.projects.map((project, index) => (
                        <div>
                            <h5>{project.name}</h5>
                            <div className="totals project">
                                <div className="label">{t('Planned hours')}</div>
                                <div className="value">
                                    {' '}
                                    {project.hours} {t('monthly')}
                                </div>
                            </div>
                            <div className="totals hours">
                                <div className="label">{t('Total Hours')}</div>
                                <div className="value">{minutesToTimeInput(project.total_normal || 0, false)}</div>
                            </div>
                            <div className="totals hours">
                                <div className="label">{t('Overtime')}</div>
                                <div className="value">{minutesToTimeInput(project.total_overtime || 0, false)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    closeModal(){
        const{banner_id,bannersInfo} = this.state;
        let banner_ids=[];
        bannersInfo.forEach(item=>{
            banner_ids.push(item.id);
        })
        this.setState({banner_view:false},()=>{
            this.props.bannerStore.sendBannerIds({banner_ids:banner_ids});
        });
    }

    renderMember() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        const dashboard = currentData;
        const { from, to,banner_view,banner_name,banner_id,editorHTML,bannersInfo } = this.state;
       
        const { data: biztypeData } = commonStore.config.biztype;

        let rate = userStore.currentUser.hourly_rate;
        let earned = 0;

        if (rate && dashboard.grandtotals 
            && (userStore.currentUser.payment_mode !== 'fixed') ) {
            earned = parseInt(
                (rate * (dashboard.grandtotals.total_normal + dashboard.grandtotals.total_overtime)) / 60
            );
            earned = `${earned} ${core.moneySymbol()}`;
            rate = `${rate} / ${core.hourSymbol()}`;
        } 
        else if(userStore.currentUser.payment_mode === 'fixed'){
            earned = `${rate} ${core.moneySymbol()}`;
            rate = `${rate} / fixed`;
        }
        else {
            earned = '?';
            rate = t('Rate not set');
        }

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row key="timelog-member" className="dashboard-stats">
                        <TimelogDashboardWidget />
                        <Col lg={3} sm={6}>
                            <CompanyCard
                                bigIcon={
                                    commonStore.config.client.image ? (
                                        <img
                                            src={`${config.UPLOADS_API_ENDPOINT}/${commonStore.config.client.image}`}
                                        />
                                    ) : (
                                        <i className="pe-7s-users text-success" />
                                    )
                                }
                                statsText={commonStore.config.client.name}
                                statsValue=""
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={
                                    biztypeData.hide_send_report ? null : (
                                        <a href="#" onClick={() => this.props.history.push('/admin/send_report')}>
                                            {t('Contact manager')}
                                        </a>
                                    )
                                }
                            />
                        </Col>
                        {dashboard.grandtotals && (
                            <Col lg={3} sm={6}>
                                <StatsCard
                                    bigIcon={<i className="pe-7s-hourglass text-info" />}
                                    statsText={t('My total this month')}
                                    statsValue={
                                        <small>
                                            {`${minutesToTimeInput(dashboard.grandtotals.total_normal || 0, false)}`}
                                        </small>
                                    }
                                    statsIcon={<i className="fa fa-refresh" />}
                                    statsIconText={`${minutesToTimeInput(
                                        dashboard.grandtotals.total_overtime || 0,
                                        false
                                    )} ${t('Overtime')}`}
                                />
                            </Col>
                        )}
                        {dashboard.grandtotals && (
                            <Col lg={3} sm={6}>
                                <StatsCard
                                    bigIcon={<i className="pe-7s-piggy text-info" />}
                                    statsText={t('Earning this month')}
                                    statsValue={<small>{`${earned}`}</small>}
                                    statsIcon={<i className="fa fa-refresh" />}
                                    statsIconText={`${rate}`}
                                />
                            </Col>
                        )}
                    </Row>
                    {!!biztypeData.enable_deviations && !biztypeData.union_group && (
                        <Row key="timelog-member-bz" className="deviation">
                            <DeviationDashboardWidget data={dashboard} />
                        </Row>
                    )}

                    <Row>
                        <Col sm={12} lg={6}>
                            {this.renderChart()}
                        </Col>
                        <Col sm={12} lg={6}>
                            {this.renderProjects()}
                        </Col>
                    </Row>
                </Grid>
                {
                    banner_view && (
                        <>
                        <BannerView  closeModal={()=>this.closeModal()} bannersInfo={bannersInfo} banner_view={banner_view} />
                        </>
                    )
                }
            </div>
        );
    }

    renderAdmin() {
        const {
            t,
            dashboardStore: { currentData },
            commonStore,
            userStore,
        } = this.props;
        const{bannersInfo,banner_view} = this.state;
        const activeProject = this.state.activeProject || currentData.projects[0];
        const usersCounters = currentData.usersCounters ? currentData.usersCounters[0] : {};
        if (!currentData.todayAtWork) return null;
        const { shifts } = currentData.todayAtWork;
        const totalNormal = currentData.overallWorkhours[0].total_normal;
        const totalOvertimes = currentData.overallWorkhours[0].total_overtime;
        let totalCosts = currentData.overallWorkhours[0].total_costs;
        let totalCostsOvertime = currentData.overallWorkhours[0].total_costs_overtime;
        let totalFixedCosts = currentData.overallWorkhours[0].total_fixed_costs;          
        const { data: biztypeData } = commonStore.config.biztype;
        const userType = userStore.currentUser.user_type;

        if (!totalCosts) {
            totalCosts = !totalFixedCosts?0:parseInt(totalFixedCosts, 10);
        } else {
            totalCosts = parseInt(totalCosts, 10) + (!totalFixedCosts?0:parseInt(totalFixedCosts, 10));
        }
        if (!totalCostsOvertime) {
            totalCostsOvertime = 0;
        } else {
            totalCostsOvertime = parseInt(totalCostsOvertime, 10);
        }
        if (!currentData.projects) {
            return null;
        }
        const chartData = [
            {
                value: usersCounters.working_now,
                view: 'blue',
                label: `${t('Working now')} [${usersCounters.working_now}]`,
            },
            {
                value: usersCounters.gone_home,
                view: 'orange',
                label: `${t('Gone home')} [${usersCounters.gone_home}]`,
            },
            {
                value: usersCounters.all_users - usersCounters.gone_home - usersCounters.working_now,
                view: 'red',
                label: `${t('Not at work')} [${
                    usersCounters.all_users - usersCounters.gone_home - usersCounters.working_now
                }]`,
            },
        ];

        return (
            <div className="main-content">
                {/* <pre>{JSON.stringify(currentData, null, 4)}</pre> */}
                <Grid fluid>
                    <Row>
                        {userType === 'pm' && <TimelogDashboardWidget />}
                        <Col lg={3} sm={6}>
                            <CompanyCard
                                bigIcon={
                                    commonStore.config.client.image ? (
                                        <img
                                            src={`${config.UPLOADS_API_ENDPOINT}/${commonStore.config.client.image}`}
                                        />
                                    ) : (
                                        <i className="pe-7s-users text-success" />
                                    )
                                }
                                statsText={commonStore.config.client.name}
                                statsValue=""
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={t('Your company')}
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-clock-o text-warning" />}
                                statsText={t('Total Hours')}
                                statsValue={minutesToTimeInput(totalNormal || 0, false)}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={t('This month')}
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-black-tie text-danger" />}
                                statsText={t('Overtime')}
                                statsValue={minutesToTimeInput(totalOvertimes || 0, false)}
                                statsIcon={<i className="fa fa-refresh" />}
                                statsIconText={t('This month')}
                            />
                        </Col>
                        {userType !== 'pm' && (
                            <Col lg={3} sm={6}>
                                <StatsCard
                                    bigIcon={<i className="pe-7s-wallet text-success" />}
                                    statsText={t('Total Costs')}
                                    statsValue={`${totalCosts + totalCostsOvertime} ${core.moneySymbol()}`}
                                    statsIcon={<i className="fa fa-refresh" />}
                                    statsIconText={`${t('Overtime')} ${totalCostsOvertime} ${core.moneySymbol()}`}
                                />
                            </Col>
                        )}
                    </Row>
                    {!!biztypeData.enable_deviations && !biztypeData.union_group && (
                        <Row key="timelog-admin-bz" className="deviation">
                            <DeviationDashboardWidget data={currentData} />
                        </Row>
                    )}
                    {/* <Row>
                        <Col md={12}>
                            <div className="card ">
                                <div className="card-header ">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/projects">{t('My Projects')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body ">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="horizontal-scroll_wrap">
                                                <div className="horizontal-scroll">
                                                    <table className="table table-project-small">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">ID</th>
                                                                <th>{t('Project')}</th>
                                                                <th>{t('Members')}</th>
                                                                <th className="text-center">{t('Tasks')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {currentData.projects.map((project) => (
                                                                <tr
                                                                    key={project.id}
                                                                    className={`table-project-small__row ${
                                                                        project.id === activeProject.id
                                                                            ? 'table-project-small__row_active'
                                                                            : ''
                                                                    }`}
                                                                    onClick={() =>
                                                                        this.setState({ activeProject: project })
                                                                    }
                                                                >
                                                                    <td className="text-center">{project.id}</td>
                                                                    <td>{project.name}</td>
                                                                    <td>{project.members_count}</td>
                                                                    <td>
                                                                        <NavLink
                                                                            className="table-project-small__tasklink"
                                                                            to={`/admin/tasks?project=${project.id}`}
                                                                        >
                                                                            {project.tasks_count}
                                                                        </NavLink>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="project-map">
                                                {activeProject && (
                                                    <Map
                                                        center={
                                                            activeProject.gps_data
                                                                ? leafletStringToLocation(activeProject.gps_data)
                                                                : DEFAULT_LOCATION
                                                        }
                                                        className="markercluster-map"
                                                        zoom={12}
                                                        maxZoom={18}
                                                    >
                                                        <TileLayer
                                                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <MarkerClusterGroup>
                                                            {currentData.projects.map((project) => (
                                                                <MapMarker
                                                                    key={project.id}
                                                                    id={project.id}
                                                                    isActive={activeProject.id === project.id}
                                                                    position={
                                                                        activeProject.gps_data
                                                                            ? leafletStringToLocation(project.gps_data)
                                                                            : DEFAULT_LOCATION
                                                                    }
                                                                    title={project.name}
                                                                    riseOnHover
                                                                />
                                                            ))}
                                                        </MarkerClusterGroup>
                                                    </Map>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={4}>
                            <div className="card">
                                <div className="card-header card-header-with-button">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/timelogs?status=draft">{t('Members')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body " style={{ minHeight: '320px' }}>
                                    <ChartPie data={chartData} />
                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="card">
                                <div className="card-header card-header-with-button">
                                    <h4 className="card-title">
                                        <NavLink to="/admin/schedule">{t('Users Schedules')}</NavLink>
                                    </h4>
                                </div>
                                <div className="card-body " style={{ minHeight: '320px' }}>
                                    <div className="horizontal-scroll_wrap">
                                        <div className="horizontal-scroll">
                                            <table className="table table-dashboard-schedule">
                                                <thead>
                                                    <tr>
                                                        {/* <th className="text-center">ID</th> */}
                                                        <th>{t('Name')}</th>
                                                        <th>{t('Work Today')}</th>
                                                        <th>{t('Schedule')}</th>
                                                        <th>{t('status')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {shifts.map((shift) => (
                                                        <tr
                                                            className="table-project-small__row"
                                                            key={shift.assignee_id}
                                                        >
                                                            {/* <td className="text-center">{shift['assignee_id']}</td> */}
                                                            <td>{shift.username}</td>
                                                            <td>{minutesToTimeInput(shift.sum, false)}</td>
                                                            <td>
                                                                {minutesToTimeInput(shift.min_start_time, true)} -{' '}
                                                                {minutesToTimeInput(shift.max_end_time, true)}
                                                            </td>
                                                            <td>{t(`shift_status_${shift.status}`)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>{this.renderChart()}</Col>
                    </Row>
                </Grid>
                {
                    banner_view && (
                        <>
                        <BannerView  closeModal={()=>this.closeModal()} bannersInfo={bannersInfo} banner_view={banner_view} />
                        </>
                    )
                }
            </div>
        );
    }

    render() {
        const { t, dashboardStore, userStore, commonStore } = this.props;
        const { loading, currentData } = dashboardStore;
        //console.log('this value',this.state.banner_view);
        if (loading) {
            return <LoadingSpinner />;
        }
        const userType = userStore.currentUser.user_type;
        if (userType == 'super-admin') {
            return this.renderSuperAdmin();
        }
        if (!commonStore.config.biztype || !currentData.projects) {
            window.location.reload(true);
            return null;
        }
        if (userType == 'pm' || userType == 'admin') {
            return this.renderAdmin();
        }


        return this.renderMember();
    }
}

export default Dashboard;
