import { observable, action, reaction } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import { findIndex } from 'lodash';

import agent from '../agent';

import userStore from './userStore';

let socket = null;
let connectionPollInterval = null;
let currentGotSocket = false;

class ChatStore {
    @observable socket = null;

    @observable allRooms = null;

    @observable currentRoomId = 0;

    @observable currentRooms = {};

    @observable currentEntity = {};

    @observable unreads = {};

    @observable totalUnreads = 0;

    @observable loading = {
        rooms: false,
        room: false,
        messages: false,
    };

    @observable roomMembers = {};

    @observable roomMessages = {};

    @action setupIo(_socket) {
        // console.log('setupIo');
        socket = _socket;
        if (userStore.currentUser) socket.emit('ehlo', { user_id: userStore.currentUser.id, client_id: userStore.currentUser.client_id });

        reaction(
            () => userStore.currentUser,
            currentUser => {
                if (currentUser) {
                    socket.emit('ehlo', { user_id: currentUser.id, client_id: currentUser.client_id });
                }
            }
        );

        socket.removeAllListeners('chat-got-message');
        if (userStore.currentUser) {
            currentGotSocket = socket.on('chat-got-message', data => {
                console.log('chat-got-message', data, this.currentRoomId);
                if (this.currentRoomId === data.room_id) {
                    const sameMessage = findIndex(this.currentRooms[this.currentRoomId].lastMessages, r => {
                        return r.id === data.id;
                    });
                    // console.log('Looking for', data.id, sameMessage);

                    if (sameMessage < 0) {
                        this.currentRooms[this.currentRoomId].lastMessages.unshift({
                            id: data.id,
                            author: data.author_id,
                            author_id: data.author_id,
                            message: data.message,
                            timestamp: new Date(),
                        });
                    }
                } else {
                    // recalculate unreads
                    this.recalcUnreads();
                }
            });

            socket.on('USERS_UPDATED', data => {
                console.log('Removing users caches');
                userStore.clearLookupValues();
            });
        }
    }

    @action recalcUnreads() {
        return agent.Chat.loadRooms()
            .then(
                action(list => {
                    // console.log('Got new unreads');
                    let total = 0;
                    Object.keys(this.unreads).forEach(key => {
                        delete this.unreads[key];
                    });
                    Object.keys(list.unreads).forEach(key => {
                        this.unreads[key] = list.unreads[key];
                        total += parseInt(list.unreads[key]);
                    });
                    //        this.unreads = list.unreads;
                    this.totalUnreads = total;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action sendMessage({ author, room, message }) {
        if (!socket) {
            throw new Error('Not connected right now, please try to reload the page');
        }
        if (!message) return;

        socket.emit('chat-new-message', {
            author,
            room,
            message,
            client_id: this.currentRooms[this.currentRoomId].room.client_id,
        });
    }

    @action setCurrentRoomId(id) {
        this.currentRoomId = id;
        if (!id) this.leaveRoom();
    }

    @action leaveRoom() {
        if (connectionPollInterval) clearInterval(connectionPollInterval);
    }

    @action loadRooms() {
        this.loading.rooms = true;
        return agent.Chat.loadRooms()
            .then(
                action(list => {
                    this.allRooms = list.rooms;
                    this.unreads = list.unreads;
                    let total = 0;
                    Object.keys(this.unreads).forEach(key => {
                        total += parseInt(this.unreads[key]);
                    });
                    this.totalUnreads = total;
                    this.loading.rooms = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading.rooms = false;
                    throw err;
                })
            );
    }

    @action loadRoom(id) {
        this.loading.room = true;
        return agent.Chat.loadRoom(id)
            .then(
                action(response => {
                    this.currentRooms[parseInt(id, 10)] = response;
                    this.currentEntity = response;
                    if (this.unreads) this.unreads[id] = 0;
                    this.leaveRoom();
                    if (socket) {
                        socket.emit('chat-still-active', {
                            room: this.currentRoomId,
                            user_id: userStore.currentUser.id,
                        });
                    }
                    let total = 0;
                    Object.keys(this.unreads).forEach(key => {
                        total += parseInt(this.unreads[key]);
                    });
                    //        this.unreads = list.unreads;
                    this.totalUnreads = total;
                    connectionPollInterval = setInterval(() => {
                        if (socket && userStore.currentUser) {
                            socket.emit('chat-still-active', {
                                room: this.currentRoomId,
                                user_id: userStore.currentUser.id,
                            });
                        }
                    }, 5000);
                    this.loading.room = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading.room = false;
                    throw err;
                })
            );
    }

    @action loadMessages(id) {
        this.loading.messages = true;
        return agent.Chat.loadMessages(id)
            .then(
                action(response => {
                    this.roomMessages[id] = response;
                    this.loading.messages = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading.messages = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        return agent.Chat.saveRoom(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.Chat.remove(id);
        this.deleteSuccess = true;
        return 1;
    }
}

export default new ChatStore();
