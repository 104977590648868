import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import withLocalization from '../../../hoc/withLocalization';
// Components
import StatusBox from '../../../elements/StatusBox';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericList from '../../../components/GenericList/GenericList';

import ClientListFilter from './ClientListFilter/ClientListFilter';
import { serverTimeFormat } from '~/library/core';

@inject('clientStore', 'commonStore')
@withRouter
@withLocalization
@observer
class ClientsList extends Component {
    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    renderTableActionButtons = (id, user_id) => (
        <div className="actions-center">
            <Button
                onClick={() => this.handleTableButtonAction(user_id, 'loginas')}
                bsStyle="info"
                simple
                title={this.props.t('Login as')}
                icon
            >
                <i className="fa fa-user" />
            </Button>
            <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                <i className="fa fa-edit" />
            </Button>
            <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                <i className="fa fa-times" />
            </Button>
        </div>
    );

    render() {
        const { config } = this.props.commonStore;
        const { filters, appliedFilters, loading } = this.props.clientStore;
        if (!config.biztypes) return null;

        return (
            <div className="table-list">
                <div className="table-list__header">
                    {config.biztypes.map(biztype => (
                        <Button round wd icon key={biztype.id} onClick={() => this.props.onAdd(biztype.id)}>
                            Add {biztype.name} <i className="fa fa-plus" />
                        </Button>
                    ))}
                </div>
                <GenericList
                    columns={[
                        {
                            Header: 'ID',
                            id: 'id',
                            accessor: 'id',
                        },
                        {
                            Header: 'Name',
                            id: 'name',
                            accessor: 'name',
                        },
                        {
                            Header: 'Type',
                            id: 'biztype_name',
                            accessor: 'biztype_name',
                            sortable: false,
                        },
                        {
                            Header: 'Phone',
                            id: 'phone',
                            accessor: 'phone',
                        },
                        {
                            Header: 'Email',
                            id: 'email',
                            accessor: 'email',
                        },
                        {
                            Header: 'Renew',
                            id: 'renew_date',
                            accessor: ({ renew_date, renew_manual }) => (
                                <span>
                                    {renew_date && moment(renew_date).format(serverTimeFormat())}
                                    <StatusBox status={renew_manual ? 'manual' : 'auto'} />
                                </span>
                            ),
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: ({ status }) => <StatusBox status={status || ''} />,
                        },
                        {
                            Header: 'Operation',
                            id: 'operation',
                            sortable: false,
                            accessor: ({ id, user_id }) => this.renderTableActionButtons(id, user_id),
                        },
                    ]}
                    filters={appliedFilters}
                    forceReload={this.props.clientStore.deleteSuccess}
                    lastListLoadTime={this.props.clientStore.lastListLoadTime}
                    header={(
                        <ClientListFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.clientStore.setFilter(name, value)}
                            onFilter={() => this.props.clientStore.onFilter()}
                            loading={loading}
                        />
                    )}
                    requestData={params => this.props.clientStore.loadList(params)}
                />
            </div>
        );
    }
}
export default ClientsList;
