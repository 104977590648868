import { observable, action } from 'mobx';

import agent from '../agent';

import userStore from './userStore';
import commonStore from './commonStore';

class AuthStore {
    @observable inProgress = false;

    @observable errors = undefined;

    @observable frontErrors = undefined;

    @observable values = {
        username: '',
        password: '',
        name: '',
        confirmPassword: '',
        email: '',
        userType: 'client',
    };

    @observable additionalData = {};

    @action formChange(name, value) {
        this.values[name] = value;
    }

    @action setUsername(username) {
        this.values.username = username;
    }

    @action setEmail(email) {
        this.values.email = email;
    }

    @action setPassword(password) {
        this.values.password = password;
    }

    @action setData(name, value) {
        this.additionalData[name] = value;
    }

    @action setError(err) {
        this.frontErrors = err;
    }

    @action reset() {
        this.values = {
            username: '',
            password: '',
            name: '',
            confirmPassword: '',
            email: '',
            userType: 'client',
        };
        this.errors = null;
        this.frontErrors = null;
        this.additionalData = {};
    }

    @action login() {
        this.inProgress = true;
        this.errors = undefined;
        return agent.Auth.login(this.values.email, this.values.password)
            .then(({ user }) => commonStore.setToken(user.token))
            .then(() => userStore.pullUser())
            .catch(
                action(err => {
                    console.log("............errrr", err.response.body.errors);
                    // this.errors = err.response && err.response.body && err.response.body.errors;
                    if(err && err.response && err.response.body && err.response.body.errors){
                        this.errors= err.response.body.errors;
                    }
                    throw this.errors;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action loginAs(id) {
        this.inProgress = true;
        this.errors = undefined;
        return agent.Auth.loginAs(id)
            .then(response => {
                if (response.user && response.user.token) {
                    commonStore.setToken(response.user.token);
                    userStore.pullUser();
                } else if (response && response.status === 'requested') {
                    throw new Error('Request to user is sent by email, you will be notified when one accepts');
                }
            })
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action register() {
        this.inProgress = true;
        this.errors = undefined;
        return agent.Auth.register(this.values)
            .catch(
                action(err => {
                    this.errors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                })
            );
    }

    @action logout() {
        commonStore.setToken(undefined);
        userStore.forgetUser();
        return Promise.resolve();
    }

    @action resetPassword(email) {
        return agent.Auth.resetPassword(email).catch(e => {
            return false;
        });
    }
}

export default new AuthStore();
