import React, { Component, Fragment } from 'react';
// import TimePicker from 'rc-time-picker';
import TimeField from 'react-simple-timefield';

import './InputTime.css';
import { timeInputToMoment, momentToTimeInput } from '../../../utils/timeCalc';

function InputTime(props) {
    const {
        value, onChange, className, disabled
    } = props;
    return (
        <Fragment>
            <TimeField
                style={{ width: 70 }}
                value={value} // {String}   required, format '00:00' or '00:00:00'
                onChange={e => {
                    onChange(e.target.value);
                }} // {Function} required
                colon=":" // {String}   default: ":"
                disabled={disabled}
                className={`form-control ${className || ''}`}
            />
            {/* <TimePicker
        className={className || 'form-control time-input'}
        value={timeInputToMoment(value)}
        inputReadOnly={disabled}
        onChange={e => onChange(momentToTimeInput(e))}
        showSecond={false}
        disabled={disabled}
        allowEmpty={false}
      /> */}
        </Fragment>
    );
}

export default InputTime;
