import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import config from '~/library/config';
import agent from '~/library/agent';

import StatusBox from '../../../elements/StatusBox';
import Button from '../../../components/CustomButton/CustomButton.jsx';

import UsersListFilter from './UsersListFilter';
import ModalEditor from './ManagesMembersForm2';

import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';

// Elements

@inject('userStore', 'commonStore')
@withRouter
@withLocalization
@observer
class UsersList extends Component {
    state = {
        nowEditingId: null,
        currenttab: null
    };

    startEdit = id => {
        console.log('ID', id);
        this.setState({ nowEditingId: id });
    };

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    renderTableActionButtons = (id, user_type, status, currentUerType) => (
        <div className="actions-center">
            {currentUerType === 'admin' && status === 'verified' && (
                <Button onClick={() => this.handleTableButtonAction(id, 'active')} bsStyle="warning" simple icon>
                    <i className="fa fa-check" />
                </Button>
            )}
            {
                
                // {user_type !== 'admin' && (
                //     <Button
                //         onClick={() => this.handleTableButtonAction(id, 'loginas')}
                //         bsStyle="info"
                //         simple
                //         title={this.props.t('Login as')}
                //         icon
                //     >
                //         <i className="fa fa-user" />
                //     </Button>
                // )}
            }
             
            {user_type === 'pm' && (
                <Button onClick={() => this.startEdit(id)} bsStyle="info" simple icon>
                    <i className="fa fa-address-book" />
                </Button>
            )}
            <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                <i className="fa fa-edit" />
            </Button>
            <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                <i className="fa fa-times" />
            </Button>
        </div>
    );
    
    loadlistbystatus = (st) => {
        if (this.state.currenttab == st) {
            return;
        }
        else {
            this.setState({ currenttab: st })
            if (st != null) {
                this.props.userStore.setFilter("status", st)
                this.props.userStore.onFilter();
            }
            else {
                this.props.userStore.setFilter("status", '')
                this.props.userStore.onFilter();
            }
            return;
        }

    }

    getUserReport = () => {
        let hydrated = {
            authorization: this.props.commonStore.token
        }
        window.open(`${config.API_ROOT}/users/report${agent.convertToGetParams(hydrated)}`);
    }

    renderHeader = () => {
        const {
            mode, userStore, t, onAdd, commonStore
        } = this.props;
        const { config } = this.props.commonStore;
        const { loading, filters, currentUser } = userStore;        
        return (
            <div>
                <div className="table-list__header" style={{ flexWrap: 'nowrap' }}>
                    {currentUser.user_type !== 'super-admin' && <>
                        {userStore.currentList && userStore.currentList.total && config.client && (
                            <div className="table-list__summary">
                                {/* <div className="highlighted" style={{cursor:"pointer"}} onClick={()=>this.loadlistbystatus(null)}>
                                {t('Total users')}: {userStore.currentList.total}
                            </div> */}
                                <div className="highlightedgreen" style={{ cursor: "pointer" }} onClick={() => this.loadlistbystatus('active')}>
                                    {t('Active members')}: {userStore.currentList.activeuser}
                                </div>
                                <div className="highlightedred" style={{ cursor: "pointer" }} onClick={() => this.loadlistbystatus('inactive')}>
                                    {t('Inactive members')}: {userStore.currentList.inactiveuser}
                                </div>
                                <div>
                                    {t('Allowed users')}: {config.client.max_users_requested}
                                </div>
                            </div>
                        )}
                        <Button fill icon onClick={() => this.getUserReport()}>
                            {t('Download as PDF')} <i className="fa fa-file-download" />
                        </Button>
                    </>
                    }
                    <Button fill wd icon onClick={() => onAdd()}>
                        {t('Add new')} <i className="fa fa-plus" />
                    </Button>
                </div>
                <UsersListFilter
                    filters={filters}
                    setFilter={(name, value) => userStore.setFilter(name, value)}
                    onFilter={() => userStore.onFilter()}
                    loading={loading}
                />
            </div>
        );
    };

    render() {
        const { mode } = this.props;
        const { nowEditingId } = this.state;

        const { userStore } = this.props;
        const userType = userStore.currentUser.user_type;
        if (userType == 'member') {
            return <div>Not Allowed</div>;
        }

        const { loading, filters, appliedFilters } = userStore;

        return (
            <Fragment>
                <Modal size="lg" show={!!nowEditingId} onHide={() => this.setState({ nowEditingId: null })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Assign Members to PM')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor id={nowEditingId} afterSave={() => this.setState({ nowEditingId: null })} />
                    </Modal.Body>
                </Modal>
                <GenericList
                    columns={[
                        {
                            Header: 'Name',
                            id: 'first_name',
                            accessor: ({ id, first_name, last_name }) => (
                                <a
                                    className="list-main-column"
                                    onClick={() => this.handleTableButtonAction(id, 'edit')}
                                >
                                    {`${first_name} ${last_name}`}
                                </a>
                            ),
                        },
                        {
                            Header: 'Phone',
                            id: 'phone',
                            accessor: 'phone',
                        },
                        {
                            Header: 'Email',
                            id: 'email',
                            accessor: 'email',
                        },
                        {
                            Header: 'Role',
                            id: 'user_type',
                            accessor: 'user_type',
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: ({ status }) => <StatusBox status={status || ''} />,
                        },
                        {
                            Header: 'Operation',
                            id: 'operation',
                            sortable: false,
                            accessor: ({ id, user_type, status }) => this.renderTableActionButtons(id, user_type, status, userType),
                        },
                    ]}
                    lastListLoadTime={this.props.userStore.lastListLoadTime}
                    forceReload={this.props.userStore.deleteSuccess}
                    header={this.renderHeader()}
                    filters={appliedFilters}
                    requestData={params => this.props.userStore.loadList(params, mode)}
                />
            </Fragment>
        );
    }
}
export default UsersList;
