import React, { Component } from 'react';
import './Compose.css';
import { Button } from 'react-bootstrap';

import withLocalization from '../../../hoc/withLocalization';

@withLocalization
export default class Compose extends Component {
    state = {
        width: 0,
        text: '',
    };

    constructor(props) {
        super(props);
        this.wrapper = React.createRef();
    }

    componentDidMount() {
        this.setState({ width: this.wrapper.current.parentElement.offsetWidth }); // 360
    }

    send() {
        this.props.sendMessage(this.state.text);
        this.setState({ text: '' });
    }

    handleKeyDown = e => {
        if (e.key === 'Enter') {
            this.send();
        }
    };

    render() {
        const { text, width } = this.state;
        return (
            <div ref={this.wrapper}>
                <div className="compose" style={{ width }}>
                    <input
                        type="text"
                        className="compose-input"
                        placeholder={this.props.t('Type a message, @name')}
                        value={text}
                        onKeyDown={e => this.handleKeyDown(e)}
                        onChange={e => this.setState({ text: e.target.value })}
                    />
                    <Button onClick={() => this.send()}>{this.props.t('Send')}</Button>
                    {/* {
            this.props.rightItems
          } */}
                </div>
            </div>
        );
    }
}
