import React, { Component } from 'react';

export function OvertimeDisplay({ data }) {
    const overtimes = [];
    if (!data) {
        return null;
    }
    Object.keys(data).forEach(key => {
        overtimes.push(data[key]);
    });
    return (
        <div style={{ lineHeight: '10px', fontSize: '9px' }}>
            {overtimes.map(row => (
                <div>
                    {row.name}: {row.value}
                </div>
            ))}
        </div>
    );
}
