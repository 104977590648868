import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';

import ProjectsList from './ProjectsList';
import ProjectsForm from './ProjectsForm';
import ProjectView from './ProjectView';

import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('authStore', 'projectStore')
@withRouter
@withRemoveDialog
@observer
class ProjectsPage extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/projects/add');
    }

    onTableAction(id, type) {
        const { history } = this.props;
        if (type === 'view') {
            history.push(`/admin/projects/view_${id}`);
            return;
        }
        if (type === 'remove') {
            this.props.projectStore.remove(id).then(res => {
                console.log(res);
                const str = `Are you sure to delete the project?
It has some data filled. Tasks: ${res.tasks}, timelogs:${res.timelogs}. `;
                confirmAlert({
                    title: this.props.t('Confirm to delete'),
                    message: str,
                    buttons: [
                        {
                            label: this.props.t('Yes'),
                            onClick: () => {
                                return this.props.projectStore.remove(id, true).then(res2 => {
                                    this.props.projectStore.resetLastListLoadTime();
                                    return res2;
                                });
                            },
                        },
                        {
                            label: this.props.t('No'),
                            onClick: () => {},
                        },
                    ],
                });
            });
        }
        /*
    *     if (type === 'remove') return this.props.handleDelete(id, (id) => {
      return this.props.projectStore.remove(id).then((res)=>{
        this.props.projectStore.resetLastListLoadTime();
        return res;
      })
    });

*/
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.projectStore.remove(id).then(res => {
                    this.props.projectStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        history.push(`/admin/projects/${id}`);
    }

    editingId = () => {
        let id = null;
        let viewing = false;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        if (id && id.indexOf('view_') === 0) {
            viewing = true;
            id = id.replace('view_', '');
        }
        return {
            editing: !!id && !viewing,
            viewing,
            add: id === 'add',
            id,
        };
    };

    render() {
        const mode = this.editingId();
        return (
            <div className="main-content">
                {!mode.editing && !mode.viewing && (
                    <ProjectsList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && <ProjectsForm id={mode.id} add={mode.add} />}
                {mode.viewing && <ProjectView id={mode.id} />}
            </div>
        );
    }
}
export default ProjectsPage;
