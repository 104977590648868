import { observable, action, reaction } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import { findIndex } from 'lodash';
import {
    getMinutesFromDate,
    getMinutesFromTimeInput,
    minutesToTimeInput,
    locationToStringGoogle,
    stringToLocationGoogle,
    toLocationGeolib,
} from '../../utils/timeCalc';
import agent from '../agent';

import commonStore from './commonStore';

let socket = null;

class UserStore {
    @observable currentUser;

    @observable loadingUser;

    @observable updatingUser;

    @observable updatingUserErrors;

    @observable currentMembersLookup = {};

    @observable lookupNeedsUpdate = false;

    @observable loadingLookup = false;

    @observable loadingLookupPromise = false;

    @observable usersById = {};

    @observable isNotificationDockOpen = false;

    @observable newNotificationsCount = 0;

    @observable dockNotifications = [];

    @observable addPossibility = {
        allowed: true,
    };

    @action hideNotificationDock() {
        this.isNotificationDockOpen = false;
    }

    @action showNotificationDock() {
        this.isNotificationDockOpen = true;
    }

    @action setupIo(_socket) {
        socket = _socket;
        const that = this;

        if (this.currentUser) {
            socket.removeAllListeners('notification-new');
            socket.on('notification-new', async (data) => {
                await this.loadNotifications();
            });
        }
    }

    @action async loadNotifications() {
        const notifications = await agent.Users.getNotifications();
        this.dockNotifications = notifications || [];
        if (this.dockNotifications) this.newNotificationsCount = this.dockNotifications.length;
        // console.log('new length', this.newNotificationsCount)
    }

    @action async clearNotifications() {
        this.dockNotifications = [];
        this.newNotificationsCount = 0;
        this.isNotificationDockOpen = false;
        let currentUser = await agent.Users.clearNotifications();
        if (currentUser.user) currentUser = currentUser.user;
        this.currentUser = currentUser;
    }

    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action pullUser() {
        this.loadingUser = true;
        return agent.Auth.current()
            .then(
                action(({ user }) => {
                    this.currentUser = user;
                })
            )
            .finally(
                action(() => {
                    this.loadingUser = false;
                })
            );
    }

    @action pullUserById(id) {
        this.loadingUser = true;
        return agent.Auth.loadById(id).then(
            action(({ user }) => {
                this.usersById[id] = user;
                return user;
            })
        );
    }

    @action updateUser(newUser) {
        this.updatingUser = true;
        this.updatingUserErrors = null;
        return agent.Auth.save(newUser)
            .then(
                action(({ user }) => {
                    this.currentUser = user;
                })
            )
            .catch(
                action((err) => {
                    this.updatingUser = false;
                    this.updatingUserErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action forgetUser() {
        this.currentUser = undefined;
        commonStore.setToken(undefined);
        // window.localStorage.removeItem('jwt');
        window.sessionStorage.removeItem('jwt');
    }

    /* Common CRUD */

    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @action loadList(params, mode) {
        const _params = Object.assign({}, params);
        _params.mode = mode;
        //console.log("params",params)
        return agent.Users.list(_params)
            .then(
                action((list) => {
                 
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action returnDefaultNew(mode, client_id, additionalParams, user, vacation_days) {
        if (mode == 'superadmins') {
            this.currentEntity = {
                user_type: 'super-admin',
            };
        } else {
            this.currentEntity = {
                user_type: 'admin',
                client_id,
            };
            if (mode == 'members') {
                this.currentEntity.user_type = 'member';
            }
            if (mode == 'managers') {
                this.currentEntity.user_type = 'pm';
            }
        }
        if (additionalParams) {
            Object.assign(this.currentEntity, additionalParams);
        }
        if (user && user.user_type === 'pm' && user.ManagesProjects) {
            this.currentEntity.MemberInProjects = user.ManagesProjects.join(',');
        }
        this.currentEntity.employee_percent = '100';
        this.currentEntity.vacation_days = vacation_days;
        this.loading = false;
        this.currentEntity = { user: this.currentEntity };
    }

    @action load(id) {
        this.loading = true;
        return agent.Users.load(id)
            .then(
                action((response) => {
                    if (!response.user.employee_percent) response.user.employee_percent = '100';
                    this.currentEntity = response;
                    this.currentEntity.user.generic_pin = +this.currentEntity.user.generic_pin;
                    this.currentEntity.user.timelog_start_from = this.currentEntity.user.timelog_start_from
                        ? moment(this.currentEntity.user.timelog_start_from).format('YYYY-MM-DD')
                        : null;       
                    this.currentEntity.user.start_time = minutesToTimeInput(response.user.start_time);
                    this.currentEntity.user.end_time = minutesToTimeInput(response.user.end_time);
                    // this.currentEntity.user.disregard_billable_hours = this.currentEntity.user.disregard_billable_hours || false;
                    return agent.Clients.load(this.currentEntity.user.client_id).then((response2) => {
                        this.currentEntity.user.clientEnabledBillableHours =
                            (response2 &&
                                response2.client &&
                                response2.client.data &&
                                response2.client.data.basicRules &&
                                response2.client.data.basicRules.trackBillableHours) ||
                            false;
                        this.loading = false;
                        return response;
                    });
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.currentMembersLookup = {};
        this.updating = true;
        if (values.data.kids) {
            let kidsOK = true;
            values.data.kids.forEach((kid) => {
                if (!kid.date || moment(new Date()).diff(moment(new Date(kid.date)), 'years') >= 18) {
                    this.updating = false;
                    kidsOK = false;
                    this.updatingErrors = { message: 'Kid age is above 18' };
                }
            });
            if (!kidsOK) return Promise.resolve(this.updatingErrors);
        }
        return agent.Users.save(values, isAdd)
            .then(
                action((user) => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action fileUpload(params) {
        return agent.Users.uploadImage(params).then(
            action((response) => {
                return response.id;
            })
        );
    }

    @action async remove(id) {
        await agent.Users.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    @action async loadLookup(mode, name) {
        const key = `${mode || 'all'}${name || ''}`;
        if (!name && this.currentMembersLookup[key]) {
            return this.currentMembersLookup[key];
        }
        if (this.loadingLookup && this.loadingLookupPromise) {
            return this.loadingLookupPromise;
        }
        this.loadingLookup = true;
        this.loadingLookupPromise = new Promise((resolve) => {
            return agent.Users.lookupByName(mode, name).then((list) => {
                this.currentMembersLookup[key] = list;
                this.loadingLookup = false;
                resolve(list);
                this.lookupNeedsUpdate = false;
                return list;
            });
        });
        return this.loadingLookupPromise;
    }

    @action async loadByProjectId(project_id) {

        return agent.Users.byProjectId(project_id)
        .then(
            action(response => {
                return response;
            })
        )
        .catch(
            action(err => {
                throw err;
            })
        );
    }

    @action async activeUser(activeUser) {
        this.updatingUser = true;
        this.updatingUserErrors = null;
        await agent.Auth.activeUser(activeUser);
        return 1;
    }

    @action clearLookupValues() {
        this.currentMembersLookup = {};
        this.loadLookup('managers', '');
        // this.lookupNeedsUpdate = true;
    }

    @action async sendReport(params) {
        const res = await agent.Users.sendReport(params);
        return res;
    }

    @action async getReportInfo(params) {
        const res = await agent.Users.getReportInfo(params);
        return res;
    }

    @action async validateNewUser(params) {
        const res = await agent.Users.validateNewUser(params);
        return res;
    }

    @action async validateAddPossible() {
        //console.log("called")
        const res = await agent.Users.validateAddPossible();
        //console.log("res..",res)
        this.addPossibility = res;
        return res;
    }

    @action async increaseUserLimit() {
        const res = await agent.Users.increaseUserLimit();
        this.addPossibility = res;
        return res;
    }
}

export default new UserStore();
