import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import _ from 'lodash';

import withLocalization from '~/hoc/withLocalization';

@inject('absenceStore', 'userStore')
@withRouter
@withLocalization
@observer
export default class AbsencesTotals extends Component {
    state = {
        loadedUser: null,
    };

    loadData() {
        if (!this.props.user) {
            this.setState({ loadedUser: this.props.userStore.currentUser });
        } else {
            this.props.userStore.pullUserById(this.props.user).then(user => {
                this.setState({ loadedUser: user });
            });
        }
    }

    componentDidMount() {
        this.loadData();
        console.log("..",this.props.absenceStore.currentEntity)
        
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.user, this.props.user)) {
            this.loadData();
        }
    }

    render() {
        const { t ,absenceStore} = this.props;
        const { loadedUser } = this.state;
        
        if (!loadedUser) {
            return <div>...</div>;
        }
        const { Totals } = loadedUser;
        return (
            <div className="vacation-total">
                <div className="vacation-box">
                    <div className="vacation-box__item">
                        <span className="vacation-box__value">{moment(new Date()).year()}</span>
                        <span className="vacation-box__label">{t('Year')}</span>
                    </div>
                    <div className="vacation-box__item">
                        <span className="vacation-box__value">{loadedUser.employee_percent}%</span>
                        <span className="vacation-box__label">{t('Employment')}</span>
                    </div>
                </div>
                <div className="vacation-panel">
                    <div className="vacation-panel__item">
                        <span className="vacation-panel__value">
                            {Totals.yearly.selfClearances} {t('of')} 4
                        </span>
                        <span className="vacation-panel__label">{t('Self clearances')}</span>
                    </div>
                    {parseInt(Totals.yearly.childDays) > 0 && (
                        <div className="vacation-panel__item">
                            <span className="vacation-panel__value">
                                {Totals.yearly.childDaysUsed} {t('of')} {Totals.yearly.childDays}{' '}
                            </span>
                            <span className="vacation-panel__label">{t('Child sickness')}</span>
                        </div>
                    )}
                  
                      {/* <div className={`acation-panel__item status-block status-${absenceStore && absenceStore.currentEntity && absenceStore.currentEntity.absence ? absenceStore.currentEntity.absence.status : null}`} style={{ margin:'auto' ,marginRight: '20px' , height:"fit-content" }}>
                      {t(absenceStore && absenceStore.currentEntity && absenceStore.currentEntity.absence ? absenceStore.currentEntity.absence.status : null)}
                </div> */}
                
                </div>
              
            </div>
        );
    }
}
