import { observable, action } from 'mobx';
import moment from 'moment/min/moment-with-locales';

// import getBase64 from '~/utils/getBase64';
import queryString from 'query-string';

import agent from '../agent';

import userStore from './userStore';

import commonStore from '../stores/commonStore';

class AbsenceStore {
    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action loadList(params) {

        return agent.Absences.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    console.log("list",list)
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action async recalc(data) {
        console.log("changed",this.props);
        if (data && data.user_id) {
            this.currentEntity.absence.user_id = data.user_id;
        }
        let pay=0;
        if(data && data.absence_payment_per_day && data.absence_payment_per_day > 0){
            pay=data.absence_payment_per_day;
        }
        const from = data ? data.from : this.currentEntity.absence.from;
        const to = data ? data.to : this.currentEntity.absence.to;
        let days = moment(new Date(to)).diff(moment(new Date(from)), 'days') + 1;
        // if (Number.isNaN(days)) days = 1;
        this.currentEntity.absence.total_days = days;
        // if(days >16){
        //     //  commonStore.addNotification(("you can not apply for more then 16 days!"), null, 'error');
        //     // this.currentEntity.absence.to=from;
        //     // this.currentEntity.absence.total_days = 1;
        //     this.currentEntity.absence.absence_total_payment=pay * days;
        //     return {to: this.currentEntity.absence.to ,
        //         total_days: this.currentEntity.absence.total_days ,
        //         absence_total_payment:this.currentEntity.absence.absence_total_payment,
        //     };
        // }
        // else{
        //     this.currentEntity.absence.absence_total_payment=pay * days;
        //     return { total_days: this.currentEntity.absence.total_days ,
        //         absence_total_payment:this.currentEntity.absence.absence_total_payment,
        //     };
        // }
        this.currentEntity.absence.absence_total_payment=pay * (days > 16 ? 16 : days);
        return { total_days: this.currentEntity.absence.total_days ,
            absence_total_payment:this.currentEntity.absence.absence_total_payment,
        };
    }

    @action returnDefaultNew(params) {
        this.currentEntity = {
            absence: {
                user_id: userStore.currentUser.id,
                from: moment(new Date()).format('YYYY-MM-DD'),
                to: moment(new Date()).format('YYYY-MM-DD'),
                total_days: 1,
                absence_type: 'self',
                absence_payment_per_day:0,
                status: 'active',
                data: {},
            },
        };
        if (params && params.location) {
            const search = queryString.parse(params.location.search);
            if (search.from) {
                this.currentEntity.absence.from = moment(new Date(search.from)).format('YYYY-MM-DD');
            }
            if (search.to) {
                this.currentEntity.absence.to = moment(new Date(search.to)).format('YYYY-MM-DD');
            }
        }
        this.recalc();
        this.loading = false;
    }

    @action load(id) {
        this.loading = true;
        return agent.Absences.load(id)
            .then(
                action(response => {
                    if (!response.absence.data) response.absence.data = {};
                    if (response.absence.data.child_index) {
                        response.absence.child = response.absence.data.child_index;
                    }
                    this.currentEntity = response;
                    this.currentEntity.absence.from = moment(this.currentEntity.absence.from).format('YYYY-MM-DD');
                    this.currentEntity.absence.to = moment(this.currentEntity.absence.to).format('YYYY-MM-DD');
                    this.loading = false;

                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        const cUser = userStore.currentUser;
        if (!values.data) values.data = {};
        if (values.child) {
            values.data.child = cUser.data.kids[parseInt(values.child, 10)];
            values.data.child_index = values.child;
        }

        return agent.Absences.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    if (user.absence.user_id === cUser.id) {
                        userStore.pullUser();
                    }
                    this.updating = false;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.Absences.remove(id);
        this.deleteSuccess = true;
        return 1;
    }
}

export default new AbsenceStore();
