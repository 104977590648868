import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import './Message.css';
import styled, { css } from 'styled-components';

const AvatarWrapper = styled.div(props => {
    return css`
        opacity: 0.8;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: 40px;
        background-position: center;
        background-image: url(${props.icon});
        transition: all 0.3s ease-in-out;
        cursor: default;
        &:hover {
            opacity: 1;
        }
    `;
});

const Avatar = props => (
    <div className="avatar-wrapper">
        <AvatarWrapper {...props} />
        <span className="user-title"> {props.title}</span>
    </div>
);

export default class Message extends Component {
    render() {
        const {
            resolvedUser, data, isMine, startsSequence, endsSequence, showTimestamp
        } = this.props;

        const friendlyTimestamp = moment(data.timestamp).format('LLLL');
        return (
            <div
                className={[
                    'message',
                    `${isMine ? 'mine' : ''}`,
                    `${startsSequence ? 'start' : ''}`,
                    `${endsSequence ? 'end' : ''}`,
                ].join(' ')}
            >
                {showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

                <div className="bubble-container">
                    <div className="avatar-container">
                        {!isMine && startsSequence && (
                            <Avatar title={`${resolvedUser.fullname}`} icon={resolvedUser.image} />
                        )}
                    </div>
                    <div className="bubble" title={friendlyTimestamp}>
                        {data.message}
                    </div>
                </div>
            </div>
        );
    }
}
