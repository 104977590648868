import { observable, action } from 'mobx';
import moment from 'moment/min/moment-with-locales';

// import getBase64 from '~/utils/getBase64';
import queryString from 'query-string';
import { dataURItoBlob } from 'react-jsonschema-form/lib/utils';

import agent from '../agent';

import userStore from './userStore';

class VacationStore {
    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action loadList(params) {
        return agent.Vacations.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action async recalc(data) {
        if (data && data.user_id) {
            this.currentEntity.vacation.user_id = data.user_id;
        }
     
        let from = data ? data.from : this.currentEntity.vacation.from;
        let to = data ? data.to : this.currentEntity.vacation.to;
        // let days = moment(new Date(to)).diff(moment(new Date(from)), 'days') + 1;
        // if (Number.isNaN(days)) days = 1;
        this.currentEntity.vacation.from=from;
        this.currentEntity.vacation.to=to;
        if ( data && (moment(from,'YYYY-MM-DD').valueOf() >  moment(to,'YYYY-MM-DD').valueOf())) {
            this.currentEntity.vacation.to =from;
            to=from;
        }
               
        let days=this.calculateBusinessDays(from,to);

        this.currentEntity.vacation.total_days = days;

        return {to : this.currentEntity.vacation.to,
             total_days: this.currentEntity.vacation.total_days };
    }
 
      calculateBusinessDays(from,to) {
let d1=moment(from);
let d2=moment(to);
        const days = d2.diff(d1, "days") + 1;
        let newDay = d1.toDate(),
        workingDays = 0,
        sundays = 0,
        saturdays = 0;
        for (let i = 0; i < days; i++) {
            const day = newDay.getDay();
            newDay = d1.add(1, "days").toDate();
            const isWeekend = ((day % 6) === 0);
            if (!isWeekend) {
                workingDays++;
            } 
            else {
                if (day === 6) saturdays++;
                if (day === 0) sundays++;
            }
        }
            console.log("Total Days:", days, "workingDays", workingDays, "saturdays", saturdays, "sundays", sundays);
        return workingDays;
    }
     
    @action returnDefaultNew(params) {
        this.currentEntity = {
            vacation: {
                user_id: userStore.currentUser.id,
                from: moment(new Date()).format('YYYY-MM-DD'),
                to: moment(new Date()).format('YYYY-MM-DD'),
                total_days: 1,
                vacation_type: 'normal',
                status: 'active',
                data: {},
            },
        };
        if (params && params.location) {
            const search = queryString.parse(params.location.search);
            if (search.from) {
                this.currentEntity.vacation.from = moment(new Date(search.from)).format('YYYY-MM-DD');
            }
            if (search.to) {
                this.currentEntity.vacation.to = moment(new Date(search.to)).format('YYYY-MM-DD');
            }
        }
        this.recalc();
        this.loading = false;
    }

    @action load(id) {
        this.loading = true;
        return agent.Vacations.load(id)
            .then(
                action(response => {
                    if (!response.vacation.data) response.vacation.data = {};
                    this.currentEntity = response;
                    this.currentEntity.vacation.from = moment(this.currentEntity.vacation.from).format('YYYY-MM-DD');
                    this.currentEntity.vacation.to = moment(this.currentEntity.vacation.to).format('YYYY-MM-DD');
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        const cUser = userStore.currentUser;
        return agent.Vacations.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    if (user.vacation.user_id === cUser.id) {
                        userStore.pullUser();
                    }
                    this.updating = false;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.Vacations.remove(id);
        this.deleteSuccess = true;
        return 1;
    }
}

export default new VacationStore();
