export const schema = {
    title: 'Project',
    type: 'object',
    required: ['name'],
    properties: {
        id: { type: 'integer' },
        image: { type: ['string', 'null'] },
        project_number: { type: ['integer', 'null'], title: 'Project Number' },
        name: { type: ['string'], title: 'Name', isRequired: true },
        status: { type: ['string', 'null'], title: 'Status', default: 'active' },
        assignee_id: { type: ['integer', 'null'], title: 'Manager' },
        parent_id: { type: ['integer', 'null'], title: 'Parent project' },
        project_type: { type: ['integer', 'null'], title: 'Project type' },
        contact_person: { type: ['string', 'null'], title: 'Contact name' },
        organization_number:{ type: ['string', 'null'], title: 'Organization Number' },
        post_place: { type: ['string', 'null'], title: 'Post place' },
        post_number: { type: ['string', 'null'], title: 'Post number', format: 'postnumber' },
        address: { type: ['string', 'null'], title: 'Address' },
        g_nr: { type: ['string', 'null'], title: 'G.Nr' },
        b_nr: { type: ['string', 'null'], title: 'B.Nr' },
        email: { type: ['string', 'null'], title: 'Email', format: 'email' },
        phone: { type: ['string', 'null'], title: 'Phone', format: 'phone-no' },
        description: { type: ['string', 'null'], title: 'Description' },
        gps_data: { type: ['string', 'null'], title: 'GPS location', format: 'gps' },
        est_hours: { type: ['integer', 'null'], title: 'Estimated hours' },
        est_work: {
            title: 'Allowed work',
            type: 'string',
            anyOf: [
                {
                    title: 'Daily',
                    enum: ['daily'],
                },
                {
                    title: 'Weekly',
                    enum: ['weekly'],
                },
                {
                    title: 'Monthly',
                    enum: ['monthly'],
                },
            ],
            default: 'weekly',
        },
        members: {
            type: 'array',
            title: 'Members',
            default: [],
            items: {
                type: 'object',
                required: ['user_id', 'hours', 'timeframe'],
                properties: {
                    user_id: {
                        title: 'Member',
                        type: 'integer',
                    },
                    additional_pay:{
                        title: 'Additional payment',
                        type: 'number',
                        default: 0,
                    },
                    // vacation_days: {
                    //     title: 'Vacation days',
                    //     type: 'number',
                    //     default: 25,
                    // },
                    hours: {
                        title: 'Estimated hours',
                        type: 'number',
                        default: 40,
                    },
                    timeframe: {
                        title: 'Allowed work',
                        type: 'string',
                        anyOf: [
                            {
                                title: 'Daily',
                                enum: ['daily'],
                            },
                            {
                                title: 'Weekly',
                                enum: ['weekly'],
                            },
                            {
                                title: 'Monthly',
                                enum: ['monthly'],
                            },
                        ],
                        default: 'weekly',
                    },
                },
            },
        },
        data: {
            type: 'object',
            properties: {
                // require_hms: { type: 'boolean', title: 'Require HMS', default: true },
                // security_analyze: { type: 'boolean', title: 'Security analyze', default: false },
                start_date: { type: 'string', format: 'date', title: 'Start date' },
                finish_date: { type: 'string', format: 'date', title: 'Finish date' },
            },
        },
        attachments: { type: ['string', 'null'], title: 'Attachments' },
    },
};

export const getSchema = () => schema;

export default getSchema;
