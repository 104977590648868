import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl } from 'react-bootstrap';
import { find } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';
import statuses from '../../../utils/normalStatuses';
import DatePicker from '../../../components/DatePicker/DatePicker';

@inject('commonStore')
@withLocalization
@observer
class VacationFilter extends Component {
    setFilterDate(name, name1, value) {
        this.props.setFilter(name1, value);
        this.props.setFilter(name, moment(new Date(value)).format('YYYY-MM-DD'));
    }

    render() {
        const {
            filters, setFilter, onFilter, loading, t, showStatus, onChangeStatus
        } = this.props;
        const { config } = this.props.commonStore;
        const { status, name, fromDate, toDate, fromDate1, toDate1 } = filters;
        const projectStatuses = statuses;
        const options = projectStatuses.map(status => {
            return { value: `${status.code}`, label: `${t(status.name)}` };
        });
        options.unshift({ value: '', label: t('All') });
        const currentSelect = find(options, o => {
            return o.value === status;
        });
        return (
            <div className="list-filters">
                <div className="list-filters__item">
                    <Select
                        options={options}
                        round
                        value={currentSelect}
                        onChange={id => setFilter('status', id.value)}
                        style={{ width: 150 }}
                    />
                </div>
                <div className="list-filters__item">
                    <span className="smaller-wrapper">
                        <DatePicker
                            selected={fromDate1}
                            className="smaller"
                            onChange={(date) => this.setFilterDate('fromDate', 'fromDate1', date)}
                            dateFormat="MMMM dd"
                            placeholder={t('From')}
                            style={{ width: '150px' }}
                        />
                    </span>
                </div>
                <div className="list-filters__item">
                    <DatePicker
                        selected={toDate1}
                        className="smaller"
                        onChange={(date) => this.setFilterDate('toDate', 'toDate1', date)}
                        dateFormat="MMMM dd"
                        placeholder={t('To')}
                        style={{ width: '150px' }}
                    />
                </div>
                <div className="list-filters__item list-filters__item_grow">
                    <FormControl
                        placeholder={t('Search')}
                        type="text"
                        onChange={(e) => setFilter('name', e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onFilter();
                            }
                        }}
                        value={name}
                    />
                </div>
                {/* <FormControl placeholder={t('Search')} type="text" onChange={(e) => setFilter('name', e.target.value)} value={name}/> */}
                <div className="list-filters__item list-filters__item_to-right">
                    <Button fill wd onClick={() => onFilter()} disabled={loading}>
                        {t('Filter')}
                    </Button>
                    {showStatus && (
                        <Button wd round onClick={() => onChangeStatus()} disabled={loading}>
                            {t('Change status')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default VacationFilter;
