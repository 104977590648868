import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import config from '~/library/config';
import getBase64 from '~/utils/getBase64';

@inject('userStore')
@observer
class ImagesUploaderInternal extends Component {
    getUrl = img => {
        return `${config.UPLOADS_API_ENDPOINT}/${img}`;
    };

    onUpload = (e, type) => {
        const { onChange, options, userStore } = this.props;
        const file = e.target.files[0];
        const { name } = file;

        getBase64(file)
            .then(imgData => {
                userStore
                    .fileUpload({
                        to_model: options.imageContext.model,
                        to_id: options.imageContext.id,
                        user_id: userStore.currentUser.id,
                        filename: name,
                        filetype: options.imageContext.fileType,
                        content: imgData,
                    })
                    .then(newId => {
                        onChange(`${newId}`);
                    });
                this.fileInput.value = '';
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const { value } = this.props;
        const showAddButton = !value;
        const image = value;

        return (
            <div className="uploads">
                {image && (
                    <div className="uploads-item uploads-item_single" onClick={() => this.fileInput.click()}>
                        <img
                            src={this.getUrl(image)}
                            style={{ width: '100%', maxWidth: '150px', maxHeight: '150px' }}
                            alt="Project"
                        />
                    </div>
                )}
                {showAddButton && (
                    <button className="uploads__add-button" type="button" onClick={() => this.fileInput.click()}>
                        <i className="pe-7s-cloud-upload" />
                    </button>
                )}
                <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    ref={fileInput => {
                        this.fileInput = fileInput;
                    }}
                    onChange={this.onUpload}
                    multiple={false}
                />
            </div>
        );
    }
}

@observer
class ImageUploadWidget extends Component {
    render() {
        return <ImagesUploaderInternal {...this.props} />;
    }
}

export default ImageUploadWidget;
