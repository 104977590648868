const STATUS_TEXTS = {
    active: 'Active',
    inactive: 'Inactive',
    manual: 'Manual',
    auto: 'Auto',
    verified: 'Verified',
    notverified: 'Not Verified'

};
export default {
    STATUS_TEXTS,
};
