import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';

import DocumentFilter from './DocumentFilter';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import '../../../../styles/css/styles.css';

//this component does the listing for documents//

@inject('authStore', 'userStore', 'commonStore', 'documentStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class DocumentTable extends Component {

    state = {

    };


    componentDidMount() {

    }

    handleTableActionButtonAction = (id, type) => {
        //handles action button clicks//
        if (type === 'edit') {
            //edit document            
            this.editDocument(id)
        }
        else if (type === 'delete') {
            //delete document
            this.removeDocument(id);
        }
        else if (type === 'copy') {
            //delete document
            this.copyDocument(id);
        }
    }

    removeDocument(id) {
        //calls action from documentStore to delete document with id//
        const { history, commonStore, t } = this.props;

        return this.props.handleDelete(id, id => {
            return this.props.documentStore.deleteDocumentById(id).then(res => {
                // if (res) {
                    commonStore.addNotification(t('Document Removed'), null, 'error');
                    this.props.documentStore.resetLastListLoadTime();
                    history.push('/admin/documents');
                // }
                return res;
            })
        });
    }

    copyDocument(id) {
        //calls action from documentStore to delete document with id//
        const { commonStore, t } = this.props;
        let data = {
            id: id
        }
        this.props.documentStore.copyDocument(data).then((res) => {
            if (res) {
                commonStore.addNotification(t('Document Copied'), null, 'success');
                this.props.documentStore.resetLastListLoadTime();
            }
            return res;
        })
    }

    editDocument(id) {
        //calls parentHandler to redirect to addEditComponent with given Id//
        const { history } = this.props;
        history.push(`/admin/documents/${id}`);
    }


    renderTableActionButtons = (id, isadmin) => {
        //renders table edit delete buttons in generic list//

        const { user_type } = this.props.userStore.currentUser;
        return (
            <div className="actions-center">
                {(user_type != "member" && user_type != "pm") ?
                    <div>
                        <Button onClick={() => this.handleTableActionButtonAction(id, 'copy')} bsStyle="warning" simple icon>
                            <i className="fa fa-copy" />
                        </Button>
                        {((user_type == "super-admin") || (user_type != "super-admin" && !isadmin)) &&
                            <>
                                <Button onClick={() => this.handleTableActionButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                                    <i className="fa fa-edit" />
                                </Button>
                                <Button onClick={() => this.handleTableActionButtonAction(id, 'delete')} bsStyle="danger" simple icon>
                                    <i className="fa fa-times" />
                                </Button>
                            </>
                        }
                    </div> : null}
            </div>
        )
    }

    render() {
        //renders document list using generic list along with documentFilter//

        const { filters, appliedFilters } = this.props.documentStore;
        return (
            <div>
                <div className="table-list">
                    <GenericList
                        columns={[
                            {
                                Header: 'Document Name',
                                id: 'document_name',
                                sortable: false,
                                accessor: ({ id, document_name }) => (
                                    <a className='list-main-column'
                                        onClick={() => this.handleTableActionButtonAction(id, 'edit')}
                                    >
                                        {`${document_name}`}
                                    </a>
                                )
                            },
                            {
                                Header: 'Created By',
                                id: 'created_by',
                                sortable: false,
                                accessor: 'created_by'
                            },
                            {
                                Header: 'Updated On',
                                id: 'updated_at',
                                sortable: false,
                                accessor: ({ updated_at }) => (
                                    <span>{updated_at && moment(updated_at).format(serverTimeFormat())}</span>
                                )
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: (full) => this.renderTableActionButtons(full.id, full.isadmin)
                            },
                        ]}

                        filters={appliedFilters}
                        lastListLoadTime={this.props.documentStore.lastListLoadTime}
                        header={
                            <DocumentFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.documentStore.setFilter(name, value)}
                                onFilter={() => this.props.documentStore.onFilter()}
                            />
                        }
                        requestData={(params) => this.props.documentStore.getDocuments(params)}
                    />
                </div>
            </div>
        )
    }
}

export default DocumentTable;