import React, { Fragment, Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import MetaTags from 'react-meta-tags';
import socketIOClient from 'socket.io-client';

// Layouts

import AdminLayout from './layouts/Admin.jsx';

// Containers
import Login from './containers/Auth/Login';
import Register from './containers/Auth/Register';
import VisitorClientRegister from './containers/Admin/Visitors/VisitorClientRegister';
import VisitorAdd from './containers/Admin/Visitors/VisitorAdd';
import Settings from './containers/Auth/Settings';
import ForgotPassword from './containers/Auth/ForgotPassword';
import LandingPage from './containers/Landing/LangingPage';
import { GDPRPage, PrivacyPage, TermsPage } from './containers/Landing/ExtraPages';

// Components
import PrivateRoute from './components/PrivateRoute';
import NotificationsDock from './containers/Admin/NotificationsDock';

import withLocalization from '~/hoc/withLocalization';
import config from '~/library/config';
import DocumentReport from './containers/Admin/Document/DocumentTemplate/DocumentReport'
import MultipleDocumentsPdfPreview from './containers/Admin/Document/DocumentTemplate/MultipleDocumentsPdfPreview.js';

// Hoc

@inject('userStore', 'commonStore', 'chatStore')
@withLocalization
@withRouter
@observer
class App extends Component {
    constructor(props) {
        super(props);
        const { history } = this.props;

        history.listen((location, action) => {
            try {
                if (document.documentElement.classList.value === 'nav-open') document.documentElement.classList.toggle('nav-open');
            } catch (e) {
                console.log('no open element');
            }
            const { userStore, commonStore, chatStore } = this.props;
            commonStore.log({
                username: userStore.currentUser ? userStore.currentUser.username : '',
                url: location.pathname,
                action: 'page',
                details: {},
            });
            if (userStore.currentUser) {
                chatStore.leaveRoom(); // check for route change - if leave room
            }
            if (userStore.currentUser && userStore.currentUser.is_now_locked) {
                if (location.pathname.indexOf('/locked') < 0) {
                    // console.log('ROUTECHANGE', location);
                    history.push('/admin/locked/');
                }
            }
            // console.log("on route change", location, action, userStore.currentUser);
        });
    }

    componentWillMount() {
        const { commonStore, chatStore } = this.props;
        this.setSocket();
        if (!commonStore.token) {
            commonStore.setAppLoaded();
        }
    }

    initRevisionCheck() {
        const store = this.props.commonStore;

        const interval = setInterval(async () => {
            const rev = await store.getGitRev();

            if (!store.currentGitRev) {
                console.log('setting', rev);
                store.setGitRev(rev);
            } else if (rev != store.currentGitRev) {
                console.log('Need to reload!');
                alert('New version is available, please reload the page');
                clearInterval(interval);
            }
        }, 5 * 60 * 1000);
    }

    componentDidMount() {
        const {
            commonStore, userStore, history, location, t, chatStore
        } = this.props;
        if (commonStore.token) {
            userStore
                .pullUser()
                .then(() => {
                    this.setSocket();
                })
                .finally(() => {
                    commonStore.setAppLoaded();
                    userStore.loadNotifications();
                    chatStore.loadRooms();
                    if (userStore.currentUser && userStore.currentUser.is_now_locked) {
                        if (location.pathname.indexOf('/locked') < 0) {
                            // console.log('ROUTECHANGE', location);
                            history.push('/admin/locked/');
                        }
                    }
                });
            const tz = new Date().getTimezoneOffset();
            if (tz !== -120 && tz !== -180 && tz !== -60) {
                // alert(
                //     t(
                //         'Clock timezone is probably invalid. Are you using Chrome on Android? If so, please change browser.'
                //     )
                // );
            }
        }
        this.initRevisionCheck();
    }

    setSocket() {
        const { chatStore, userStore } = this.props;
        const socket = socketIOClient(config.SOCKET_ROOT);
        socket.on('connect', data => {
            // console.log('Response', data);
            chatStore.setupIo(socket);
            userStore.setupIo(socket);
        });
        socket.on('connection_established', data => {
            // console.log('Response', data);
            chatStore.setupIo(socket);
            userStore.setupIo(socket);
        });
    }

    render() {
        const { commonStore } = this.props;
        let scale = 1;
        if (window.outerWidth < 1000) scale = 1;
        if (commonStore.appLoaded) {
            return (
                <Fragment>
                    <MetaTags>
                        <meta name="viewport" content={`width=device-width, initial-scale=${scale}`} />
                    </MetaTags>
                    <NotificationsDock />
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/register" component={Register} />
                        <Route path="/visitorClientRegister" component={VisitorClientRegister} />
                        <Route path="/visitorRegister" component={VisitorAdd} />
                        <Route path="/forgot" component={ForgotPassword} />
                        <PrivateRoute path="/settings" component={Settings} />
                        <PrivateRoute path="/admin" render={props => <AdminLayout {...props} />} />
                        <Route path="/gdpr" component={GDPRPage} />
                        <Route path="/privacy-policy" component={PrivacyPage} />
                        <Route path="/terms" component={TermsPage} />
                        <Route path="/documentReport" component ={DocumentReport}/>
                        <Route path="/multipleDocumentsPdfPreview" component ={MultipleDocumentsPdfPreview}/>
                        <Route path="/" component={LandingPage} />                        
                    </Switch>
                </Fragment>
            );
        }
        return null;
    }
}

export default App;
