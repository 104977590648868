import { observable, action, computed } from 'mobx';

import agent from '../agent';

const currentEntityInit = {
    urgency: 'normal',
    assigned_id_init: null,
    Transitions: [],
    status: 'new',
    due_date: '',
};
class DeviationStore {
    @observable currentList = [];

    @observable currentEntity = currentEntityInit;

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @observable filters = {
        subject: '',
        assigned_id: '',
        project: '',
        status: '',
        reported_by_id: '',
    };

    @observable appliedFilters = {
        subject: '',
        assigned_id: '',
        project: '',
        status: '',
        reported_by_id: '',
    };

    @computed get getPreparedData() {
        const {
            id,
            assigned_id,
            project_id,
            task_id,
            subject,
            due_date,
            comments,
            urgency,
            attachments,
            txt_cause,
            txt_consequence,
            txt_how_to_correct,
            txt_how_to_stop,
            txt_tbd,
            txt_prevent,
            txt_fix,
            spent_hours,
            spent_rate,
            spent_other,
            spent_total,
            myself,
        } = this.currentEntity;
        const data = this.currentEntity.data || {};

        const { history, ...preparedData } = data;
        return {
            id,
            assigned_id,
            project_id,
            task_id,
            subject,
            due_date,
            comments,
            urgency,
            data: preparedData,
            attachments,
            txt_cause,
            txt_consequence,
            txt_how_to_correct,
            txt_how_to_stop,
            txt_tbd,
            txt_prevent,
            txt_fix,
            spent_hours,
            spent_rate,
            spent_other,
            spent_total,
            myself,
        };
    }

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    @action loadList(params) {
        return agent.Deviation.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    @action load(id) {
        this.loading = true;
        return agent.Deviation.load(id)
            .then(
                action(({ deviation: { assigned_id, ...rest } }) => {
                    this.currentEntity = {
                        assigned_id,
                        assigned_id_init: assigned_id,
                        ...rest,
                    };
                    this.loading = false;
                })
            )
            .catch(
                action(err => {
                    this.returnDefaultNew();
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action transitionDeviation(id, transitionName, transitionData) {
        return agent.Deviation.transition({ id, transitionName, transitionData })
            .then(
                action(deviation => {
                    return deviation;
                })
            )
            .catch(
                action(err => {
                    console.log('err', err, this);
                    this.updating = false;
                    throw err.response && err.response.body && err.response.body.error;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        if (!values.data) values.data = {};

        return agent.Deviation.save(values, isAdd)
            .then(
                action(({ deviation }) => {
                    const { assigned_id, ...rest } = deviation;
                    this.currentEntity = {
                        assigned_id,
                        assigned_id_init: assigned_id,
                        ...rest,
                    };
                    this.updating = false;
                    return deviation;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    throw err.response && err.response.body && err.response.body.error;
                })
            );
    }

    @action async remove(id) {
        await agent.Deviation.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @action calcTotal(deviation) {
        let { spent_hours, spent_rate, spent_other } = deviation;
        spent_hours = spent_hours ? parseFloat(spent_hours) : 0;
        spent_rate = spent_rate ? parseFloat(spent_rate) : 0;
        spent_other = spent_other ? parseFloat(spent_other) : 0;
        deviation.spent_total = spent_hours * spent_rate + spent_other;
    }

    @action handleChange = (name, value) => {
        const deviation = { ...this.currentEntity };
        if (name === 'project_id') {
            deviation.task_id = '';
        }
        deviation[name] = value;
        this.calcTotal(deviation);
        this.currentEntity = deviation;
    };

    @action handleChangeFull(obj = {}) {
        this.currentEntity = { ...this.currentEntity, ...obj };
    }

    @action returnDefaultNew(params) {
        this.currentEntity = {
            ...currentEntityInit,
            ...params,
        };
        this.loading = false;

        return this.currentEntity;
    }

    @action sendEmail(id, email) {
        return agent.Deviation.sendEmail(id, email)
            .then(
                action(r => {
                    return r;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
}

export default new DeviationStore();
