import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { find, cloneDeep, sortBy } from 'lodash';
import MultiSelect from '@khanacademy/react-multi-select';

import { Input } from '../../../elements/Input';
import ReactQuill from 'react-quill';
import { Row, Col,Modal } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RadioWidget from '../../../components/GenericForm/Widgets/RadioWidget';
import { ManagersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import './Banner.css';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';
import { style } from '~/variables/Variables';


@inject('projectStore', 'commonStore', 'userStore','bannerStore')
@withRouter
@withLocalization
@observer
class AddBanner extends Component {
    state = {
        users: null,
        members: null,
        selectedUsers: null,
        editingRowId: null,
        usersToAdd: null,
        editorHTML: '',
        theme : 'snow',
        modules: {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        },
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ],

        placeholder: 'Write Something',
        banner_name:'',
        banner_view:false
    };

    async loadData() {
        const { id, userStore,user_type } = this.props;
        if (!id) return;
       
        if(user_type === "pm"){
            const user = await userStore.load(id);
            const users = await userStore.loadLookup('members', '');
            const members = this.getSortedMembers(cloneDeep(user.user.ManagesUsers), users);
            const membersIds = members.map(({ id }) => id);
            const selectedUsers = [ ...(new Set(membersIds)) ];
            const usersToAdd = this.getUsersToAdd(user.user.ManagesUsers, users);
            const userOptions = usersToAdd.map((r) => {
                return { value: r.id, label: r.fullname };
            });
            this.setState({
                users,
                selectedUsers,
                members,
                usersToAdd,
                userOptions,
            });
        }

        if(user_type === "admin"){
            const user = await userStore.load(id);
            const users = await userStore.loadLookup('members', '');
            const members = [ ...(new Set(users))];
            const userOptions = members.map((r) => {
                return { value: r.id, label: r.fullname };
            });
            this.setState({
                users,
                userOptions,
            });
        }

      
    }

    getSortedMembers(members, users) {
        members.forEach(member => {
            const user = find(users, r => r.id === member.user_id);
            member.fullname = user ? user.fullname : '???';
        });
        members = sortBy(members, r => r.fullname.toUpperCase());
        return members;
    }

    componentDidUpdate(previousProps) {
        const { id } = this.props;
        if (id !== previousProps.id) {
            this.loadData();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async onSave() {
        const {
            bannerStore, id, t, commonStore, history
        } = this.props;
        const{selectedUsers,banner_name,editorHTML}=this.state;
        if(!banner_name || !selectedUsers || !editorHTML){
            commonStore.addNotification(t('All fields are required'), null, 'error');
            return;
        }
        if(banner_name.trim().length === 0){
            commonStore.addNotification(t('Space characters are not allowed'), null, 'error');
            return;
        }
        const payload ={
            body:editorHTML,
            name:banner_name,
            readers:selectedUsers
        }
        await bannerStore.save(payload).then(response=>{
            bannerStore.resetLastListLoadTime();
            commonStore.addNotification(t('Saved'), null, 'success');
            history.push(this.getListUrl());
        });
    }

    getUsersToAdd(members, users) {
        const toAdd = [];
        const memberIds = [];
        members.forEach(member => memberIds.push(member.user_id));
        users.forEach(user => {
            if (memberIds.indexOf(user.id) >= 0) return;
            toAdd.push(user);
        });
        return toAdd;
    }

    getUserNameById(id) {
        const user = find(this.state.users, r => r.id === id);
        if (!user) return '???';
        return `${user.fullname}`;
    }


    getListUrl() {
        //returns banner list URL//
        return '/admin/banner';
    }

    cancelClick() {
        //redirects pages to list URL//
        const { history } = this.props;
        history.push(this.getListUrl());
    }

    handleTextChange = (html) => {
        //handles text change in editor and passes it to parent//
        this.setState({ editorHTML: html })

    }

    async assignMembersByProject(id) {
        // var ids = [1,2,3,4,5]
        var userOptionsOld = this.state.userOptions;

        const {
            projectStore
        } = this.props;
        await projectStore.getProjectMembersIds(id).then(
            (data) => {
                let projectMemberIds = data.membersArray || [];
                this.setState({
                    selectedUsers: projectMemberIds,
                    members: userOptionsOld.filter(option => projectMemberIds.indexOf(option.value) !== -1).map(option => ({ id: option.value, label: option.label }))
                })
            }
        );
    }

    render() {
        const {
            users, members, editingRowId, usersToAdd, selectedUsers, userOptions,banner_name,banner_view
        } = this.state;
        const { id, t,user_type } = this.props;
        if(user_type==="pm"){

            if (!users || !members || !id) {
                return <LoadingSpinner />;
            }
        }
        if(user_type==="admin"){
            if (!users || !id) {
                return <LoadingSpinner />;
            }
        }

        // const userOptions = usersToAdd.map((r) => {
        //     return { value: r.id, label: r.fullname };
        // });

        return (
            <div style={{padding:'0px 1em'}}>

                <div className="page__header">
                        <p style={{color: '#4c83f7',cursor:'pointer'}} onClick={() => this.cancelClick()}> {t('Go Back')}</p>
                        <h1 className="page__title" style={{fontWeight:400}}>
                            {t('Add')}{t('New')}{t('Banner')}
                        </h1>
                </div>
                <div className="row" style={{ display: 'flex', alignItems: 'center',padding:'0px 8px',marginTop:'20px' }}>
                    <div className="col-md-6">
                        <div className="form-group field field-string">
                            <label className="control-label">{t('Select Member')}*</label>
                            <MultiSelect
                                options={userOptions}
                                selected={selectedUsers || []}
                                onSelectedChanged={(id) => {
                                    this.setState({ 
                                        selectedUsers: id,
                                        members: userOptions.filter(option => id.indexOf(option.value) !== -1).map(option => ({ id: option.value, label: option.label }))
                                    });
                                }}
                                overrideStrings={{
                                    selectSomeItems: t('Select people...'),
                                    allItemsAreSelected: t('All members are selected'),
                                    selectAll: t('Select All'),
                                    search: t('Search'),
                                }}
                              
                            />
                        </div>
                    </div>
                    {
                        user_type === "pm" && (

                        <div className="col-md-6">
                            <div className="form-group field field-string">
                                <label className="control-label">{t('Assigned members of projects')}</label>
                                <ProjectsWidget
                                    value={''}
                                    placeholder={'Assigned members of projects'}
                                    onChange={(id) => this.assignMembersByProject(id)}
                                />
                            </div>
                        </div>
                        )
                    }
               
   
                </div>

                <div className='row' style={{padding:'0px 8px' }}>
                    <div className='col-md-12 col-lg-12'>
                    <Input
                        label={`${t('Banner Name')} *`}
                        placeholder={t('Banner Name')}
                        type="text"
                        name="banner_name"
                        value={banner_name}
                        removeZIndex = {true}
                        onChange={evt => {
                            this.setState({banner_name:evt.target.value})
                        }}
                    />
                    </div>
                </div>
                <div className='row'  style={{padding:'0px 8px' }}>
                    <div className='col-md-12 col-lg-12'>
                    <ReactQuill
                        theme = {this.state.theme}
                        onChange={this.handleTextChange}
                        value={this.state.editorHTML}
                        modules={this.state.modules}
                        formats={this.state.formats}
                        placeholder={this.state.placeholder}
                        style={{ border: '0px', minHeight:'200px', color:'black',marginTop:'20px'}}
                        preserveWhitespace={true}
                    />
                    </div>
                </div>
                <div>
                <div  style={{padding:'0px 8px' }}>

                    <Button  wd icon fill  primary onClick={() => this.onSave()}>
                        {t('Save')}
                    </Button>
                    <Button  wd icon style={{marginLeft:'1.4em'}}  primary onClick={() => this.cancelClick()}>
                        {t('Cancel')}
                    </Button>
                </div>
                </div>
              
            </div>
          
        );
    }
}

export default AddBanner;
