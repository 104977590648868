import React from 'react';
import PropTypes from 'prop-types';

const BaseFormItem = ({
    label, style, className, children,removeZIndex
}) => (
    <div className={className} style={style}>
        <label className={`input-block ${label ? '' : 'input-block_no-label'}`}>
            {label && (
                <span className={removeZIndex?"input-block__label-removezindex":"input-block__label"} title={label}>
                    {label}{removeZIndex}
                </span>
            )}
            {children}
        </label>
    </div>
);

export const Input = ({
    label, value, onChange,removeZIndex, style, className, children, error, ...rest
}) => (
    <BaseFormItem label={label} style={style} className={className} removeZIndex={removeZIndex}>
        <input
            className={`form-control input-block__base-input ${error ? 'input-block__base-input_error' : ''}`}
            type="text"
            value={value || ''}
            onChange={onChange}
            {...rest}
            
        />
    </BaseFormItem>
);

export const NumberInput = ({
    label, value, onChange, style, className, children, ...rest
}) => (
    <BaseFormItem label={label} style={style} className={className}>
        <input
            className="form-control input-block__base-input"
            type="number"
            value={value}
            onChange={onChange}
            {...rest}
        />
    </BaseFormItem>
);

export const Textarea = ({
    label, value, onChange, style, className, children, error, ...rest
}) => (
    <BaseFormItem label={label} style={style} className={className}>
        <textarea
            className={`form-control input-block__base-input input-block__base-input_textarea ${
                error ? 'input-block__base-input_error' : ''
            }`}
            value={value || ''}
            onChange={onChange}
            {...rest}
        />
    </BaseFormItem>
);

BaseFormItem.propTypes = {
    label: PropTypes.string,
};

export default Input;
