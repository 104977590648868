import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';

import Button from '../../../components/CustomButton/CustomButton.jsx';

import ModalEditor from './ProjectMembersForm';
import ProjectListFilter from './ProjectListFilter/ProjectListFilter';

import withLocalization from '~/hoc/withLocalization';
import withPermissions from '~/hoc/withPermissions';
import GenericList from '~/components/GenericList/GenericList';
import { serverTimeFormat } from '~/library/core';
// Elements

import { minutesToTimeInput } from '~/utils/timeCalc';

@inject('projectStore', 'userStore')
@withRouter
@withPermissions
@withLocalization
@observer
class ProjectsList extends Component {
    state = {
        nowEditingId: null,
    };

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    startEdit = (id) => {
        if (this.props.userStore.currentUser.user_type == 'member') return;
        // console.log('ID', id);
        this.setState({ nowEditingId: id });
    };

    renderTableActionButtons = (id, aclAllowed) => (
        <div className="actions-center">
            {aclAllowed('project-edit') && (
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
            )}
            {aclAllowed('project-delete') && (
                <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                    <i className="fa fa-times" />
                </Button>
            )}
            <Button onClick={() => this.handleTableButtonAction(id, 'view')} bsStyle="default" simple icon>
                <i className="fa fa-align-left" />
            </Button>
        </div>
    );

    afterChangeMembers() {
        this.props.projectStore.setFilter('reloadTime', new Date().toString());
        this.props.projectStore.onFilter();
        this.setState({ nowEditingId: null });
    }

    render() {
        const { loading, filters, appliedFilters } = this.props.projectStore;
        const { aclAllowed, t, userStore } = this.props;
        const { nowEditingId } = this.state;
        return (
            <Fragment>
                <Modal
                    size="lg"
                    dialogClassName="custom-big-modal"
                    show={!!nowEditingId}
                    onHide={() => this.setState({ nowEditingId: null })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Project members')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor id={nowEditingId} afterSave={() => this.afterChangeMembers()} />
                    </Modal.Body>
                </Modal>
                <div className="table-list">
                    <div className="table-list__header">
                        {aclAllowed('project-add') && (
                            <Button fill wd icon onClick={() => this.props.onAdd()}>
                                {this.props.t('Add new')} <i className="fa fa-plus" />
                            </Button>
                        )}
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'Employer / Project',
                                id: 'name',
                                accessor: ({ id, name }) => (
                                    <a
                                        className="list-main-column"
                                        onClick={() => this.handleTableButtonAction(id, 'edit')}
                                    >
                                        {name}
                                    </a>
                                ),
                            },
                            {
                                Header: 'Last Updated',
                                id: 'updated_at',
                                accessor: ({ updated_at }) => updated_at && moment(updated_at).format(serverTimeFormat()),
                            },
                            {
                                Header: 'Location',
                                id: 'address',
                                accessor: ({ address, post_place, post_number }) =>
                                    `${address ? `${address} ` : ''}${post_place ? `${post_place} ` : ''}${
                                        post_number ? `${post_number} ` : ''
                                    }`,
                            },
                            {
                                Header: 'Total Members',
                                id: 'members_amount',
                                sortable: false,
                                accessor: ({ members_amount, id }) => (
                                    <span>
                                        <div className="badge badge-green">{members_amount}</div>
                                        {userStore.currentUser.user_type != 'member' && (
                                            <Button
                                                style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 10 }}
                                                onClick={() => this.startEdit(id)}
                                            >
                                                {t('Assign')}
                                            </Button>
                                        )}
                                    </span>
                                ),
                            },
                            {
                                Header: 'Total Hours',
                                id: 'total_hours',
                                sortable: false,
                                accessor: ({ total_hours }) => `${minutesToTimeInput(total_hours, false)}`,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: ({ id }) => this.renderTableActionButtons(id, aclAllowed),
                            },
                        ]}
                        forceReload={this.props.projectStore.deleteSuccess}
                        filters={appliedFilters}
                        lastListLoadTime={this.props.projectStore.lastListLoadTime}
                        header={
                            <ProjectListFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.projectStore.setFilter(name, value)}
                                onFilter={() => this.props.projectStore.onFilter()}
                                loading={loading}
                            />
                        }
                        requestData={(params) => this.props.projectStore.loadList(params)}
                    />
                </div>
            </Fragment>
        );
    }
}
export default ProjectsList;
