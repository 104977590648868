import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';


@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class ProjectRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectRules: [],
        }
        this.loadData = this.loadData.bind(this);       
        this.handleUserDataChange = this.handleUserDataChange.bind(this);       
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.project_statuses){
            const project_statuses = currentEntity.client.data.project_statuses;
            this.setState({                
                projectRules: project_statuses
            })
        }
    }

    handleUserDataChange = (name,value) =>{
        console.log(name,value);
        const {currentEntity} = this.props.clientStore;
        if(currentEntity.client.data.project_statuses){
            currentEntity.client.data.project_statuses=value;
        }
        else{
            console.log("No project rules")
            // let basicRules = {} ;
            // basicRules[name]=value;
            // currentEntity.client.data.basicRules = basicRules;
        }
    }

    render() {
        const { projectRules } = this.state;
        const { t } = this.props;
        return (
            <div>
                <div className="mt-2 custom-card">
                    <h6>{t('Project statuses')}</h6>
                    <TableWidget
                        headers={['code', 'Label']}
                        metaData = {[{field:'code',type:'text',placeholder:'code', required: true},
                            {field:'name',type:'text',placeholder:'Label', required: true}]}                        
                        data={projectRules}
                        onChange = {data => this.handleUserDataChange('projectRules',data)}
                    />
                </div>
            </div>
        );
    }
}
export default ProjectRules;
