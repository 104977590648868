import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';
import NotificationSystem from 'react-notification-system';


import routes from '../routes';
import visitorRoutes from '../visitorRoutes';
import AdminNavbar from '../components/Navbars/AdminNavbar.jsx';
import Sidebar from '../components/Sidebar/Sidebar';

import withLocalization from '~/hoc/withLocalization';
import { style } from '~/variables/Variables';

@inject('userStore', 'commonStore', 'authStore')
@withLocalization
@observer
class Admin extends Component {
    mobxHook = autorun(() => {
        const { commonStore } = this.props;
        if (commonStore.PendingNotifications.length > 0) {
            commonStore.PendingNotifications.forEach(pn => {
                if (this.refs.notificationSystem) this.refs.notificationSystem.addNotification(pn);
            });
        }
    });

    constructor(props) {
        super(props);
        this.state = {
            color: 'black',
            hasImage: true,
            navbar: false,
            mini: false,
            fixedClasses: 'dropdown show-dropdown open',
        };
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === '/admin') {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        render={routeProps => (
                            <prop.component {...routeProps} handleClick={this.handleNotificationClick} />
                        )}
                    />
                );
            }
            return null;
        });
    };

    handleLogOut = () => {
        this.props.userStore.forgetUser();
    };

    handleMiniClick = () => {
        this.setState({ mini: !this.state.mini });
        document.body.classList.toggle('sidebar-mini');
    };

    render() {
        const { commonStore, authStore } = this.props;
        return (
            <div className="wrapper">
                {(authStore && commonStore && commonStore.config && commonStore.config.client
                    && (commonStore.config.client.allow_visitor_only == undefined || !commonStore.config.client.allow_visitor_only)) ?
                    <>
                        <NotificationSystem ref="notificationSystem" style={style} />
                        <Sidebar {...this.props} routes={routes} hasImage />
                        <div className="main-panel" id="main-panel" ref="mainPanel">
                            {authStore && commonStore && commonStore.connectionError && !authStore.inProgress && false && (
                                <div style={{ background: 'red', padding: 20 }}>ERROR IN CONNECTION</div>
                            )}
                            <AdminNavbar
                                {...this.props}
                                handleMiniClick={this.handleMiniClick}
                                handleLogOut={this.handleLogOut}
                                navbar={this.state.navbar}
                                routes={routes}
                            />
                            <Switch>{this.getRoutes(routes)}</Switch>
                        </div>
                    </>
                 : <>
                 <NotificationSystem ref="notificationSystem" style={style} />
                        <Sidebar {...this.props} routes={visitorRoutes} hasImage />
                        <div className="main-panel" id="main-panel" ref="mainPanel">
                            {authStore && commonStore && commonStore.connectionError && !authStore.inProgress && false && (
                                <div style={{ background: 'red', padding: 20 }}>ERROR IN CONNECTION</div>
                            )}
                            <AdminNavbar
                                {...this.props}
                                handleMiniClick={this.handleMiniClick}
                                handleLogOut={this.handleLogOut}
                                navbar={this.state.navbar}
                                routes={visitorRoutes}
                            />
                            <Switch>{this.getRoutes(visitorRoutes)}</Switch>
                        </div>
                 </>}
            </div>
        );
    }
}

export default Admin;
