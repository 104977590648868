import { observable, action } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

class DashboardStore {
    @observable currentData = [];

    @observable loading = true;

    @action load(params) {
        this.loading = true;
        return agent.Dashboard.get(params)
            .then(
                action(list => {
                    // this.requestParams = params;
                    this.currentData = list;
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }
}

export default new DashboardStore();
