import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

import Button from '../../../components/CustomButton/CustomButton.jsx';

import TaskListFilter from './TaskListFilter/TaskListFilter';

import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import { minutesToTimeInput } from '~/utils/timeCalc';
import customerFeature from '~/utils/customerFeature';
import { serverTimeFormat } from '~/library/core';
// Elements

@inject('taskStore', 'userStore')
@withRouter
@withLocalization
@observer
class TasksList extends Component {
    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    renderTableActionButtons = (id, full) => {
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        return (
            <div className="actions-center">
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {(user_type == 'member' || (user_type == 'member' && full.assignee_id == userId) || allow) &&
                    !full.is_default_for_project && (
                        <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                            <i className="fa fa-times" />
                        </Button>
                    )}
                <Button onClick={() => console.log('redirect to task View')} bsStyle="default" simple icon>
                    <i className="fa fa-align-left" />
                </Button>
            </div>
        );
    };

    render() {
        const { loading, filters, appliedFilters } = this.props.taskStore;
        return (
            <div className="table-list">
                <div className="table-list__header">
                    <Button fill wd icon onClick={() => this.props.onAdd()}>
                        {this.props.t('Add new')} <i className="fa fa-plus" />
                    </Button>
                </div>
                <GenericList
                    columns={[
                        {
                            Header: 'Task',
                            id: 'name',
                            accessor: ({ id, name, task_number }) => (
                                <a
                                    className="list-main-column"
                                    onClick={() => this.handleTableButtonAction(id, 'edit')}
                                >
                                    {`${task_number} | ${name}`}
                                </a>
                            ),
                        },
                        {
                            Header: 'Project',
                            id: 'project_name',
                            sortable: true,
                            accessor: 'project_name',
                        },
                        {
                            Header: 'Assignee',
                            id: 'user_name',
                            sortable: true,
                            accessor: ({ user_name, is_default_for_project }) => (
                                <span>{is_default_for_project ? this.props.t('All') : `${user_name || ''}`}</span>
                            ),
                        },
                        {
                            Header: 'Location',
                            id: 'location',
                            sortable: false,
                            accessor: ({ address, post_place, post_number }) => (
                                <span>
                                    {`${address ? `${address} ` : ''}${post_place ? `${post_place} ` : ''}${
                                        post_number ? `${post_number} ` : ''
                                    }`}
                                </span>
                            ),
                        },
                        {
                            Header: 'Last Updated',
                            id: 'updated_at',
                            accessor: ({ updated_at }) => <span>{updated_at && moment(updated_at).format(serverTimeFormat())}</span>,
                        },
                        {
                            Header: 'Total Hours',
                            id: 'total_hours',
                            sortable: false,
                            accessor: ({ total_hours }) => `${minutesToTimeInput(total_hours, false)}`,
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: (full) => this.renderTableActionButtons(full.id, full),
                        },
                    ]}
                    forceReload={this.props.taskStore.deleteSuccess}
                    filters={appliedFilters}
                    lastListLoadTime={this.props.taskStore.lastListLoadTime}
                    header={
                        <TaskListFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.taskStore.setFilter(name, value)}
                            onFilter={() => this.props.taskStore.onFilter()}
                            loading={loading}
                        />
                    }
                    requestData={(params) => this.props.taskStore.loadList(params)}
                />
            </div>
        );
    }
}
export default TasksList;
