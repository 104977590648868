import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import moment from 'moment/min/moment-with-locales';

import AcceptFormBlock from '../Timelogs/AcceptFormBlock';

import AbsencesTotals from './AbsencesTotals';

import { Modal,Row,Col } from 'react-bootstrap';
import { Input, Textarea } from '../../../elements/Input';
import MultiSelect from '@khanacademy/react-multi-select';
import Button from '../../../components/CustomButton/CustomButton.jsx';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/absence';
import customerFeature from '~/utils/customerFeature';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            absence_type: { md: 4 },
            // 'status':{md:4},
            child: { md: 4, doShow: ({ formData }) => formData.absence_type === 'child' },
        },
        {
            from: { md: 4 },
            to: { md: 4 },
          
        },
        {
            total_days: {
                md: 4,
            },
            absence_payment_per_day: { md: 4 },
            absence_total_payment:{md:4}
           
            // 'status':{md:4},
        },
        {
            comments: { md: 12 },
        },
        {
            attachments: { md: 12 },
        },
    ],
    absence_type: {
        'ui:widget': 'AbsenceStatuses',
    },
    child: {
        'ui:widget': 'KidsSelect',
    },
    attachments: {},
    user_id: {
        'ui:widget': 'AllUsersWidget',
    },
};

@inject('absenceStore', 'commonStore', 'userStore','leaveDetailsStore')
@withRouter
@withLocalization
@observer
class AbsencesForm extends Component {
    state = {
        schema: getSchema(),
        uiSchema: defaultUiSchema,
        show_modal:false,
        absence_note:'',
        project_email:'',
        error_msg:'',
        modal_id:0,
        from_date:'',
        to_date:''
    };

    getListUrl() {
        return '/admin/absences';
    }

    async handleChangeStatus(action) {
        this.loadData();
    }

    async loadData() {     
        const {
            absenceStore, id, add, mode, location
        } = this.props;
        const { currentEntity } = absenceStore;

        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });

        if (add) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return absenceStore.returnDefaultNew({ location });
        }
        const absence = await absenceStore.load(id, add);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, absence.absence) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        const { userStore } = this.props;
        const { currentUser } = userStore;

        schema.attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Absence',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        // const showUser = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
        //     && customerFeature('pm_allow_editing_all')
        //     && !customerFeature('disallow_absences_vacations_not_self');

        // if (showUser) {
        //     schema['ui:layout'][0].user_id = { md: 4 };
        // }

        // if (currentUser.user_type === 'member') {
        //     if (schema.user_id) schema.user_id.classNames = 'hidden';
        // }
        schema.user_id.classNames = 'hidden';

        return schema;
    }

    componentWillMount() {
        this.props.absenceStore.loading = true;
        
        // console.log("load data from absent form",this.props.absenceStore.currentEntity)
        // alert("yes")
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    openModal(id,values){
        console.log('values',values);
        let comment = `I would like to take absence from ${values.from} to ${values.to}`
        this.setState({show_modal:true,absence_note:comment,modal_id:id,from_date:values.from,to_date:values.to});
       // this.loadProjectDetails(id,values);
    }

    onSave(values) {
        const {
            absenceStore, history, add, t, commonStore
        } = this.props;
        const { userStore } = this.props;
        const { currentUser } = userStore;

        if (!values.user_id) {
            values.user_id = currentUser.id;
        }
        const allow = (currentUser.user_type == 'admin' || currentUser.user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');

        if (currentUser.id != values.user_id && !allow) {
            commonStore.addNotification(t('Can not save on behalf of others'), null, 'error');
            return false;
        }
        values.status = 'active';
        return absenceStore.save(values, add).then(result => {
            if (!result.absence || !result.absence.id) {
                // some error
                console.log(result);
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            this.openModal(result.absence.id,values);
            // commonStore.addNotification(t('Saved'), null, 'success');
            // history.push(this.getListUrl());
            return true;
        });
    }

    applyVacation = async ()=>{
        const{project_email,error_msg,absence_note,modal_id,from_date,to_date} = this.state;
        const {
             history,  t, userStore,leaveDetailsStore
        } = this.props;
        const { currentUser } = userStore;
        if(absence_note === '' || project_email === '' || project_email === null ){
             this.setState({error_msg:true});
            return;
        }
        else{
           let payloadData ={
            modal_id:modal_id,
            email:project_email,
            comment:absence_note,
            modal_type:"Absence",
            from_date:from_date,
            to_date:to_date
           }

           await leaveDetailsStore.save(payloadData).then(result=>{
               this.setState({
                show_modal:false,
                project_email:'',
                absence_note:''
               },()=>{
                history.push(this.getListUrl());
               })
           })

        }
    }

    handleClosesApplyVacation = ()=>{
        this.setState({
            show_modal:false,
            project_email:'',
            vacation_note:''
           })
    }

    validateAbsence(formData,errors){
        const {t} = this.props;
        // console.log("Checking form : ",formData,errors);
        if(formData.to && formData.from && moment(formData.from).isAfter(moment(formData.to))){
            errors.from.addError(t("From date should be before to date"));            
        }
        if(formData.absence_type && formData.absence_type == 'self' && formData.total_days > 3){            
            errors.to.addError(t("Self-clarification is max 3 days"));            
        }
        if (formData.absence_type && formData.to && formData.absence_type == 'self' && moment(formData.to).isAfter(moment().add(3,'days')) ){
            errors.to.addError(t("Only up to 3 future days are valid."));            
        }
        if (formData.absence_type && formData.from && formData.leave_type && formData.absence_type == 'self' && moment(formData.from).isBefore(moment().add(-1,'year').add(2,'days')) ){
            errors.from.addError(t("Cannot apply for sick leave older than 1 year."));            
        }                        
        return errors;
    }



    render() {
        const { absenceStore, add,t } = this.props;
        const { loading, currentEntity, recalc } = absenceStore;
        const { schema, uiSchema,show_modal,project_email,absence_note } = this.state;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting') && !add;
        let allow = (user_type == 'admin' || user_type == 'pm')
            && customerFeature('pm_allow_editing_all')
            && !customerFeature('disallow_absences_vacations_not_self');
        allow = !add && currentEntity && currentEntity.absence && currentEntity.absence.user_id != userId && !allow;

        if (currentEntity && currentEntity.absence && currentEntity.absence.status == 'approved') allow = true;
if(currentEntity && currentEntity.absence && currentEntity.absence.absence_payment_per_day > 0){
currentEntity.absence.absence_total_payment=currentEntity.absence.absence_payment_per_day * currentEntity.absence.total_days;
}
        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <AbsencesTotals user={currentEntity.absence.user_id} />
              
                <GenericForm
                    entity={currentEntity.absence}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.absence"
                    onChange={async data => absenceStore.recalc(data)}
                    onSave={values => this.onSave(values)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                    customvalidate = {(formData,errors)=>this.validateAbsence(formData,errors)}
                    disallowSave={allow}
                />
                {showStatus && (
                    <AcceptFormBlock
                        params={{ id: this.props.id, status: currentEntity.absence.status,data:currentEntity, entity: 'absence' }}
                        afterSave={() => this.handleChangeStatus()}
                    />
                )}
                <Modal size="lg" show={show_modal} onHide={this.handleClosesApplyVacation}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Vacation')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <Input
                                className="custom-form__col"
                                label={`${t('To')} *`}
                                placeholder={t('Enter email address')}
                                type="email"
                                name="topm"
                                value={project_email}
                                onChange={evt => {
                                   this.setState({project_email:evt.target.value});
                                }}
                                error={project_email ===''}
                            />
                              {(project_email ==='') &&(
                                <span style={{color:'red'}}>{t('Email Address is required')}</span>   
                              ) }
                        <Textarea
                            label={t('Comment')}
                            value={absence_note}
                            onChange={(e) => this.setState({absence_note:e.target.value})}
                            //placeholder={t('Enter your comments')}
                            required
                            error={this.state.absence_note === ''}
                        />    
                          {(absence_note ==='' ) &&(
                            <span style={{color:'red'}}>{t('Comment is required')}</span>      
                        )}
                       
                    </Modal.Body>
                    <Modal.Footer>
                                <div>
                                    <Button variant="secondary" onClick={this.applyVacation} className="btn-wd btn-fill">
                                        {t('Save')}
                                    </Button>
                                    <Button variant="secondary" onClick={this.handleClosesApplyVacation} className="btn-wd">
                                        {t('Close')}
                                    </Button>
                                </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default AbsencesForm;
