import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import { TimeInput } from '../../../../elements/Input';
import InputTime from '../../../../components/GenericForm/Widgets/InputTime';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class RulesForStartTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rulesForStartTime: {
                end: "00:00",
                start: "00:00",
                total: "00:00",
            },
        }
        this.handleTextChange = this.handleTextChange.bind(this)
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { rulesForStartTime } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            rulesForStartTime.start = basicRules.startTimeRules.start;
            rulesForStartTime.end = basicRules.startTimeRules.end;
            rulesForStartTime.total = basicRules.startTimeRules.total;
            this.setState({ rulesForStartTime })
        }
    }

    handleTextChange = (name, value) => {
        const { rulesForStartTime } = this.state;
        rulesForStartTime[name] = value;
        this.props.handleUserDataChange('startTimeRules', rulesForStartTime);
        this.setState({ rulesForStartTime });
    }


    render() {
        const { rulesForStartTime } = this.state;
        const { t } = this.props;
        return (
            <Row>
                <Col sm={4}>
                    {/* <TimeInput
                        label={`${t('Start time')} (hh:mm)`}
                        name="start"
                        value={rulesForStartTime.start}
                        onChange={evt => {
                            this.handleTextChange('start', evt.target.value);
                        }}
                    /> */}
                    <label className="control-label">{t('Start time')}
                    <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <InputTime       
                        width={"auto"}
                        value={rulesForStartTime.start}
                        onChange={value => {
                            this.handleTextChange('start', value);
                        }}
                    />
                </Col>
                <Col sm={4}>
                    {/* <TimeInput
                        label={`${t('End time')} (hh:mm)`}                                               
                        name="end"                                               
                        value={rulesForStartTime.end}
                        onChange={evt => {
                            this.handleTextChange('end', evt.target.value);
                        }}
                    /> */}
                    <label className="control-label">{t('End time')}
                    <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <InputTime         
                        width={"auto"}               
                        value={rulesForStartTime.end}
                        onChange={value => {
                            this.handleTextChange('end', value);
                        }}
                    />
                </Col>
                <Col sm={4}>
                    {/* <TimeInput
                        label={`${t('Total Time')} (hh:mm)`}
                        name="total"
                        value={rulesForStartTime.total}
                        onChange={evt => {
                            this.handleTextChange('total', evt.target.value);
                        }}
                    /> */}
                    <label className="control-label">{('Total Time')}
                    <span className="text-muted "><em> (hh:mm)</em></span>
                    </label>
                    <InputTime     
                        width={"auto"}                   
                        value={rulesForStartTime.total}
                        onChange={value => {
                            this.handleTextChange('start', value);
                        }}
                    />
                </Col>
            </Row >
        );
    }
}
export default RulesForStartTime;
