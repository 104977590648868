import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col} from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import {NumberInput} from '../../../../elements/Input';


@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class VacationRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standared_vacation_days: 10,
        }
        this.loadData = this.loadData.bind(this); 
        this.handleUserDataChange = this.handleUserDataChange.bind(this); 
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.Vacation_setup) {
            const Vacation_setup = Object.assign({}, currentEntity.client.data.Vacation_setup);            
            this.setState({standared_vacation_days:Vacation_setup.standared_vacation_days})
        }
    }

    handleUserDataChange = (name,value) =>{
        console.log(name,value);
        const {currentEntity} = this.props.clientStore;
        if(currentEntity.client.data.Vacation_setup){
            currentEntity.client.data.Vacation_setup[name]=value;
        }else{
            console.log("No login rules, creating new.");
            let Vacation_setup = {} ;
                Vacation_setup[name]=value;
            currentEntity.client.data.Vacation_setup = Vacation_setup;
        }
    }

    render() {
        const { standared_vacation_days } = this.state;
        const { t } = this.props;
        return (
            <Row>
                <Col sm={4}>
                    <NumberInput
                        label={`${t('Standard vacation days')}`}                      
                        name="standared_vacation_days"
                        value={standared_vacation_days}
                        onChange={evt => {
                            let days = parseInt(evt.target.value,10)
                            this.setState({standared_vacation_days: days },
                                ()=>this.handleUserDataChange('standared_vacation_days',days))
                        }}
                    />
                </Col>
            </Row>
        );
    }
}
export default VacationRules;
