import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import AbsencesList from './AbsencesList';
import AbsencesForm from './AbsencesForm';

import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('authStore', 'absenceStore', 'userStore')
@withRouter
@withRemoveDialog
@observer
class AbsencesPage extends Component {
    onAdd() {
        const { history } = this.props;
        history.push('/admin/absences/add');
    }

    onTableAction(id, type) {
        const { history } = this.props;
        if (type === 'remove') {
            return this.props.handleDelete(id, id => {
                return this.props.absenceStore.remove(id).then(res => {
                    this.props.absenceStore.resetLastListLoadTime();
                    return res;
                });
            });
        }
        history.push(`/admin/absences/${id}`);
    }

    editingId = () => {
        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };

    render() {
        const mode = this.editingId();
        const { user_type } = this.props.userStore.currentUser;
        return (
            <div className="main-content">
                {!mode.editing && (
                    <AbsencesList
                        onTableAction={(id, type) => this.onTableAction(id, type)}
                        onAdd={() => this.onAdd()}
                    />
                )}
                {mode.editing && <AbsencesForm id={mode.id} add={mode.add} />}
            </div>
        );
    }
}
export default AbsencesPage;
