import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';
import _ from 'lodash';
import { Row, Col } from 'react-bootstrap';
import StatusBox from '../../../elements/StatusBox';
import MultiSelect from '@khanacademy/react-multi-select';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericList from '~/components/GenericList/GenericList';
import { norwayTime, } from '~/utils/timeCalc';

import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import { commonStore } from '~/library/stores/commonStore';


@inject('authStore',  'userStore', 'commonStore','userAccessStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class PermissionPage extends Component {
    state = {               
        selectedUsers: [],
        options: [],      
    }

    componentDidMount() {
        this.loadData();
    }


    async loadData() {    
        const {userStore,userAccessStore} = this.props;
        const{currentUser} = userStore;
        await userAccessStore.loadPmMembers(currentUser.id).then((data) => {
            let multiSelectOptions=[];
            data.ManagesUsers.map(item=>{
                multiSelectOptions.push({
                    label: item.fullname + (item.user_title !=='none'?` (${item.user_title})`:''),
                    value: item.id
                })
            })
            this.setState({ options:multiSelectOptions,selectedUsers:[] });
        });     
    }

    async handleAccess(){
        const {userAccessStore,commonStore,t} = this.props;
        if(this.state.selectedUsers.length === 0){
            commonStore.addNotification(t('Please select users'), null, 'error');
        }else{

            const data ={
                ManageUsers: this.state.selectedUsers.map(id => ({ id }))
            }
            await userAccessStore.savePmMembers(data).then((resp)=>{
                this.props.userAccessStore.resetLastListLoadTime();
                commonStore.addNotification(t('Saved'), null, 'success');
                this.state.selectedUsers = [];
            }).catch((err)=>{
            });
        }
      
    }

    handleTableActionButtonAction(id,full){
        const{t,userAccessStore,commonStore} = this.props;
        let data={
            status:'accept'
        }
        userAccessStore.approveAccess(id,data).then(resp=>{
            userAccessStore.resetLastListLoadTime();
            commonStore.addNotification(t('Request Approved Successfully'), null, 'success');
        });
    }

    renderTableActionButtons = (id,full) => {
        const{userStore,t}= this.props;
        const{currentUser} = userStore;
        //renders table edit delete buttons in generic list//                        
        return (
            <div className="actions-center" style={{ justifyContent: 'center' }}>
               {/* {
                full.status === "Requested" && (currentUser.user_type === "member" || currentUser.user_type === "pm") && (
                    <Button onClick={() => this.handleTableActionButtonAction(id, full)} fill wd icon>
                        {t('Accept')}
                    </Button>   
                )
               }  */}
               {
                   full.status === "Requested" && (
                    currentUser.user_type === "member" ? (
                        <Button onClick={() => this.handleTableActionButtonAction(id, full)} fill wd icon>
                        {t('Accept')}
                    </Button> 
                    ) : (currentUser.user_type === "pm" ? (currentUser.id === full.manager_id ? <></> : 
                    <Button onClick={() => this.handleTableActionButtonAction(id, full)} fill wd icon>
                    {t('Accept')}
                </Button>) : <></>
                   ))
               }
               
            </div>
        )
    }

    render() {
        const {t,userStore} = this.props;
        const{currentUser} = userStore;
        return(
          
             <div className="main-content">
              <Row style={{marginBottom:'20px'}}>
                        <Col xs={4}>
                        {
                            currentUser.user_type === "member" && (
                                 <h5>{t('Give Access ')}{t('To')}:</h5>
                            )

                        }
                        {
                            currentUser.user_type === "pm" && (
                                <h5>{t('Send Request ')}{t('Access')}:</h5>
                            )
                        }
                        {
                            currentUser.user_type === "admin" && (
                                <h5>{t('Send Request ')}{t('Access')}:</h5>
                            )
                        }
                         
                        </Col>
                        <Col xs={5}>

                        <MultiSelect
                            options={this.state.options}
                            selected={this.state.selectedUsers}
                            onSelectedChanged={selected => this.setState({selectedUsers:selected})}
                            overrideStrings={{
                                selectSomeItems: t('Select people...'),
                                allItemsAreSelected: t('All users are selected'),
                                selectAll: t('Select All'),
                                search: t('Search'),
                            }} 
                         />
                    
                        </Col>
                        <Col xs={3}>
                        {
                            currentUser.user_type === "member" && (
                                <Button fill wd icon onClick={()=>this.handleAccess()}>
                                    {this.props.t('Give Access')} 
                                </Button>
                            )

                        }
                        {
                            currentUser.user_type === "pm" && (
                                <Button fill wd icon onClick={()=>this.handleAccess()}>
                                    {this.props.t('Send Request ')} 
                                </Button>
                            )
                        }
                        {
                            currentUser.user_type === "admin" && (
                                <Button fill wd icon onClick={()=>this.handleAccess()}>
                                    {this.props.t('Send Request ')} 
                                </Button>
                            )
                        }
                        </Col>
                    </Row> 
             <div className="table-list">
                <GenericList
                    columns={[
                        {
                            Header: 'Expiry Time',
                            id: 'till_date',
                            accessor: ({ till_date }) => (
                               // <span>{till_date}</span>
                                 <span>{till_date && moment(till_date).format(serverTimeFormat())}</span>
                                //<span>{till_date && norwayTime(till_date)}</span>
                            ),
                            sortable:false
                        },
                        {
                            Header: 'User Name',
                            id: 'fullname',
                            accessor: ({fullname,admin_fullname,admin_id}) =>(
                                <span>{admin_fullname?admin_fullname:fullname}</span>
                            ),
                            sortable:false
                        },

                        {
                            Header: 'Status',
                            id: 'status',
                            accessor: 'status'
                        },
                                                        
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: full => this.renderTableActionButtons(full.id, full),
                        },
                    ]}

                    lastListLoadTime={this.props.userAccessStore.lastListLoadTime}
                    requestData={(params) => this.props.userAccessStore.loadList(params)}
                />
             </div>
                  
                    

            </div>
        )
    }
}
export default PermissionPage;
