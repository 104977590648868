import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';

import withPermissions from '../../../hoc/withPermissions';

import LoadingSpinner from '~/elements/LoadingSpinner';
import GenericForm from '~/components/GenericForm/GenericForm';
import withLocalization from '~/hoc/withLocalization';
import getSchema from '~/library/schemas/project';

/* const uiSchema = {
  'ui:field': 'layout_grid',
  'ui:layout_grid': { 'ui:row': [
      { 'ui:col': { md: 12, children: [
            { 'ui:group': 'Name', 'ui:row': [
                { 'ui:col': { md: 6, children: ['name'] } },
                { 'ui:col': { md: 6, children: ['status'] } },
              ] },
            { 'ui:row': [
                { 'ui:col': { md: 6, children: ['title'] } },
                { 'ui:col': { md: 6, children: [
                  { 'ui:row': [
                      { 'ui:col': { md: 6, children: ['name'] } },
                      { 'ui:col': { md: 6, children: ['status'] } },
                    ] }
                ] } },
              ] },
            { 'ui:row': [
                { 'ui:col': { md: 12, children: ['phone'] } },
              ] },
          ] } },
    ] },
} */

const defaultUiSchema = {
    'ui:field': 'tabs',
    'ui:tabs': [
        {
            'ui:title': 'Project info',
            id: 'tab1',
            'ui:field': 'layout',
            'ui:layout:hideframe': true,
            'ui:layout': [
                {
                    'ui:subtitle': 'Project basic',
                    name: { md: 4 },
                    project_number: { md: 4 },
                    status: { md: 4 },
                    assignee_id: { md: 4 },
                    //          'project_type':{md:4},
                    image: { md: 4 },
                },
                {
                    'ui:subtitle': 'Employer info',
                    contact_person: { md: 4 },
                    //          'title':{md:4},
                    email: { md: 4 },
                    phone: { md: 4 },
                    organization_number:{md:4}
                },
                {
                    'ui:subtitle': 'Location',
                    address: { md: 4 },
                    post_place: { md: 4 },
                    post_number: { md: 4 },
                    g_nr: { md: 4 },
                    b_nr: { md: 4 },
                    gps_data: { md: 4 },
                },
                {
                    'ui:subtitle': 'Extras',
                    data: { md: 12 },
                },
                {
                    'ui:subtitle': 'Project documents',
                    attachments: { md: 12 },
                },
            ],
            data: {
                'ui:field': 'layout',
                'ui:layout:hideframe': true,
                'ui:flex': true,
                'ui:layout': [
                    {
                        // require_hms: { className: 'col-md-4' },
                        // security_analyze: { className: 'col-md-4' },
                        start_date: { className: 'col-md-4' },
                        finish_date: { className: 'col-md-4' },
                    },
                ],
                // require_hms: {
                //     'ui:widget': 'CheckBoxWidget',
                // },
                // security_analyze: {
                //     'ui:widget': 'CheckBoxWidget',
                // },
            },
            est_work: {
                'ui:widget': 'Radio',
            },
            image: {
                'ui:widget': 'ImageUpload',
                'ui:imageContext': {
                    model: 'Project',
                    fileType: 'logo',
                    id: 0,
                },
            },
            attachments: {
                'ui:widget': 'AttachmentsWidget',
                'ui:imageContext': {
                    model: 'Project',
                    fileType: 'docs',
                    id: 0,
                },
            },
            gps_data: {
                'ui:widget': 'GpsCoordinatesWidget',
            },
            status: {
                'ui:widget': 'ProjectStatuses',
            },
            assignee_id: {
                'ui:widget': 'ManagersWidget',
            },
            project_number: {
                'ui:placeholder': 'Leave blank to auto-populate',
            },
        },
        {
            'ui:title': 'Members',
            'ui:layout:hideframe': true,
            id: 'tab2',
            'ui:field': 'layout',
            'ui:layout': [
                {
                    members: {
                        md: 12,
                    },
                },
            ],
            multipleMembersSelection: {
                'ui:options': { orderable: false, removable: true, inline: true },
                className: 'form-chooser',
                items: {
                    'ui:options': { orderable: false, removable: true, inline: true },
                    className: 'form-chooser',
                    user_id: {
                        classNames: 'col-md-4',
                        'ui:widget': 'MembersWidget',
                    },
                },
            },
            members: {
                'ui:options': { orderable: false, removable: true, inline: true },
                className: 'form-chooser',
                items: {
                    'ui:options': { orderable: false, removable: true, inline: true },
                    className: 'form-chooser',
                    user_id: {
                        classNames: 'col-md-4',
                        'ui:widget': 'MembersWidget',
                    },
                    additional_pay: { classNames: 'col-md-4' },
                    hours: { classNames: 'col-md-4' },
                    timeframe: {
                        classNames: 'col-md-12',
                        'ui:widget': 'Radio',
                    },
                },
            },
        },
    ],
};

/* const uiSchema = {
  'ui:field': 'layout',
  'ui:layout': [
    {
      name: { md: 12 },
      data: {md:12}
    },
  ],
  'data': {
    allow_vacations: {
      'ui:widget': 'TrueFalse'
    },
    allow_absents: { 'ui:widget': 'TrueFalse' },
    allow_project_users: { 'ui:widget': 'TrueFalse'},
    allow_accepting: { 'ui:widget': 'TrueFalse'},
  }
} */

@inject('projectStore', 'commonStore')
@withRouter
@withLocalization
@withPermissions
@observer
class ProjectsForm extends Component {
    state = {
        schema: getSchema(),
        uiSchema: defaultUiSchema,
    };

    getListUrl() {
        return '/admin/projects';
    }

    async loadData() {
        const {
            projectStore, id, add, mode
        } = this.props;
        this.setState({
            schema: getSchema({
                config: this.props.commonStore.config,
            }),
        });

        if (add) {
            this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, null) });
            return projectStore.returnDefaultNew();
        }
        const project = await projectStore.load(id, add);
        this.setState({ uiSchema: this.prepareSchema(defaultUiSchema, project.project) });
    }

    prepareSchema(_schema, currentEntity) {
        const schema = cloneDeep(_schema);
        schema['ui:tabs'][0].attachments = {
            'ui:widget': 'AttachmentsWidget',
            'ui:imageContext': {
                model: 'Project',
                fileType: 'docs',
                id: currentEntity && currentEntity.id !== 'add' ? currentEntity.id : 0,
                existingAttachment: currentEntity && currentEntity.Attachments ? currentEntity.Attachments : [],
            },
        };
        if (this.props.aclAllowed('show_times_on_project')) {
            schema['ui:tabs'][0]['ui:layout'].push({
                'ui:subtitle': 'Costs and expenses',
                est_hours: { md: 6 },
                est_work: { md: 6 },
            });
        }

        return schema;
    }

    componentWillMount() {
        this.props.projectStore.loading = true;
    }

    async componentDidMount() {
        await this.loadData();
    }

    componentDidUpdate(previousProps) {
        const { location } = this.props;
        if (location !== previousProps.location) {
            this.loadData();
        }
    }

    async recalcGps(e) {
        return this.props.projectStore.recalcGpsForProject(e);
    }

    onSave(values) {
        const {
            projectStore, history, add, t, commonStore
        } = this.props;
        return projectStore.save(values, add).then(result => {
            if (!result.project || !result.project.id) {
                // some error
                console.log(result);
                commonStore.addNotification(t(result.message || 'Error'), null, 'error');
                return false;
            }
            commonStore.addNotification(t('Saved'), null, 'success');
            history.push(this.getListUrl());
            return true;
        });
    }

    render() {
        const { projectStore, add } = this.props;
        const { loading, currentEntity } = projectStore;
        const { schema, uiSchema } = this.state;

        if (loading) return <LoadingSpinner />;

        return (
            <div className="primary-page">
                <GenericForm
                    entity={currentEntity.project}
                    recomputeFields={['gps_data', 'data.addressCache']}
                    uiSchema={uiSchema}
                    schema={schema}
                    translationScope="forms.project"
                    onSave={values => this.onSave(values)}
                    onChange={async data => this.recalcGps(data)}
                    listUrl={this.getListUrl()}
                    isAdding={add}
                />
            </div>
        );
    }
}

export default ProjectsForm;
