import React, { Component } from 'react';
import { Table } from 'react-bootstrap'
import Button from '../../../components/CustomButton/CustomButton.jsx'
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';

@inject('commonStore')
@withLocalization
@withRemoveDialog
@observer
class TableWidget extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            headers: props.headers,
            data: props.data,
            metaData: props.metaData,
            newRow: {},
            editableId: -1
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDataChanges = this.handleDataChanges.bind(this);
        this.handleTableActionButtonAction = this.handleTableActionButtonAction.bind(this);
        this.addRowToData = this.addRowToData.bind(this);
        this.editRowInData = this.editRowInData.bind(this);
        this.setNewRowFields = this.setNewRowFields.bind(this);
        this.checkForRequiredFields = this.checkForRequiredFields.bind(this);
    }

    componentDidMount() {
        this.setNewRowFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data != prevState.data) {
            return { data: nextProps.data };
        }
        else return null;
    }

    handleTableActionButtonAction = (type, index) => {
        if (type === 'add') {
            this.addRowToData();
        }
        else if (type === 'edit') {
            this.editRowInData(index);
        }
        else if (type === 'update') {
            this.updateRowInData(index);
        }
        else {
            this.removeObjectFormData(index);
        }
    }

    setNewRowFields = () => {
        const { newRow, metaData } = this.state;
        for (const i in metaData) {
            newRow[metaData[i].field] = metaData[i].type === 'text' ? '' : 0;
        }
        this.setState({ newRow });
    }

    checkForRequiredFields(row) {
        const { metaData } = this.state;
        const { commonStore, t } = this.props;
        for (const i in metaData) {
            if (!row[metaData[i].field] && metaData[i].required) {
                commonStore.addNotification(t('Please fill all required fields'), null, 'error');
                console.log('Please fill field ', metaData[i].field)
                return false;
            }
        }
        return true;
    }

    addRowToData = () => {
        const { newRow, metaData, data } = this.state;
        if (!this.checkForRequiredFields(newRow))
            return;
        let temp = {};
        for (const i in metaData) {
            temp[metaData[i].field] = newRow[metaData[i].field]
        }
        data.push(temp);
        this.setState({ data }, () => {
            this.props.onChange(this.state.data)
            this.setNewRowFields();
        });
        return;
    }

    editRowInData = (index) => {
        this.setState({ editableId: index })
    }

    updateRowInData = (index) => {
        const { data } = this.state
        if (!this.checkForRequiredFields(data[index]))
            return;
        this.setState({ editableId: -1 })
        return;
    }

    removeObjectFormData = (index) => {
        const { data } = this.state;
        const { handleDelete, t, commonStore, onChange } = this.props;
        return handleDelete(index, async () => {
            data.splice(index, 1);
            commonStore.addNotification(t('Deleted'), null, 'error');
            this.setState({ data }, onChange(this.state.data));
        });
    }

    handleInputChange = (name, evt) => {
        const { newRow } = this.state;
        newRow[name] = evt.target.type === "number" ? parseInt(evt.target.value, 10) : evt.target.value;
        this.setState({ newRow });
    }

    handleDataChanges(name, evt, index) {
        const { data } = this.state;
        let temp = data[index];
        temp[name] = evt.target.type === "number" ? parseInt(evt.target.value, 10) : evt.target.value;
        this.setState({ data }, this.props.onChange(this.state.data));
    }

    render() {
        const { headers, data, metaData, editableId, newRow } = this.state;
        const { t } = this.props;
        return (
            <Table striped hover responsive>
                <thead style={{backgroundColor:'#e1f1f7'}}>
                    <tr>
                        {headers.map((item, index) => (
                            <th key={index}><b>{t(item)}</b></th>
                        ))}
                        <th><b>{t('Actions')}</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {metaData.map((item, index) => (
                            <td key={index}>
                                <input type={item.type} name={item.field} placeholder={t(item.placeholder)}
                                    onChange={evt => this.handleInputChange(item.field, evt)}
                                    value={newRow[item.field] ? newRow[item.field] : item.type === 'number' ? 0 : ''} />
                                {item.required && <span className="text-danger"> <b>{t('*')}</b></span>}
                            </td>
                        ))}
                        <td>
                            <Button onClick={() => this.handleTableActionButtonAction('add')}
                                bsStyle="primary" simple icon fill>
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </Button>
                        </td>
                    </tr>

                    {data.map((item, index) => (
                        <tr key={index}>
                            {metaData.map(label => (
                                <td key={label.field}>
                                    {index === editableId ?
                                        <>
                                            <input type={label.type} name={label.field} placeholder={t(label.placeholder)}
                                                onChange={evt => this.handleDataChanges(label.field, evt, index)}
                                                value={item[label.field]} />
                                            {label.required && <span className="text-danger"> <b>{t('*')}</b></span>}
                                        </>
                                        :
                                        item[label.field]}
                                </td>
                            ))}
                            <td>
                                {index === editableId ?
                                    <Button onClick={() => this.handleTableActionButtonAction('update', index)}
                                        bsStyle="primary" simple icon>
                                        <i className="fa fa-save" aria-hidden="true"></i>
                                    </Button>
                                    :
                                    <Button onClick={() => this.handleTableActionButtonAction('edit', index)}
                                        bsStyle="primary" simple icon>
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </Button>
                                }

                                <Button onClick={() => this.handleTableActionButtonAction('delete', index)}
                                    bsStyle="danger" simple icon>
                                    <i className="fa fa-trash-alt" aria-hidden="true">
                                    </i></Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
}

export default TableWidget;