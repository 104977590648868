import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { find, filter } from 'lodash';
import MultiSelect from '@khanacademy/react-multi-select';

import Select from '../../../elements/Select';
import withLocalization from '../../../hoc/withLocalization';

@inject('userStore','userAccessStore')
@observer
@withLocalization
class UsersWidgetMobx extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            selectValue: '',
            multiSelectOptions: [],
        };
    }

    async componentDidMount() {
        if (this.props.directList) {
            return;
        }
        this.props.userStore.loadLookup(this.props.mode, '');
        if (this.props.isSelectMultiple) {
            this.props.userStore.loadLookup(this.props.mode, '').then(() => {
                const key = `${this.props.mode || 'all'}`;
                const multiSelectOptions = this.prepareOptions(this.props.userStore.currentMembersLookup[key]);
                this.setState({ multiSelectOptions });
            });
        } else {
            this.props.userStore.loadLookup(this.props.mode, '');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('UPD', prevProps.directList && prevProps.directList === this.props.directList);
    }

    loadOptions = (inputValue, callback) => {
        const { mode, userStore, directList,showAdminPM } = this.props;
        const { currentMembersLookup,currentUser } = userStore;
        const key = `${mode || 'all'}${inputValue || ''}`;
        if (directList) {
            callback(this.prepareOptions(directList));
            return;
        }
        if(showAdminPM){
            if(currentUser.user_type === 'pm'){
                 this.props.userAccessStore.loadAllowedPms(currentUser.id).then((data) => {
                    let multiSelectOptions=[];
                    data.users.map(item=>{
                        multiSelectOptions.push({
                            label: item.fullname,
                            value: item.id
                        })
                    })
                    callback(multiSelectOptions);
                });    
            }
            else{
                this.props.userStore.loadLookup(this.props.mode, inputValue).then(() => {
                    const options = this.prepareOptions(currentMembersLookup[key]);
                    callback(options);
                    if (this.props.value && this.props.isMulti) {
                        const currentSelect = filter(options, o => {
                            return this.props.value.indexOf(String(o.value)) >= 0;
                        });
                        this.setState({ selectValue: currentSelect });
                        // this.setState({selectValue: options[0]});
                    }
                });
            }
           
        }
        else{
            this.props.userStore.loadLookup(this.props.mode, inputValue).then(() => {
                const options = this.prepareOptions(currentMembersLookup[key]);
                callback(options);
                if (this.props.value && this.props.isMulti) {
                    const currentSelect = filter(options, o => {
                        return this.props.value.indexOf(String(o.value)) >= 0;
                    });
                    this.setState({ selectValue: currentSelect });
                    // this.setState({selectValue: options[0]});
                }
            });
        }
    };

    changeMultiSelect = (value) => {
        this.setState({ selectValue: value });
    }

    handleInputChange = newValue => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    prepareOptions = array => {
        const { emptyOption, emptyOptionLabel } = this.props;
        const result = array
            ? array.map(pm => {
                return { value: `${pm.id}`, label: `${pm.fullname}` };
            })
            : [];
        if (emptyOption) {
            result.unshift({
                value: '',
                label: emptyOptionLabel,
            });
        }
        return result;
    };

    render() {
        const { directList, isSelectMultiple, t } = this.props;
        const { loadingLookup, currentMembersLookup } = this.props.userStore;
        const {
            value,
            onChange,
            mode,
            isMulti,
            storeAsString,
            disabled,
            label,
            emptyOption,
            emptyOptionLabel,
            placeholder = 'Select...',
            storeAsStringAndFull,
        } = this.props;
        const { inputValue, multiSelectOptions } = this.state;
        const key = `${mode || 'all'}${inputValue || ''}`;
        const options = this.prepareOptions(directList || currentMembersLookup[key]);
        let currentSelect = null;

        if (isMulti) {
            if (this.state.selectValue) {
                currentSelect = this.state.selectValue;
            } else {
                currentSelect = find(options, o => {
                    return String(o.value) === String(value);
                });
            }
        } else if(isSelectMultiple) {
            //console.log("IsSelectMultiple",this.state.selectValue)
            currentSelect = this.state.selectValue;
        } else {
            currentSelect = filter(options, o => {
                return String(o.value) == String(value);
            });
        }

        const inputView = !!label;

        return (
            <div className={`managers-widget ${inputView ? 'input-block' : ''}`}>
                {inputView && <span className="input-block__label">{label}</span>}
                {!isSelectMultiple && <>{mode === 'managers' ? (
                    <Select
                        options={options}
                        value={currentSelect}
                        isDisabled={disabled}
                        onChange={id => onChange(id.value, id)}
                        style={{ width: '100%' }}
                    />
                ) : (
                    <Select
                        asyncSelect
                        cacheOptions
                        emptyOption={emptyOption}
                        emptyOptionLabel={emptyOptionLabel}
                        isDisabled={disabled}
                        isMulti={isMulti}
                        inputView={inputView}
                        loadOptions={this.loadOptions}
                        defaultOptions
                        value={currentSelect}
                        onInputChange={this.handleInputChange}
                        placeholder={placeholder}
                        onChange={id => {
                            this.setState({ selectValue: id });
                            console.log(id);
                            if (storeAsString) {
                                let map = '';
                                id.forEach(r => {
                                    if (map) map += ',';
                                    map += r.value;
                                });
                                onChange(map, id);
                            } else {
                                onChange(id ? id.value : null, id);
                            }
                        }}
                        style={{ width: '100%' }}
                    />
                )}</>}
                {isSelectMultiple && <MultiSelect 
                    options={multiSelectOptions}
                    selected={currentSelect}
                    onSelectedChanged={id => {
                        this.setState({ selectValue: id }, () => {
                            if (storeAsString) {
                                // let map = '';
                                // id.forEach(r => {
                                //     if (map) map += ',';
                                //     map += r.value;
                                // });
                                // onChange(map, id);
                                const str = id.join(',');
                                onChange(str, id);
                            } else if(storeAsStringAndFull) {
                                const str = id.join(',');
                                const full = multiSelectOptions.filter(option => id.indexOf(option.value) !== -1);
                                onChange(str, id, full);
                            } else {
                                const full = multiSelectOptions.filter(option => id.indexOf(option.value) !== -1);
                                onChange(id, full);
                            }
                        })
                    }}
                    overrideStrings={{
                        selectSomeItems: t('Select people...'),
                        allItemsAreSelected: t('All members are selected'),
                        selectAll: t('Select All'),
                        search: t('Search'),
                    }}
                />}
            </div>
        );
    }
}

@observer
export class ManagersWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="managers" />;
    }
}

@observer
export class MembersWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="members" />;
    }
}

@observer
export class MembersWidgetMultiple extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} storeAsString isMulti mode="members" />;
    }
}

@observer
export class MembersWidgetMultipleSelect extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} storeAsStringAndFull isSelectMultiple mode="members" />;
    }
}

@observer
export class AllUsersWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="both" />;
    }
}

@observer
export class AllUsersInCompanyWidget extends Component {
    render() {
        return <UsersWidgetMobx {...this.props} mode="everyone" />;
    }
}
