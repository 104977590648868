import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import ModalEditor from '../Timelogs/ModalAccept';

import VacationsTotals from './VacationsTotals';
import VacationListFilter from './VacationFilter';

import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import { statusMap } from '~/utils/normalStatuses';
import { serverTimeFormat } from '~/library/core';

// Elements

import customerFeature from '~/utils/customerFeature';
import { minutesToTimeInput } from '~/utils/timeCalc';

@inject('vacationStore', 'userStore', 'timelogStore')
@withRouter
@withLocalization
@observer
class VacationsList extends Component {
    state = {
        accepting: null,
        acceptanceChanged: false,
        selected: null,
    };

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    renderTableActionButtons = (id, full) => {
        const { t } = this.props;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting');
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = statusMap[statusText] || '';
        return (
            <div className="actions-center">
                {customerFeature('allow_accepting') && (
                    <div
                        className={`status-block status-${full.status || 'active'}${
                            user_type === 'member' ? ' status-no-click-for-member' : ''
                        }`}
                        onClick={() => (showStatus ? this.handleAccept(id, full) : null)}
                        style={{ marginTop: '-4px', lineHeight: '10px' }}
                    >
                        {t(statusText)}
                        <br />
                        <small>
                            <small>
                                {this.props.commonStore.getUsername(full.status_changed_by)}
                                <br />
                                {full.status_changed_by && moment(new Date(full.status_changed_on)).format(serverTimeFormat())}
                            </small>
                        </small>
                    </div>
                )}
                <Button onClick={() => this.handleTableButtonAction(id, 'edit')} bsStyle="warning" simple icon>
                    <i className="fa fa-edit" />
                </Button>
                {allow && (
                    <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>
                )}
            </div>
        );
    };

    handleAccept(id, full) {
        if (full.status == 'approved') return;
        this.setState({
            accepting: {
                ids: [id],
                count: 1,
                entity: 'vacation',
            },
        });
    }

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    onMassAccept() {
        const ids = [];
        if (!this.state.selected) return;
        Object.keys(this.state.selected).forEach(id => {
            if (this.state.selected[id]) ids.push(id);
        });
        this.setState({
            acceptanceChanged: false,
            accepting: {
                ids,
                count: ids.length,
                entity: 'vacation',
            },
        });
    }

    afterMassAccept() {
        this.props.vacationStore.resetLastListLoadTime();
        this.setState({ accepting: null, acceptanceChanged: true });
    }

    render() {
        const { t } = this.props;

        const {
            loading, filters, appliedFilters, lastListLoadTime
        } = this.props.vacationStore;
        const { user_type } = this.props.userStore.currentUser;
        const { accepting, acceptanceChanged } = this.state;
        const showStatus = user_type === 'pm' && customerFeature('allow_accepting');

        return (
            <Fragment>
                <Modal size="lg" show={!!accepting} onHide={() => this.setState({ accepting: null })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Accept/reject')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor params={accepting} afterSave={() => this.afterMassAccept()} />
                    </Modal.Body>
                </Modal>
                {(user_type === 'member' || user_type === 'pm') && <VacationsTotals />}
                <div className="table-list">
                    <div className="table-list__header">
                        <Button fill wd icon onClick={() => this.props.onAdd()}>
                            {this.props.t('Add new')} <i className="fa fa-plus" />
                        </Button>
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'From date',
                                id: 'from',
                                sortable: true,
                                Cell: ({ original }) => moment(new Date(original.from)).format(serverTimeFormat()),
                            },
                            {
                                Header: 'To date',
                                id: 'to',
                                sortable: true,
                                Cell: ({ original }) => moment(new Date(original.to)).format(serverTimeFormat()),
                            },
                            user_type === 'member'
                                ? null
                                : {
                                    Header: 'Member',
                                    id: 'last_name',
                                    accessor: ({ first_name, last_name }) => (
                                        <span>{`${first_name} ${last_name}`}</span>
                                    ),
                                },
                            {
                                Header: 'Total days',
                                id: 'total_days',
                                accessor: ({ total_days }) => <span>{total_days}</span>,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: full => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        filters={appliedFilters}
                        onSelectionChanged={selection => this.onSelectionChanged(selection)}
                        allowCheckboxes={showStatus}
                        lastListLoadTime={lastListLoadTime}
                        header={(
                            <VacationListFilter
                                filters={filters}
                                setFilter={(name, value) => this.props.vacationStore.setFilter(name, value)}
                                onFilter={() => this.props.vacationStore.onFilter()}
                                loading={loading}
                                onChangeStatus={() => this.onMassAccept()}
                                showStatus={showStatus}
                            />
                        )}
                        requestData={params => this.props.vacationStore.loadList(params)}
                    />
                </div>
            </Fragment>
        );
    }
}
export default VacationsList;
