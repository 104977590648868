import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { observer } from 'mobx-react';

import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';

import './ConversationList.css';

@observer
export default class ConversationList extends Component {
    render() {
        const {
            rooms, t, allowModification, currentRoomId, unreads
        } = this.props;
        return (
            <div className="conversation-list">
                <Toolbar title={t('Projects')} />
                {/* leftItems={[
        <ToolbarButton key="cog" icon="ion-ios-cog" />
      ]}
        rightItems={[
        <ToolbarButton key="add" icon="ion-ios-add-circle-outline" />
      ]}
        <div className="pull-right">
          {allowModification && <Button onClick={()=>this.props.editRoom(0)}><i className="fa fa-plus"/></Button>}
        </div>
        */}
                {rooms.map(room => (
                    <ConversationListItem
                        currentRoomId={currentRoomId}
                        unreads={unreads}
                        key={room.id}
                        data={room}
                        changeRoom={id => this.props.changeRoom(id)}
                        editRoom={id => this.props.editRoom(id)}
                        allowModification={allowModification}
                    />
                ))}
            </div>
        );
    }
}
