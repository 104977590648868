import { action, observable } from 'mobx';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';

import { getMinutesFromTimeInput, minutesToTimeInput } from '../../utils/timeCalc';
import agent from '../agent';

import userStore from './userStore';
import commonStore from './commonStore';

// const timelogInitial = {
//     task_id: null,
//     workplace: '',
//     from: '08:00',
//     to: '16:00',
//     break: 0,
//     total_hours_normal: '08:00',
//     total_hours_overtime: '00:00',
//     for_date: new Date(),
//     data: {},
//     distance: localStorage.getItem('last_km_filled') || 0,
//     signature: null,
//     Attachments: [],
// };
const timelogInitial = {
    task_id: null,
    workplace: '',
    from: '08:00',
    to: '16:00',
    break: 0,
    total_hours_normal: '08:00',
    total_hours_overtime: '00:00',
    for_date: new Date(),
    data: {},
    distance: sessionStorage.getItem('last_km_filled') || 0,
    signature: null,
    Attachments: [],
};

class TimelogStore {
    @observable currentList = [];

    @observable currentEntity = timelogInitial;

    @observable originalEntity = timelogInitial;

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @observable allLocations = [];

    @action resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    @observable ui = {
        forDateWarning: false,
        wrongSubmit: false,
    };

    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action massPushNotification(ids, comment, action) {
        const users = this.currentList.rows.filter(row => ids.indexOf(`${row.id}`) >= 0).map(row => row.user_id);
        const uniqueUsers = [ ...(new Set(users)) ];
        return Promise.all(uniqueUsers.map(id => agent.Timelogs.sendMassStatusPushNotification(id, comment, action)));
    }

    @action singlePushNotification(id, comment, action) {
        return agent.Timelogs.sendSingleStatusPushNotification(id, comment, action);
    }

    @action loadList(params) {
        // console.log("list load",params);
        return agent.Timelogs.list(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action changeEntity(name, value, kind) {
        const entity = Object.assign({}, this.currentEntity);
        const nameArray = name.split('.');
        if (nameArray.length === 2) {
            const [firstProp, secondProp] = nameArray;
            entity[firstProp] = entity[firstProp] || {};
            entity[firstProp][secondProp] = value;
        } else {
            entity[name] = value;
        }
        this.currentEntity = entity;
        if (name === 'from' || name === 'to' || name === 'break') {
            this.calcTotalTime();
        }
        if (kind === 'overtime') {
            this.calcOvertimes();
        }
    }

    @action calcTotalTime() {
        const entity = Object.assign({}, this.currentEntity);
        const fromMinutes = getMinutesFromTimeInput(entity.from);
        const endMinutes = getMinutesFromTimeInput(entity.to);
        const breakMinutes = entity.break || 0; // Math.round(entity.break / 15) * 15;
        entity.breakMinutes = breakMinutes;
        let totalMinutes = endMinutes - fromMinutes;
        if (endMinutes < fromMinutes) {
            // && fromMinutes > (12 * 60)
            // this is probably 12-h shift
            totalMinutes += 24 * 60;
        }
        if (totalMinutes > breakMinutes) {
            totalMinutes -= breakMinutes;
        } else {
        }
        const totalResultPure = minutesToTimeInput(totalMinutes, 'full');
        entity.total_hours_overall = totalResultPure.positive ? totalResultPure.value : '00:00';
        totalMinutes -= getMinutesFromTimeInput(entity.total_hours_overtime);
        const totalResult = minutesToTimeInput(totalMinutes, 'full');
        entity.total_hours_normal = totalResult.positive ? totalResult.value : '00:00';
        this.currentEntity = entity;
    }

    @action calcOvertimes() {
        const entity = Object.assign({}, this.currentEntity);
        let overtime = 0;
        Object.keys(entity.data.overtimes).forEach((key) => {
            const over = entity.data.overtimes[key];
            overtime += getMinutesFromTimeInput(over.value); // * ((over.multiplier + 100) / 100);
        });
        overtime = minutesToTimeInput(overtime, 'full');
        entity.total_hours_overtime = overtime.positive ? overtime.value : '00:00';
        this.currentEntity = entity;
        this.calcTotalTime();
    }

    @action returnDefaultNew(params) {
        const t = _.cloneDeep(timelogInitial);
        const clientConfig = commonStore.config.client.data;
        // t.for_date = userStore.currentUser.timelog_last_filled ? moment(new Date(userStore.currentUser.timelog_last_filled)).add(1, 'days').toDate() :
        t.for_date = this.currentEntity.for_date;
        if (moment(t.for_date).isAfter(moment(new Date()))) {
            t.for_date = this.currentEntity.for_date;
        }
        t.user_id = userStore.currentUser.id;
        t.from = clientConfig.basicRules.startTimeRules.start;
        t.to = clientConfig.basicRules.startTimeRules.end;
        t.break = clientConfig.basicRules.breakTimeRules.minutes;
        t.data.address = ''; // userStore.currentUser.address;
        t.data.workplace = '';
        this.currentEntity = t;
        this.loading = false;
    }

    prepareLoadedEntity(loadedData) {
        if (!loadedData.data) {
            loadedData.data = {};
        }
        loadedData.distance = loadedData.data.distance || loadedData.distance;
        loadedData.for_date = new Date(loadedData.for_date);
        loadedData.from = minutesToTimeInput(loadedData.from, false);
        loadedData.to = minutesToTimeInput(loadedData.to, false);
        loadedData.total_hours_normal = minutesToTimeInput(loadedData.total_hours_normal, false);
        loadedData.total_hours_overtime = minutesToTimeInput(loadedData.total_hours_overtime, false);
        const breakMinutes = loadedData.break || 0; // Math.round(loadedData.break / 15) * 15;
        // console.log(loadedData);
        loadedData.break = breakMinutes;

        return loadedData;
    }

    @action load(id) {
        this.loading = true;
        return agent.Timelogs.load(id)
            .then(
                action((response) => {
                    const timelog = this.prepareLoadedEntity(response.timelog);
                    this.currentEntity = {
                        ...timelogInitial,
                        ...timelog,
                    };
                    this.originalEntity = {
                        ...timelogInitial,
                        ...timelog,
                    };
                    this.calcTotalTime();
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action saveAndOverrideCurrentEntity(values, isAdd, intermediate) {
        this.currentEntity = JSON.parse(JSON.stringify(values));
        return this.save(values, isAdd, intermediate);
    }

    @action save(values, isAdd, intermediate) {
        class ValidationError extends Error {
            constructor(message) {
                super(message); // (1)
                this.name = 'ValidationError'; // (2)
            }
        }

        // localStorage.setItem('last_km_filled', this.currentEntity.distance);
        sessionStorage.setItem('last_km_filled', this.currentEntity.distance);
        // this.currentEntity.data.distance = this.currentEntity.distance;
        console.log('Entity For Save', this.currentEntity);

        this.currentEntity.intermediateSave = !!intermediate;

        if (!intermediate && !this.currentEntity.description) {
            this.updating = false;
            this.updatingErrors = { message: 'Comment is required' };
            throw new ValidationError('Comment is required');
            // return Promise.reject({message: 'Comment is required'});
        }
        if (!intermediate && !this.currentEntity.task_id) {
            this.updating = false;
            this.updatingErrors = { message: 'Task is required' };
            throw new ValidationError('Task is required');
            // return Promise.reject({message: 'Comment is required'});
        }

        if (intermediate) {
            return agent.Timelogs.save(this.currentEntity, false).then(
                action((tl) => {
                    return tl;
                })
            );
        }

        this.updating = true;
        const user = userStore.currentUser;

        const entity = _.cloneDeep(this.currentEntity);
        if (entity.status === 'draft') entity.status = 'active';
        entity.for_date = moment(entity.for_date).format('YYYY-MM-DD');
        if (!entity.id) isAdd = true;
        return agent.Timelogs.save(entity, isAdd)
            .then(
                action((tl) => {
                    // this.currentEntity = tl;
                    if (tl.timelog.user_id === user.id) {
                        userStore.pullUser();
                    }
                    return tl;
                })
            )
            .catch((err) => {
                // this.updating = false;
                // this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                throw err;
            });
    }

    saveAsHoliday(from, to) {
        const user = userStore.currentUser;
        const entity = {
            from,
            to,
        };
        return agent.Timelogs.saveHolidays(entity, true)
            .then(
                action((tl) => {
                    // this.currentEntity = tl;
                    if (tl.timelog.user_id === user.id) {
                        userStore.pullUser();
                    }
                    return tl;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.Timelogs.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    @action async setStatus(id, entityName, status, _data = {}) {
        const data = Object.assign({}, _data);
        data.status = status;
        return agent.Timelogs.setStatus(entityName, id, data);
    }

    @action async getPossibleDraftId(autostart) {
        const r = await agent.Timelogs.getDraftId(autostart);
        if (autostart) {
            return r.timelog;
        }
        return r.timelog.id;
    }

    @action async getCurrentDraftOrSkip(forceProject = '', user = '', onlyValid = false) {
        const r = await agent.Timelogs.getCurrentDraftOrSkip(forceProject, user, onlyValid);
        return r;
    }

    @action async getCurrentDraftForLocked(forceProject = '', user = '') {
        const r = await agent.Timelogs.getCurrentDraftForLocked(forceProject, user);
        return r;
    }

    @action async finishByButton(timelog, data) {
        const response = await agent.Timelogs.finishByButton(timelog.id, data);
        this.load(timelog.id);
        return response.timelog;
    }

    @action async reportInjury(timelog, data) {
        const response = await agent.Timelogs.reportInjury(timelog.id, data);
        this.load(timelog.id);
        return response.timelog;
    }

    @action async startByButton(id, data) {
        const r = await agent.Timelogs.startByButton(id, data);
        this.load(id);
    }

    @action setManualDefaults() {
        this.currentEntity.tracker_status = 'manual';
        this.currentEntity.status_note = ''; //'SUSPECT'
        const clientConfig = commonStore.config.client.data;

        this.currentEntity.from = clientConfig.basicRules.startTimeRules.start;
        this.currentEntity.to = clientConfig.basicRules.startTimeRules.end;
        this.currentEntity.break = clientConfig.basicRules.breakTimeRules.minutes;
        this.calcTotalTime();
    }

    @action switchToManual() {
        if (this.currentEntity.gps_data) {
            this.currentEntity.gps_data.start = { manual: true };
            this.currentEntity.gps_data.end = { manual: true };
        }
        this.currentEntity.tracker_status = 'manual';
    }

    @action async breakByButton(timelog, action, reload) {
        const response = await agent.Timelogs.breakByButton(timelog, action);
        if (reload) {
            this.load(timelog);
        }
        return response.timelog;
    }

    @action async getAllLocations(params) {
        this.loading = true;
        const response = await agent.Timelogs.getAllLocations(params);
        this.allLocations = response;
        this.loading = false;
        return response;
    }

    @action async convertLogToPublic(data){
        this.updating = true;
        return await agent.Timelogs.convertLogToPublic(data)
        .then(
            action(response=>{
                this.updating = true;
                return response;
            })
        )
        .catch(
            action((err)=>{
                this.updating = false;
                this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                throw err;
            })
        );     
    }
}

export default new TimelogStore();
