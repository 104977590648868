import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

import i18n from '../i18n';

import commonStore from './stores/commonStore';
import authStore from './stores/authStore';
import config from './config';
import request from 'superagent';

const { API_ROOT } = config;
const superagent = superagentPromise(_superagent, global.Promise);

const handleErrors = (err, response, skipErrors) => {
    if (!err) {
        // no error.
        return null;
    }
    // console.log('HE', JSON.stringify(err));
    if (err && err.response && err.response.status === 401) {
        // authStore.logout();
        if (commonStore) commonStore.addNotification('Authentication error', null, 'error');
        return new Error('Authentication error');
    }
    let text = 'Unknown error connecting to the server';
    console.log(err);
    if (err && response && response.body && response.body.error && typeof response.body.error === 'string') {
        text = response.body.error;
    }
    if (
        err &&
        err.response &&
        err.response.body &&
        err.response.body.errors &&
        err.response.body.errors.error &&
        typeof err.response.body.errors.error === 'string'
    ) {
        text = err.response.body.errors.error;
    }
    if (err && err.response && err.response.body && err.response.body.errors && err.response.body.errors.message) {
        text = err.response.body.errors.message;
    }
    console.log('ERR', err, response);
    if (commonStore && !skipErrors) commonStore.addNotification(i18n.t(text), null, 'error');
    return new Error(text);
};

const responseBody = (res) => res.body;

const tokenPlugin = (req) => {
    if (commonStore.token) {
        req.set('authorization', `Token ${commonStore.token}`);
    }
};

const convertToGetParams = (params) =>
    Object.keys(params).reduce((total, currentValue, currentIndex, arr) => {
        let param = JSON.stringify(params[currentValue]);
        if (!param) {
            return total;
        }
        if (param.indexOf('{') < 0 && param.indexOf('[') < 0) param = param.replace('"', '').replace('"', '');
        return `${total}${currentValue}=${param}&`;
    }, '?');

const requests = {
    del: (url) => superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).end(handleErrors).then(responseBody),
    get: (url, skipErrors) =>
        superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r, skipErrors))
            .then(responseBody),
    put: (url, body, skipErrors) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r, skipErrors))
            .then(responseBody),
    post: (url, body, skipErrors) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end((e, r) => handleErrors(e, r, skipErrors))
            .then(responseBody),
};

const Auth = {
    current: () => requests.get('/user'),
    loadById: (id) => requests.get(`/user/${id}`),
    config: () => requests.get('/config'),
    login: (email, password) => requests.post('/users/login', { user: { email, password } }),
    loginAs: (id) => requests.get(`/users/loginas/${id}`, true),
    register: (values) =>
        requests.post('/users', {
            user: values,
        }),
    save: (user, type = 'basic') => requests.put('/user', { user, type }),
    log: (log) => requests.post('/log', { log }),
    resetPassword: (email) => requests.put('/reset_password', { email }),
    upload: (images) => requests.post('/images', { images }),
    setLang: (lang) => requests.post('/user/lang', { lang }),
    activeUser: id => requests.get(`/users/member_active/${id}`),
};

const BizTypes = {
    list: (params) => requests.get(`/biztypes${convertToGetParams(params)}`),
    load: (id) => requests.get(`/biztypes/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/biztypes/', values) : requests.put(`/biztypes/${values.id}`, values),
    remove: (id) => requests.del(`/biztypes/${id}`),
    getBizTypeDocumentsById: (id) => requests.get(`/biztype_documents/biz/${id}`),
    saveBizTypeDocuments: (data) => requests.post('/biztype_documents',data),
};

const Banners = {
    list: (params) => requests.get(`/banners${convertToGetParams(params)}`),
    save: (values) => requests.post('/banners/', values),
    getBannerById: (id) => requests.get(`/banners/getById/${id}`),
    sendBannerIds: (ids) =>requests.post(`/banner_readers/`,ids),
    removeBanner: (id) => requests.del(`/banners/${id}`),
}

const Clients = {
    list: (params) => requests.get(`/clients${convertToGetParams(params)}`),
    load: (id) => requests.get(`/clients/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/clients/', values) : requests.put(`/clients/${values.id}`, values),
    remove: (id) => requests.del(`/clients/${id}`),
};

const Users = {
    list: (params) => requests.get(`/admins${convertToGetParams(params)}`),
    load: (id) => requests.get(`/admins/${id}`),
    save: (values, isAdd) => (isAdd ? requests.post('/admins/', values) : requests.put(`/admins/${values.id}`, values)),
    remove: (id) => requests.del(`/admins/${id}`),
    uploadImage: (params) => requests.post('/attachments', params),
    lookupByName: (mode, name) => requests.get(`/admins/lookup_users?name=${name}&mode=${mode}`),
    sendReport: (params) => requests.get(`/dashboard/send_report${convertToGetParams(params)}`),
    getReportInfo: (params) => requests.get(`/dashboard/report_info${convertToGetParams(params)}`),
    clearNotifications: () => requests.get('/user/clear-notifications'),
    getNotifications: () => requests.get('/user/notifications'),
    gitRev: () => requests.get('/git-rev'),
    validateNewUser: (params) => requests.post('/admins/validate', params),
    validateAddPossible: () => requests.get('/admins/validate_add_possible'),
    increaseUserLimit: () => requests.get('/admins/increase_user_limit'),
    byProjectId: (project_id) => requests.get(`/user/byprojectid/${project_id}`),
   
};


const UserAccess = {
    list: (params) => requests.get(`/user_data_access${convertToGetParams(params)}`),
    loadPmMembers: (id) => requests.get(`/pm_members/${id}`),
    loadAllowedPms:() => requests.get(`/user_data_access/getAllowedPMs`),
    savePmMembers: (values) => requests.post('/user_data_access/',values),
    approveAccess: (id,data) => requests.put(`/user_data_access/update_permission_status/${id}`,data),
}

const Dashboard = {
    get: (params) => requests.get(`/dashboard${convertToGetParams(params)}`),
    contact: (params) => requests.put('/contact_email', params),
};

const Projects = {
    list: (params) => requests.get(`/projects${convertToGetParams(params)}`),
    load: (id) => requests.get(`/projects/${id}`),
    getProjectMembersIds: (id) => requests.get(`/projects/projectmemberids/${id}`),
    getProjectPms: (id) => requests.get(`/projects/getProjectPms/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/projects/', values) : requests.put(`/projects/${values.id}`, values),
    remove: (id, confirm) => requests.del(`/projects/${id}?confirm=${confirm}`),
    lookupByName: (mode, name, _module) => requests.get(`/projects/lookup_projects?name=${name}&mode=${mode}&module=${_module}`),
};

const Tasks = {
    list: (params) => requests.get(`/tasks${convertToGetParams(params)}`),
    load: (id) => requests.get(`/tasks/${id}`),
    save: (values, isAdd) => (isAdd ? requests.post('/tasks/', values) : requests.put(`/tasks/${values.id}`, values)),
    remove: (id) => requests.del(`/tasks/${id}`),
    lookupByName: (name) => requests.get(`/tasks/lookup_tasks?name=${name}`),
    gpsAddress: (address) => requests.get(`/tasks/geolocation?address=${encodeURI(address)}`),
};

const Chat = {
    loadRooms: () => requests.get('/chat/rooms'),
    loadRoom: (id) => requests.get(`/chat/room/${id}`),
    loadMessages: (id) => requests.get(`/chat/messages/${id}`),
    saveRoom: (values, isAdd) =>
        isAdd ? requests.post('/chat/rooms', values) : requests.put(`/chat/room/${values.room.id}`, values),
    removeRoom: (id) => requests.del(`/chat/rooms/${id}`),
};

const Timelogs = {
    list: (params) => requests.get(`/timelogs${convertToGetParams(params)}`),
    load: (id) => requests.get(`/timelogs/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/timelogs/', values) : requests.put(`/timelogs/${values.id}`, values),
    remove: (id) => requests.del(`/timelogs/${id}`),
    saveHolidays: (values) => requests.post('/timelogs/holidays', values),
    setStatus: (entityName, id, data) => requests.put(`/timelogs/status/${entityName}/${id}`, data),
    presenceList: (params) => requests.get(`/presences${convertToGetParams(params)}`),
    getDraftId: (autostart) => requests.get(`/timelogs/draft_id${convertToGetParams(autostart || {})}`),
    getCurrentDraftOrSkip: (forceProject, user, onlyValid) =>
        requests.get(
            `/timelogs/current_draft_or_skip?forceProject=${forceProject}&user=${user}&onlyValid=${onlyValid}`
        ),
    getCurrentDraftForLocked: (forceProject, user) =>
        requests.get(`/timelogs/current_draft_for_locked?project=${forceProject}&user=${user}`),
    finishByButton: (id, data) => requests.put(`/timelogs/finish/${id}`, data || {}),
    reportInjury: (id, data) => requests.put(`/timelogs/reportInjury/${id}`, data),
    breakByButton: (id, action) => requests.put(`/timelogs/break/${id}/${action}`, {}),
    startByButton: (id, data) => requests.put(`/timelogs/start/${id}`, data),
    getAllLocations: (params) => requests.get(`/timelogs/all_locations${convertToGetParams(params)}`),
    sendMassStatusPushNotification: (userId, comment, action) => requests.post(`/timelogs/multiplePush/${userId}`, { comment, action }),
    sendSingleStatusPushNotification: (timelogId, comment, action) => requests.post(`/timelogs/singlePush/${timelogId}`, { comment, action }),
    convertLogToPublic: (data) => requests.post('/timelogs/convertLogToPublic',data)
};

const Vacations = {
    list: (params) => requests.get(`/vacations${convertToGetParams(params)}`),
    load: (id) => requests.get(`/vacations/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/vacations/', values) : requests.put(`/vacations/${values.id}`, values),
    remove: (id) => requests.del(`/vacations/${id}`),
};

const Absences = {
    list: (params) => requests.get(`/absences${convertToGetParams(params)}`),
    load: (id) => requests.get(`/absences/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/absences/', values) : requests.put(`/absences/${values.id}`, values),
    remove: (id) => requests.del(`/absences/${id}`),
};

const Deviation = {
    list: (params) => requests.get(`/deviations${convertToGetParams(params)}`),
    load: (id) => requests.get(`/deviations/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/deviations/', values, true) : requests.put(`/deviations/${values.id}`, values, true),
    remove: (id) => requests.del(`/deviations/${id}`),
    transition: ({ id, transitionName, transitionData }) =>
        requests.put(`/deviations/${id}/transition/${transitionName}`, transitionData, true),
    sendEmail: (id, email) => requests.put(`/deviations/${id}/email`, { email }, true),
};

const ExtraDoc = {
    list: (params) => requests.get(`/extradocs${convertToGetParams(params)}`),
    load: (id) => requests.get(`/extradocs/${id}`),
    save: (values, isAdd) =>
        isAdd ? requests.post('/extradocs/', values, true) : requests.put(`/extradocs/${values.id}`, values, true),
    remove: (id) => requests.del(`/extradocs/${id}`),
};

const Locked = {
    startLock: (data) => requests.post('/locked/pm?start=true', data, true),
    endLock: (data) => requests.post('/locked/pm?start=false', data, true),
    loginWithPin: (data) => requests.post('/locked/loginwithpin', data, true),
    // makeAction: (id, data) => requests.put(`/locked/action/${id}`, data),
    loadList: (params) => requests.get(`/locked/list${convertToGetParams(params)}`),
    sendReportByMail: (params) => requests.get(`/locked/send${convertToGetParams(params)}`),
    sendSms: (params) => requests.get(`/locked/sendsms${convertToGetParams(params)}`, true),
};

// const Schedule = {
//     list: (params) => requests.get(`/schedule${convertToGetParams(params)}`),
//     load: (id) => requests.get(`/schedule/${id}`),
//     save: (values, isAdd) =>
//         isAdd ? requests.post('/schedule/', values) : requests.put(`/schedule/${values.id}`, values),
//     addBulkShift: (values) => requests.post('/schedule/bulk-shift/', values),
//     remove: (id) => requests.del(`/schedule/${id}`),
//     loadResources: () => requests.get('/schedule/resources'),
//     move: (data) => requests.put('/schedule/move', data),
//     copy: (data) => requests.put('/schedule/copy', data),
//     listWeekly: () => requests.get('/schedule/weekly'),
//     copyToNextWeek: (data) => requests.put('/schedule/copy_to_next', data),
//     copyMonth: (data) => requests.put('/schedule/copy_month', data),
//     memberAction: (id, action, data) => requests.put(`/schedule/${id}/memberaction/${action}`, data),
// };


// const checklist={
//     listelementbyclientid: (params) => requests.get(`/elements/client`),
//     addelement: (data) => requests.post('/elements',data,true),
//     getElementById: (id) => requests.get(`/elements/${id}`),
//     deleteElementById : (id) => requests.post(`/elements/delete/${id}`),
//     getMultipleAttachments: (data) => requests.post('/attachments/getmultiple', data),
    
//     searchElement: (data) => requests.post('/elements/search', data, true),
//     getElementChildByParentId: (data) => requests.post('/elements/child', data, true),
//     copyElementById: (data) => requests.post('/elements/copy', data, true),

//     getChecklistTemplateByClientId: (params) => requests.get(`/checklists${convertToGetParams(params)}`),
//     getChecklistTemplateByClientIdPagination: (params) => requests.get(`/elements/cpage/${convertToGetParams(params)}`),
//     addChecklistTempalte: (data) => requests.post('/checklists',data,true),
//     getChecklistTemplateById: (id) => requests.get(`/checklists/${id}`),
//     deleteChecklistTemplateById : (id) => requests.del(`/checklists/${id}`),
//     deleteChecklistById : (id) => requests.del(`/userchecklists/${id}`),
//     assignChecklist : (data) => requests.post('/userchecklists/assign',data,true),
//     getChecklistById : (id) => requests.get(`/userchecklists/get/${id}`),
//     lookupByName: (data) => requests.post(`/checklists/lookup_checklist`,data,true),
//     getChecklists: (params) => requests.get(`/userchecklists${convertToGetParams(params)}`),
//     checkChecklistElement: (data) => requests.post(`/userchecklists/check`,data,true),
//     checkAllChecklist: (data) => requests.post(`/userchecklists/checkall`,data,true),
//     approveChecklist : (data) => requests.post('/userchecklists/approve',data,true),
//     report : (type) => requests.post(`/userchecklists/report/${type}`),
//     deviationChecklist : (data) => requests.post('/userchecklists/deviation',data,true),
// }

const documents = {
    //HTTP methods for documents//
    getDocuments: (params) => requests.get(`/documents/page${convertToGetParams(params)}`),
    getDocumentsList: (params) => requests.get(`/documents/list`),
    getDocumentById: (id) => requests.get(`/documents/getById/${id}`),
    addDocument: (data) => requests.post('/documents',data,true),
    copyDocument: (data) => requests.post('/documents/copy',data,true),
    deleteDocumentById: (id) => requests.del(`/documents/${id}`),    
    getTemplateDocuments: (params)=> requests.get(`/documents/getTemplateDocs${convertToGetParams(params)}`),
    getAdminDocuments:(params)=> requests.get(`/documents/admin/list`), 
    getPDFDataByAttachmentId:(id)  => requests.get(`/attachments/filedata/${id}`),

    //HTTP methods for document_template//
    getDocumentTemplates: (params) => requests.get(`/document_templates${convertToGetParams(params)}`),
    getDocumentTemplateById: (id) => requests.get(`/document_templates/getById/${id}`),
    addDocumentTemplate: (data) => requests.post('/document_templates',data,true),
    deleteDocumentTemplateById: (id) => requests.del(`/document_templates/${id}`),    
    getDocumentsInTemplate: (id) => requests.get(`/document_templates/getDocs/${id}`),
    getDocumentsInTemplateInSelectedOrder: (id) => requests.get(`/document_templates/getDocsByOrder/${id}`),
    getMultipleDoumentsByIds: (params) => requests.get(`/document_templates/getMultipleDoumentsByIds${convertToGetParams(params)}`),
    getDocumentReportByTemplateId: (id) => requests.get(`/document_templates/report/${id}`),
    getDocumentReportPreviewByTemplateId: (id) => requests.get(`/document_templates/reportPreview/${id}`),   
    getmultipleDocumentsPdfPreviewUrl: (params) => requests.get(`/document_templates/multipleDocumentsPdfPreview${convertToGetParams(params)}`),   
    getDocumentTemplateAssignList:(id) => requests.get(`/document_templates/getTemplateAssignList/${id}`),

    //HTTP methods for document_read//    
    addDocumentRead: (data) => requests.post('/document_read',data),
    sendDocumentReadMailToMembers: (data) => requests.post('/document_read/sendMailsToMembers',data),
    updateDocumentReadStatus:(data) => requests.post('/document_read/edit',data),
    assignDocumentTemplatesToUser:(data) => requests.post('/document_read/assignDocumentTemplatesToUser',data),
}

const LeaveDetailsStore = {
    save: (data) => requests.post('/leave_details/',data,true),    
}


const visitors = {
    //HTTP methods for visitors//
    getVisitors: (params) => requests.get(`/visitors${convertToGetParams(params)}`),    
    getVisitorsReport: (params) => requests.get(`/visitors/report${convertToGetParams(params)}`),    
    getVisitorById: (id) => requests.get(`/visitors/getById/${id}`),
    addVisitor: (data) => requests.post('/visitors',data,true),    
    addVisitorPublic: (data) => requests.post('/visitors/visitorRegister',data,true),    
    addClientVisitorPublic: (data) => requests.post('/visitors/visitorClientRegister',data,true),    
    verifyClientVisitor: (data) => requests.post('/visitors/verifyClientRegister',data,true),    
    deleteVisitorById: (id) => requests.del(`/visitors/${id}`)    
}

export default {
    config,
    Auth,
    Clients,
    Users,
    BizTypes,
    Dashboard,
    Projects,
    Chat,
    Tasks,
    Timelogs,
    Vacations,
    Absences,
    Locked,
    Deviation,
    ExtraDoc,
    convertToGetParams,
    documents,
    visitors,
    LeaveDetailsStore,
    UserAccess,
    Banners
};
