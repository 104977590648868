import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react'

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import withLocalization from '~/hoc/withLocalization';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Row, Col } from 'react-bootstrap';
import MultiSelect from '@khanacademy/react-multi-select';
import AddBanner from './AddBanner';
import BannerDetails from './BannerDetails'
// import { Input } from '../../elem';

//styles
import '../../../styles/css/styles.css';
import Input from '../../../elements/Input.js';
import BannersList from './BannersList.js';

//this is the root component for documents//

@inject('bannerStore', 'authStore', 'userStore', 'commonStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class Banner extends Component {
    state = {
        
    }


    onAdd() {
        //redirects path to add banner//
        const { history } = this.props;
        history.push('/admin/banner/add');
    }

    onTableAction(id, type) {
        const { history,bannerStore, commonStore,t} = this.props;
        if (type === 'remove') {
                bannerStore.removeBanner(id).then(response=>{
                bannerStore.resetLastListLoadTime();
                commonStore.addNotification(t('Deleted'), null, 'error');
            })
            return;
        }
        history.push(`/admin/banner/${id}`);
    }


    editingId = () => {
        //sets mode on the basis of path//

        let id = null;
        if (this.props.location.pathname !== this.props.match.path) {
            id = this.props.location.pathname.replace(this.props.match.path, '');
            id = id.replace('/', '');
        }
        return { editing: !!id, add: id === 'add', id };
    };


    render() {
        const {t,userStore} = this.props;
        const{currentUser} = userStore;
        const mode = this.editingId();
        return (
            <div className={`main-content`}>
                 {!mode.editing && (
                    <BannersList    
                     onTableAction={(id, type) => this.onTableAction(id, type)} 
                     onAdd={() => this.onAdd()} 
                    />
                )}
                {/* {
                    mode.add ?(
                        <BannerDetails />
                    ):(
                        <AddBanner  id={currentUser.id} user_type={currentUser.user_type} />
                    )
                } */}
                {mode.editing && !mode.add && <BannerDetails id={mode.id} />}
                {mode.add && <AddBanner  id={currentUser.id} user_type={currentUser.user_type} />}
              
            </div>
        )
    }
}

export default Banner;