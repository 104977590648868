import React, { Component } from 'react';
import shave from 'shave';
import { observer } from 'mobx-react';

import './ConversationListItem.css';

@observer
export default class ConversationListItem extends Component {
    componentDidMount() {
        shave('.conversation-snippet', 20);
    }

    render() {
        const {
            photo, name, description, id
        } = this.props.data;
        const {
            changeRoom, editRoom, allowModification, currentRoomId, unreads
        } = this.props;
        const unread = unreads[id];
        // console.log('U..', id, unread);
        /* {allowModification && (
          <div className="conversation-action">
            <i className="fa fa-cog" onClick={()=>editRoom(id)}></i>
          </div>
        )} */
        const showUnread = !!(unread && unread !== '0');
        return (
            <div
                className={`conversation-list-item ${currentRoomId === id ? 'conversation-list-item-current' : ''}`}
                onClick={() => changeRoom(id)}
            >
                <i className="pe-7s-users conversation-photo" />
                <div className="conversation-info">
                    <h1 className="conversation-title">{name}</h1>
                    <p className="conversation-snippet">{description}</p>
                </div>
                {showUnread && <div className="conversation-badge">{unread}</div>}
            </div>
        );
    }
}
