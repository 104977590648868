import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import { NumberInput,Input } from '../../../../elements/Input';
import Checkbox from '../../../../components/CustomCheckbox/CustomCheckbox.jsx';
import Select from '../../../../elements/Select.js';
import SwitchWidget from '../SwitchWidget';

const approvalTimelogsOptions = [{ value: 'auto', label: 'Automatic', title: 'Automatic' }, { value: 'auto-no-overtime', label: 'Automatic if no overtimes', title: 'Automatic if no overtimes' }, { value: 'manual', label: 'manual', title: 'manual' }]

const approvalVacationsOptions = [{ value: 'auto', label: 'Automatic', title: 'Automatic' }, { value: 'manual', label: 'Manual', title: 'manual' }]

const approvalAbsentsOptions = [{ value: 'auto', label: 'Automatic', title: 'Automatic' }, { value: 'manual', label: 'manual', title: 'manual' }]


@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class General extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generalRules: {
                selfClarificationPeriod: 0,
                defaultHolidays: "",
                allowManualTimelogChange: false,
                allow_overtime_limit: false,
                displayCommentAfterFinish: false,
                // timelog_no_edit_for_approved: false,
                // timelog_no_edit_for_done: false,
                // timelog_hide_kilometers:false,
                // timelog_hide_other:false,
                // timelog_hide_extra:false,
                // show_times_on_project:false,
                // gps_enabled:false,
                // hide_send_report:false,
                allowQucikChecklist:false,
                quickCheckListText:'Did you get any injury at site today?',
                approvalAbsents: 'manual',
                approvalTimelogs: 'manual',
                approvalVacations: 'manual',
                maxHouresToEditWorkingHours:48,
                workinghourUserphoto:false,
                workinghourGPSObligatory:false,
                signatureModalAfterWorklog:false
            },
            defaultDays: [{ day: 'Mon', checked: false }, { day: 'Tue', checked: false },
            { day: 'Wed', checked: false }, { day: 'Thu', checked: false },
            { day: 'Fri', checked: false }, { day: 'Sat', checked: false },
            { day: 'Sun', checked: false }],
        }
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.loadData = this.loadData.bind(this);
        this.updatedWeekDays = this.updatedWeekDays.bind(this);
        this.parseWeekDays = this.parseWeekDays.bind(this);
        this.translateLabels = this.translateLabels.bind(this);
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { generalRules } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            generalRules.selfClarificationPeriod = basicRules.selfClarificationPeriod ? basicRules.selfClarificationPeriod : 16;
            generalRules.defaultHolidays = basicRules.defaultHolidays ? basicRules.defaultHolidays : "5,6";
            generalRules.allowManualTimelogChange = basicRules.allowManualTimelogChange ? basicRules.allowManualTimelogChange : false;
            generalRules.allow_overtime_limit = basicRules.allow_overtime_limit ? basicRules.allow_overtime_limit : false;
            generalRules.displayCommentAfterFinish = basicRules.displayCommentAfterFinish ? basicRules.displayCommentAfterFinish : false;
            // generalRules.timelog_no_edit_for_approved = basicRules.timelog_no_edit_for_approved ? basicRules.timelog_no_edit_for_approved : false;
            // generalRules.timelog_no_edit_for_done = basicRules.timelog_no_edit_for_done ? basicRules.timelog_no_edit_for_done : false;
            // generalRules.timelog_hide_kilometers = basicRules.timelog_hide_kilometers ? basicRules.timelog_hide_kilometers : false;
            // generalRules.timelog_hide_other = basicRules.timelog_hide_other ? basicRules.timelog_hide_other : false;
            // generalRules.timelog_hide_extra = basicRules.timelog_hide_extra ? basicRules.timelog_hide_extra : false;
            // generalRules.show_times_on_project = basicRules.show_times_on_project ? basicRules.show_times_on_project : false;
            // generalRules.gps_enabled = basicRules.gps_enabled ? basicRules.gps_enabled : false;
            // generalRules.hide_send_report = basicRules.hide_send_report ? basicRules.hide_send_report : false;
            generalRules.allowQucikChecklist = basicRules.allowQucikChecklist ? basicRules.allowQucikChecklist : false;
            generalRules.quickCheckListText = basicRules.quickCheckListText ? basicRules.quickCheckListText : 'Did you get any injury at site today?';
            generalRules.approvalAbsents = basicRules.approvalAbsents ? basicRules.approvalAbsents : 'manual';
            generalRules.approvalTimelogs = basicRules.approvalTimelogs ? basicRules.approvalTimelogs : 'manual';
            generalRules.approvalVacations = basicRules.approvalVacations ? basicRules.approvalVacations : 'manual';
            generalRules.maxHouresToEditWorkingHours = basicRules.maxHouresToEditWorkingHours ? basicRules.maxHouresToEditWorkingHours : 48;
            generalRules.workinghourUserphoto = basicRules.workinghourUserphoto ? basicRules.workinghourUserphoto : false;
            generalRules.workinghourGPSObligatory = basicRules.workinghourGPSObligatory ? basicRules.workinghourGPSObligatory : false;
            generalRules.signatureModalAfterWorklog = basicRules.signatureModalAfterWorklog ? basicRules.signatureModalAfterWorklog : false;

            this.setState({ generalRules },
                () => { this.updatedWeekDays() })
        }
        else {
            this.updatedWeekDays();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    translateLabels = () => {
        const { t } = this.props;
        approvalTimelogsOptions.map(item => {
            item.label = t(item.title)
        })
        approvalVacationsOptions.map(item => {
            item.label = t(item.title)
        })
        approvalAbsentsOptions.map(item => {
            item.label = t(item.title)
        })
    }

    handleTextChange = (name, value) => {
        const { generalRules } = this.state;
        generalRules[name] = value;
        this.props.handleUserDataChange(name, value);
        this.setState({ generalRules });
    }

    handleSwitch = (name, evt) => {
        const { generalRules } = this.state;
        generalRules[name] = evt;
        this.props.handleUserDataChange(name, evt);
        this.setState({ generalRules })
    }

    handleCheck = (index, value) => {
        const { generalRules } = this.state;
        let idsArray = [];
        idsArray = this.parseWeekDays();
        //based on value adds or removes from idsArray//
        if (value == true) {
            idsArray.push(index);
        } else {
            if (generalRules.defaultHolidays.includes(index)) {
                idsArray = idsArray.filter(function (item) {
                    return parseInt(item) !== index
                })
            }
        }
        generalRules.defaultHolidays = idsArray.toString();
        this.props.handleUserDataChange('defaultHolidays', idsArray.toString());
        this.setState({ generalRules }, () => {
            this.updatedWeekDays();
        });
    }

    //maps and parse days from weekdays//
    parseWeekDays = () => {
        const { generalRules } = this.state;
        let idsArray = []
        if (generalRules.defaultHolidays != null && generalRules.defaultHolidays != "") {
            idsArray = generalRules.defaultHolidays.split(",").map(function (item) {
                return parseInt(item, 10);
            });
        }
        return idsArray;
    }

    updatedWeekDays = () => {
        let idsArray = this.parseWeekDays();
        let updatedDays = this.state.defaultDays;
        updatedDays = this.state.defaultDays.map((item, index) => {
            if (idsArray.includes(index)) {
                item.checked = true;
                return item;
            } else {
                item.checked = false;
                return item;
            }
        })
        this.setState({ defaultDays: updatedDays });
    }

    render() {
        const { generalRules, defaultDays } = this.state;
        const { t } = this.props;
        this.translateLabels();
        return (
            <div>
                <Row>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('Self clarification period days')}`}
                            value={generalRules.selfClarificationPeriod}
                            onChange={evt => {
                                this.handleTextChange('selfClarificationPeriod', parseInt(evt.target.value, 10));
                            }}
                        />
                    </Col>
                    <Col sm={6}>
                        <NumberInput
                            label={`${t('Maximum hours to edit timelogs')}`}
                            value={generalRules.maxHouresToEditWorkingHours}
                            onChange={evt => {
                                this.handleTextChange('maxHouresToEditWorkingHours', parseInt(evt.target.value, 10));
                            }}
                        />
                    </Col>
                    
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={12}>
                        <label className="control-label">{t('Default holidays')}</label>
                        <div className="row-flex" style={{ overflowX: 'auto' }}>
                            {defaultDays.map((item, index) => (
                                <Checkbox
                                    inline
                                    key={`${index}`}
                                    isChecked={item.checked}
                                    onChange={evt => this.handleCheck(index, evt)}
                                    label={t(item.day)}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>

                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={6}>
                    <Input
                            label={`${t('Quick query text')}`}
                            value={generalRules.quickCheckListText}
                            onChange={evt => {
                                this.handleTextChange('quickCheckListText', evt.target.value);
                            }}
                          />  
                    </Col>                    
                </Row>

                <Row style={{ marginTop: '2rem' }}>
                <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.allowQucikChecklist}
                            onChange={checked => this.handleSwitch('allowQucikChecklist', checked)} />
                    </Col>
                <Col sm={10}>
                        <label className="control-label">{t('Show quick query in mobile app')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Show quick query in mobile app")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '2rem' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.allowManualTimelogChange}
                            onChange={checked => this.handleSwitch('allowManualTimelogChange', checked)} />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label">{t('Allow change timelog without switching to manual mode')}</label>
                        <OverlayTrigger 
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("PMs/Admins will not able to manage other shifts")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.allow_overtime_limit}
                            onChange={checked => this.handleSwitch('allow_overtime_limit', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Allow more then 9 hours without confirmation')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("PMs/Admins will not able to manage other shifts")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.displayCommentAfterFinish}
                            onChange={checked => this.handleSwitch('displayCommentAfterFinish', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Allow to display comment dialog after finish work')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("PMs/Admins will not able to manage other shifts")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.signatureModalAfterWorklog}
                            onChange={checked => this.handleSwitch('signatureModalAfterWorklog', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Show Signature Modal after finishing work in mobile app')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Show Signature Modal after finishing work in mobile app")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.workinghourUserphoto}
                            onChange={checked => this.handleSwitch('workinghourUserphoto', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Allow Capture live image of user when start working hours')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Allow Capture live image of user when start working hours")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.workinghourGPSObligatory}
                            onChange={checked => this.handleSwitch('workinghourGPSObligatory', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Make GPS obligatory for start/end time in mobile app, without GPS on workslogs cant be registered')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Make GPS obligatory for start/end time in mobile app, without GPS on workslogs cant be registered")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                {/* <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.timelog_no_edit_for_approved}
                            onChange={checked => this.handleSwitch('timelog_no_edit_for_approved', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Disable edit timelog when approved')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Disable edit timelog when approved")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.timelog_no_edit_for_done}
                            onChange={checked => this.handleSwitch('timelog_no_edit_for_done', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Disable edit timelog when not draft')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Disable edit timelog when not draft")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.timelog_hide_other}
                            onChange={checked => this.handleSwitch('timelog_hide_other', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Hide timelog other expenses')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Hide timelog other expenses")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.timelog_hide_extra}
                            onChange={checked => this.handleSwitch('timelog_hide_extra', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Hide timelog extra work')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Hide timelog extra work")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.timelog_hide_kilometers}
                            onChange={checked => this.handleSwitch('timelog_hide_kilometers', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Hide timelog kilometers')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Hide timelog kilometers")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.show_times_on_project}
                            onChange={checked => this.handleSwitch('show_times_on_project', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Show agreement times on project form')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Show agreement times on project form")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.gps_enabled}
                            onChange={checked => this.handleSwitch('gps_enabled', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('GPS enabled')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("GPS enabled")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.hide_send_report}
                            onChange={checked => this.handleSwitch('hide_send_report', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Hide send report')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Hide send report")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row> */}
               
                {/* <Row>
                    <Col sm={12}>
                    <Input
                            label={`${t('Self clarification period days')}`}
                            value={generalRules.selfClarificationPeriod}
                            onChange={evt => {
                                this.handleTextChange('selfClarificationPeriod', parseInt(evt.target.value, 10));
                            }}
                          />  
                    </Col>
                </Row> */}
                <Row>
                    <Col sm={4} style={{ marginTop: '2rem' }}>
                        <label className="control-label">{t('Time log approval')}</label>
                        <Select
                            menuPlacement={"top"}
                            options={approvalTimelogsOptions}
                            value={approvalTimelogsOptions.find(item => item.value === generalRules.approvalTimelogs)}
                            onChange={evt => this.handleSwitch('approvalTimelogs', evt.value)}
                            style={{ width: '100%', top: 'auto', bottom: "100%" }}
                        />
                    </Col>
                    <Col sm={4} style={{ marginTop: '2rem' }}>
                        <label className="control-label">{t('Vacations approval')}</label>
                        <Select
                            menuPlacement={"top"}
                            options={approvalVacationsOptions}
                            value={approvalVacationsOptions.find(item => item.value === generalRules.approvalVacations)}
                            onChange={evt => this.handleSwitch('approvalVacations', evt.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col sm={4} style={{ marginTop: '2rem' }}>
                        <label className="control-label">{t('Absents approval')}</label>
                        <Select                            
                            menuPlacement={"top"}
                            options={approvalAbsentsOptions}
                            value={approvalAbsentsOptions.find(item => item.value === generalRules.approvalAbsents)}
                            onChange={evt => this.handleSwitch('approvalAbsents', evt.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default General;