import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import withLocalization from '../../../hoc/withLocalization';
import GenericList from '../../../components/GenericList/GenericList';
import { minutesToTimeInput } from '../../../utils/timeCalc';
import DatePicker from '../../../components/DatePicker/DatePicker';
import config from '../../../library/config';
import userPlaceholderImg from '../../../assets/img/user-placeholder.jpg';

@inject('userStore', 'presenceStore')
@withRouter
@withLocalization
@observer
class EmployeeBoard extends Component {
    getStatusText = (status) => {
        switch (status) {
            case 'work':
                return 'Working';
            case 'absent':
                return 'Absence';
            case 'vacation':
                return 'Vacation';
            case 'no_work':
                return 'Not working';
            default:
                return 'Not working';
        }
    };

    handleChangeDate = (date) => {
        this.props.presenceStore.setFilter('date', date);
    };

    render() {
        const { commonStore, presenceStore } = this.props;
        const { data: clientData } = commonStore.config.client;
        const { filters } = presenceStore;
        return (
            <div className="main-content">
                <GenericList
                    filters={filters}
                    className="employee-table"
                    header={
                        <div className="list-filters">
                            <div className="list-filters__item list-filters__item_to-right">
                                <DatePicker
                                    selected={filters.date}
                                    onChange={this.handleChangeDate}
                                    dateFormat="EEEE MMMM dd, yyyy"
                                    style={{
                                        marginBottom: 15,
                                    }}
                                />
                            </div>
                        </div>
                    }
                    columns={[
                        {
                            Header: 'Employee',
                            id: 'email',
                            accessor: (user) => (
                                <div className={`employee-table__user employee-table__user_${user.presenceStatus}`}>
                                    <i
                                        style={{
                                            backgroundImage: `url("${
                                                user.image
                                                    ? `${config.UPLOADS_API_ENDPOINT}/${user.image}`
                                                    : userPlaceholderImg
                                            }")`,
                                        }}
                                    />
                                    <div>
                                        <h4>
                                            {user.first_name} {user.last_name}
                                        </h4>
                                        {user.email && <a href={`mailto:${user.email}`}>{user.email}</a>}
                                    </div>
                                </div>
                            ),
                        },
                        {
                            Header: 'Start time',
                            id: 'start_time',
                            accessor: ({ start_time, task_general_start_time }) => (
                                <div className="employee-table__start">
                                    <span style={{ color: '34525C' }}>
                                        {minutesToTimeInput(task_general_start_time, true)}
                                    </span>
                                    {start_time && (
                                        <span style={{ color: '#744098' }}>
                                            {start_time <= task_general_start_time
                                                ? this.props.t('In Time')
                                                : this.props.t('Late')}{' '}
                                            {minutesToTimeInput(start_time, true)}
                                        </span>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: 'Finish time',
                            id: 'end_time',
                            accessor: ({ end_time, task_general_end_time }) => (
                                <div className="employee-table__start">
                                    <span style={{ color: '34525C' }}>
                                        {minutesToTimeInput(task_general_end_time, true)}
                                    </span>
                                    {end_time && (
                                        <span style={{ color: '#744098' }}>{minutesToTimeInput(end_time, true)}</span>
                                    )}
                                </div>
                            ),
                        },
                        {
                            Header: 'TOTAL TIME',
                            id: 'total_time',
                            sortable: false,
                            accessor: ({ start_time, end_time }) =>
                                start_time && end_time ? minutesToTimeInput(end_time - start_time, false) : '-',
                        },
                        {
                            Header: 'Task',
                            id: 'task_name',
                            accessor: ({ task_general_name }) => task_general_name || '-',
                        },
                        {
                            Header: 'Status',
                            id: 'status',
                            sortable: false,
                            accessor: ({ presenceStatus }) => (
                                <span className={`employee-table__status employee-table__status_${presenceStatus}`}>
                                    {this.props.t(this.getStatusText(presenceStatus))}
                                </span>
                            ),
                        },
                    ]}
                    requestData={(params) => this.props.presenceStore.loadList(params)}
                />
            </div>
        );
    }
}
export default EmployeeBoard;
