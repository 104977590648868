import React, { Fragment } from 'react';
import Form from 'react-jsonschema-form';
import { withRouter } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';
import deepEqual from 'deep-equal';

import withLocalization from '../../hoc/withLocalization';

import LayoutField from './LayoutField';
import LayoutGridField from './LayoutGridField';
import TabsField from './TabsField';
import ImageUploadWidget from './Widgets/ImageUploadWidget';
import ActiveInactiveWidget from './Widgets/ActiveInactiveWidget';
import RadioWidget from './Widgets/RadioWidget';
import TrueFalseWidget from './Widgets/TrueFalseWidget';
import ProjectStatuses from './Widgets/ProjectStatuses';
import TaskStatuses from './Widgets/TaskStatuses';
import { AllUsersWidget, MembersWidget, ManagersWidget, MembersWidgetMultiple } from './Widgets/UsersWidget';
import { ProjectsWidget } from './Widgets/ProjectsWidget';
import { TasksWidget } from './Widgets/TasksWidget';
import CheckBoxWidget from './Widgets/CheckBoxWidget';
import InputTime from './Widgets/InputTime';
import AttachmentsWidget from './Widgets/AttachmentsWidget';
import { KidsSelect } from './Widgets/SpecialSelect';
import VacationStatusesWidget from './Widgets/VacationStatusesWidget';
import AbsenceStatusesWidget from './Widgets/AbsenceStatusesWidget';
import TimeSelectWidget from './Widgets/TimeSelectWidget';
import DaysOfWeekWidget from './Widgets/DaysOfWeekWidget';
import GpsCoordinatesWidget from './Widgets/GpsCoordinatesWidget';
import { MemberInProjectsWidget, MemberInProjectsWidgetMultiSelect } from './Widgets/MemberInProjectsWidget';
import BusinessTypesSelect from './Widgets/BusinessTypesSelect';
import DeviationConfigWidget from './Widgets/DeviationConfigWidget';
import ShiftsWidget from './Widgets/ShiftsWidget';
import { serverValidation } from '../../library/core';
import DocumentTemplateWidget from './Widgets/DocumentTemplateWidget';

const fields = {
    layout: LayoutField,
    layout_grid: LayoutGridField,
    tabs: TabsField,
    DeviationConfigWidget,
};

const widgets = {
    ImageUpload: ImageUploadWidget,
    ActiveInactive: ActiveInactiveWidget,
    Radio: RadioWidget,
    TrueFalse: TrueFalseWidget,
    ProjectStatuses,
    TaskStatuses,
    CheckBoxWidget,
    ManagersWidget,
    AllUsersWidget,
    MembersWidget,
    AttachmentsWidget,
    ProjectsWidget,
    TasksWidget,
    InputTime,
    VacationStatuses: VacationStatusesWidget,
    AbsenceStatuses: AbsenceStatusesWidget,
    TimeSelect: TimeSelectWidget,
    DaysOfWeek: DaysOfWeekWidget,
    KidsSelect,
    GpsCoordinatesWidget,
    MemberInProjectsWidget,
    MemberInProjectsWidgetMultiSelect,
    BusinessTypesSelect,
    MembersWidgetMultiple,
    DeviationConfigWidget,
    ShiftsWidget,
    //DocumentTemplate:DocumentTemplateWidget,
};

const customFormats = {
    'tax-number':serverValidation('tax'),
    'required-name':/^(?!\s*$).+/,
    'phone-no': serverValidation('phone'),
    postnumber: serverValidation('zip'),
    gps: /(^$|^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$)/, // allow empty strings
    'social-no': serverValidation('social'),
    email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
};

const getTypeScope = (typeName) => `types.${typeName}`;
const getPropScope = (scope, propName) => `${scope}.fields.${propName}`;

function intlSchema(schema, scope, i18n, name) {
    const newSchema = {
        ...schema,
        ..._.pickBy({
            title: i18n(schema.title),
            description: i18n(schema.description),
            help: i18n(schema.help),
        }),
    };

    if (schema.definitions) {
        newSchema.definitions = _.mapValues(schema.definitions, (typeSchema, typeName) =>
            intlSchema(typeSchema, getTypeScope(typeName), i18n, typeName)
        );
    }

    if (schema.type === 'object') {
        newSchema.properties = _.mapValues(schema.properties, (propSchema, propName) =>
            intlSchema(propSchema, getPropScope(scope, propName), i18n, propName)
        );
    }

    if (schema.enum && (!schema.enumNames || _.isEqual(schema.enum, schema.enumNames))) {
        newSchema.enumNames = schema.enum.map((option) => _.defaultTo(i18n(`${scope}.options.${option}`), option));
    }

    if (schema.items && schema.type === 'array') {
        if (schema.items.enum && !schema.items.enumNames) {
            newSchema.items = {
                ...newSchema.items,
                enumNames: schema.items.enum.map((option) => _.defaultTo(i18n(`${scope}.options.${option}`), option)),
            };
        } else if (schema.items.properties && schema.items.type === 'object') {
            newSchema.items = {
                ...newSchema.items,
                properties: _.mapValues(schema.items.properties, (propSchema, propName) =>
                    intlSchema(propSchema, getPropScope(scope, propName), i18n, propName)
                ),
            };
        }
    }

    return newSchema;
}

export const isFilled = (fieldName) => ({ formData }) => !!(formData[fieldName] && formData[fieldName].length);
export const isTrue = (fieldName) => ({ formData }) => formData[fieldName];

@withRouter
@withLocalization
class GenericForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: props.entity
    };
    this.submitForm = this.submitForm.bind(this);
  }

    static recomputeFields(props, state) {
        if (props.recomputeFields && props.recomputeFields.length) {
            let stateChanged = false;
            props.recomputeFields.forEach((fld) => {
                if (fld.indexOf('data.') === 0) {
                    fld = fld.replace('data.', '');
                    if (props.entity.data[fld] !== state.formData.data[fld]) {
                        stateChanged = true;
                        state.formData.data[fld] = props.entity.data[fld];
                    }
                } else if (props.entity[fld] !== state.formData[fld]) {
                    stateChanged = true;
                    state.formData[fld] = props.entity[fld];
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { formDataCallback } = this.props;
        const previousFormData = prevState.formData;
        const newFormData = this.state.formData;
        const formDataSame = deepEqual(previousFormData, newFormData, { strict: true });
        if (!formDataSame && typeof formDataCallback === 'function') {
            formDataCallback(this.state.formData);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.formData && props.entity && !state.formData.id && props.entity.id) {
            state.formData = props.entity;
        }
        if (state.formData && props.entity && !state.formData._reload && props.entity._reload) {
            state.formData = props.entity;
        }
        GenericForm.recomputeFields(props, state);
        return state;
    }

    onSubmit(e) {
        return this.props.onSave(e.formData, e);
    }

    onCancel() {
        this.setState({ formData: null });
        const { history, listUrl } = this.props;
        if (!listUrl) return;
        history.push(listUrl);
    }

    submitForm() {
        this.submitButton.click();
    }

    componentDidMount() {        
        const { formData } = this.state;
        const { formDataCallback } = this.props;
        if (typeof formDataCallback === 'function') {
            formDataCallback(formData);
        }
        // formData.__loadCounter = 1;
        setTimeout(() => {            
            if (this.form) this.form.validate();
            // this.setState({formData});
        }, 100);
    }

    transformErrors(errors) {
        const { t } = this.props;
        return errors.map((error) => {
            error.message = t(error.message);
            return error;
        });
    }

    //custom form validator//
    validateForm(formData, errors,t){        
        // console.log("FormObject : ",formData);  
        const {customvalidate} = this.props;
        if(customvalidate !== undefined) return customvalidate(formData,errors);     
        // let today = moment(new Date()).format('YYYY-MM-DD');
        // if(formData.birthday && moment(formData.birthday).isSameOrAfter(today)){                                   
        //     errors.birthday.addError(t("Please enter valid birthdate"));
        // }         
        // if(formData.User && formData.User.username && formData.User.username.trim() === ''){
        //     errors.User.username.addError(t("is a required property"));
        // }
         return errors;
    }

    async onChange(formData, all) {                    
        const fd = _.cloneDeep(formData);
        console.log("fd:",fd)
        const oldData = this.state.formData;
        console.log("oldata:", oldData)
        if(fd && fd.basicRules && fd.basicRules.overtimeCalcRules && fd.basicRules.overtimeCalcRules.calc_period 
            && fd.basicRules.overtimeCalcRules.calc_period == 'government'
            //  &&
            // oldData && oldData.basicRules && oldData.basicRules.overtimeCalcRules && oldData.basicRules.overtimeCalcRules.calc_period 
            // && oldData.basicRules.overtimeCalcRules.calc_period != 'government'
            ){
            console.log("************************");
            console.log(fd.basicRules.overtimeCalcRules)
            let newpayload = fd.basicRules.overtimeCalcRules;
            newpayload.max_hours =9;
            newpayload.max_minutes =0;
            fd.basicRules.overtimeCalcRules = newpayload;
        }

        Object.keys(fd).forEach((key) => {
            // Fix for the issue when removing completely a string value.            
            if (fd && oldData && typeof oldData[key] === 'string' && oldData[key] && typeof fd[key] === 'undefined') {
                if (
                    !(all.schema.properties[key] && all.schema.properties[key].format === 'date') &&
                    !all.schema.properties[key].isRequired
                ) {                    
                    fd[key] = '';
                }
            }
            if (
                fd &&
                oldData &&
                (fd[key] === '' || typeof fd[key] === 'undefined') &&
                all.schema.properties[key] &&
                all.schema.properties[key].type.indexOf('null') >= 0 &&
                !all.schema.properties[key].isRequired
            ) {                
                fd[key] = null;
            }
            
        });
        if (this.props.onChange) {
            const newData = await this.props.onChange(fd);
            Object.keys(newData).forEach((key) => {
                fd[key] = newData[key];
            });
        }
        this.setState({ formData: fd });
    }

    render() {
        const { entity, schema, uiSchema, translationScope, t, listUrl, formContext, disallowSave, isBizType, handleDocument,assignDocument,handleAssignDocuments } = this.props;
        const { formData } = this.state;
        return (
            <Fragment>
                <Form
                    ref={(form) => {
                        this.form = form;
                    }}
                    formData={formData}
                    schema={intlSchema(schema, translationScope, t)}
                    onChange={(all) => this.onChange(all.formData, all)}
                    uiSchema={uiSchema}
                    fields={fields}
                    showErrorList={false}
                    widgets={widgets}
                    customFormats={customFormats}
                    onSubmit={(e) => this.onSubmit(e)}
                    transformErrors={(errors) => this.transformErrors(errors)}
                    validate={(formData,errors)=>this.validateForm(formData,errors,t)}
                    liveValidate
                    noHtml5Validate
                >
                    <button
                        ref={(btn) => {
                            this.submitButton = btn;
                        }}
                        className="hidden"
                    />
                </Form>
                
                {assignDocument &&
                    <DocumentTemplateWidget
                    user_id = {entity.id?entity.id:0}
                    handleChnage = {(ids) => handleAssignDocuments(ids)}/>
                }

                {isBizType==true && (
                    <div style={{paddingBottom:'10px'}}>
                        <button className="btn btn-fill" onClick={() => handleDocument()}>
                            {t('Document Management')}
                        </button>
                    </div>
                )}
                {!disallowSave && (
                    <button className="btn btn-fill" onClick={() => this.submitForm()}>
                        {t('Save')}
                    </button>
                )}
                {listUrl && (
                    <button className="btn" style={{ marginLeft: '20px' }} onClick={() => this.onCancel()}>
                        {t('Cancel')}
                    </button>
                )}
            </Fragment>
        );
    }
}

export default GenericForm;
