import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import { find } from 'lodash';
import { Button, Modal } from 'react-bootstrap';

import ModalEditor from './ChatModalEditor';

import withLocalization from '~/hoc/withLocalization';
import withPermissions from '~/hoc/withPermissions';
import LoadingSpinner from '~/elements//LoadingSpinner';
import Messenger from '~/components/Chat/Messenger';
import config from '~/library/config';
import DefaultUserIcon from '~/assets/img/faces/face-0.jpg';

@inject('chatStore', 'userStore', 'commonStore')
@withLocalization
@withPermissions
@withRouter
@observer
export default class Chat extends Component {
    state = {
        nowEditingId: null,
        nowEditingRoom: {},
    };

    loadData(_id) {
        const id = !_id ? null : parseInt(_id);
        const {
            chatStore, add, location, match
        } = this.props;
        if (!chatStore.allRooms) {
            chatStore.loadRooms();
        } else {
            this.props.chatStore.loading.rooms = false;
        }
        if (id) {
            chatStore.setCurrentRoomId(id);
            chatStore.loadRoom(id);
            // chatStore.loadMessages(id);
        } else {
            chatStore.setCurrentRoomId(null);
        }
    }

    componentWillMount() {
        // this.props.chatStore.loading.messages = true;
        console.log('WM');
        this.props.chatStore.loading.rooms = true;
        this.props.chatStore.loading.room = true;
    }

    componentDidMount() {
        const { location } = this.props;
        let id = location.pathname.replace('/admin/chat', '');
        if (id.startsWith('/')) id = id.replace('/', '');
        if (!id) id = null;
        console.log('DM', id);
        this.loadData(id); // tbd: last loaded ID
    }

    handleChangeRoom = id => {
        this.props.chatStore.setCurrentRoomId(parseInt(id));
        this.props.history.push(`/admin/chat/${id}`);
        this.loadData(id);
    };

    sendMessage = text => {
        try {
            this.props.chatStore.sendMessage({
                author: this.props.userStore.currentUser.id,
                room: this.props.chatStore.currentRoomId,
                message: text,
            });
        } catch (e) {
            console.log(e);
            this.props.commonStore.addNotification(e.toString(), null, 'error');
        }
    };

    resolveUser = message => {
        const room = this.props.chatStore.currentRooms[this.props.chatStore.currentRoomId];
        const member = find(room.members, r => r.user_id == message.author_id);
        if (!member) return { fullname: '?', image: DefaultUserIcon };
        return {
            fullname: member.fullname,
            image: member.image ? `${config.UPLOADS_API_ENDPOINT}/${member.image}` : DefaultUserIcon,
        };
    };

    editRoom = async id => {
        if (!id) {
            this.setState({ nowEditingRoom: { room: {}, members: [] } });
            this.setState({ nowEditingId: -1 });
            return;
        }
        await this.props.chatStore.loadRoom(id);
        console.log(id, this.props.chatStore.currentEntity);
        this.setState({ nowEditingRoom: this.props.chatStore.currentEntity });
        this.setState({ nowEditingId: id });
    };

    onEditorChange = property => {
        const s = this.state.nowEditingRoom;
        if (property.members) {
            Object.keys(property).forEach(key => {
                s[key] = property[key];
            });
        } else {
            Object.keys(property).forEach(key => {
                s.room[key] = property[key];
            });
        }
        this.setState({ nowEditingRoom: s });
    };

    saveChat = async () => {
        await this.props.chatStore.save(this.state.nowEditingRoom, this.state.nowEditingId === -1);
        this.setState({ nowEditingId: null });
        this.props.chatStore.loading.rooms = true;
        this.props.chatStore.allRooms = null;
        this.loadData(0);
    };

    render() {
        const {
            currentRooms, allRooms, currentRoomId, loading, unreads
        } = this.props.chatStore;
        const { t, userStore } = this.props;
        const { nowEditingId, nowEditingRoom } = this.state;
        const { aclAllowed } = this.props;

        const allowMod = aclAllowed('chat-configure');

        if (
            loading.rooms
            || (currentRoomId && loading.room) //
        ) {
            return <LoadingSpinner />;
        }
        const currentRoom = currentRoomId ? currentRooms[currentRoomId] : null;
        return (
            <div className="main-content">
                <Modal size="md" show={!!nowEditingId} onHide={() => this.setState({ nowEditingId: null })}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.t('Chat properties')}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ModalEditor entity={nowEditingRoom} onChange={property => this.onEditorChange(property)} />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ nowEditingId: null })}>
                            {t('Close')}
                        </Button>
                        <Button variant="primary" onClick={() => this.saveChat()}>
                            {t('Save changes')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Messenger
                    rooms={allRooms}
                    changeRoom={id => this.handleChangeRoom(id)}
                    currentRoomId={currentRoomId}
                    currentRoom={currentRoom}
                    myId={userStore.currentUser.id}
                    unreads={unreads}
                    sendMessage={message => this.sendMessage(message)}
                    t={t}
                    resolveUser={message => this.resolveUser(message)}
                    editRoom={id => this.editRoom(id)}
                    allowModification={false}
                />
            </div>
        );
    }
}
