import { observable, action, reaction } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import { findIndex } from 'lodash';

import agent from '../agent';

import commonStore from './commonStore';

let socket = null;

class UserAccessStore {


    @observable loadingLookup = false;


    @observable filters = {
        status: '',
        name: '',
    };

    @observable appliedFilters = {
        status: '',
        name: '',
    };

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    /* Common CRUD */

    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = null;

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @action resetLastListLoadTime() {
        console.log('called');
        this.lastListLoadTime = new Date();
    }

    @action loadList(params, mode) {
        const _params = Object.assign({}, params);
        //console.log("params",params)
        return agent.UserAccess.list(_params)
            .then(
                action((list) => {
                 
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    @action returnDefaultNew(mode, client_id, additionalParams, user, vacation_days) {
        if (mode == 'superadmins') {
            this.currentEntity = {
                user_type: 'super-admin',
            };
        } else {
            this.currentEntity = {
                user_type: 'admin',
                client_id,
            };
            if (mode == 'members') {
                this.currentEntity.user_type = 'member';
            }
            if (mode == 'managers') {
                this.currentEntity.user_type = 'pm';
            }
        }
        if (additionalParams) {
            Object.assign(this.currentEntity, additionalParams);
        }
        if (user && user.user_type === 'pm' && user.ManagesProjects) {
            this.currentEntity.MemberInProjects = user.ManagesProjects.join(',');
        }
        this.currentEntity.employee_percent = '100';
        this.currentEntity.vacation_days = vacation_days;
        this.loading = false;
        this.currentEntity = { user: this.currentEntity };
    }

  
    @action loadPmMembers(id){
        return agent.UserAccess.loadPmMembers(id).then(
            action((response)=>{
                return response;
            })
        ).catch(
            action((err)=>{
                throw err;
            })
        )
    }

    @action loadAllowedPms(id){
        return agent.UserAccess.loadAllowedPms(id).then(
            action((response)=>{
                return response;
            })
        ).catch(
            action((err)=>{
                throw err;
            })
        )
    }

    @action savePmMembers(values){
        return agent.UserAccess.savePmMembers(values).then(
            action((response=>{
                return response;
            }))
        ).catch(
            action((err)=>{
                throw err;
            })
        )
    }

    @action approveAccess(id,data){
        return agent.UserAccess.approveAccess(id,data).then(
            action(response=>{
                return response;
            })
        ).catch(
            action((err)=>{
                throw err;
            })
        )
    }

}

export default new UserAccessStore();
