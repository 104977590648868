import { observable, action } from 'mobx';
import moment from 'moment/min/moment-with-locales';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

class ClientStore {
    @observable currentList = [];

    @observable currentEntity = {};

    @observable updatingErrors = null;

    @observable updating = false;

    @observable loading = false;

    @observable requestParams = {};

    @observable deleteSuccess = false;

    @observable lastListLoadTime = null;

    @observable filters = {
        biztype: '',
        name: '',
    };

    @observable appliedFilters = {
        biztype: '',
        name: '',
    };

    @action setLoading(value) {
        this.loading = value;
    }

    @action setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    @action onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        // requestParams.filters = this.filters;
        // this.loadList(requestParams);
    }

    @action loadList(params) {
        this.loading = true;
        return agent.Clients.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.currentList = list;
                    this.lastListLoadTime = list.time;
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action returnDefaultNew(params) {
        this.currentEntity = {
            client: {
                User: { data: {} },
                data: {},
                max_users: 1000,
                renew_manual: true,
                biztype: 1,
                renew_date: moment(new Date()).toISOString(),
                ...params,
            },
        };
        this.loading = false;

        return this.currentEntity;
    }

    @action load(id) {
        this.loading = true;
        return agent.Clients.load(id)
            .then(
                action(response => {
                    if (!response.client.User) response.client.User = {};
                    if (!response.client.data) response.client.data = {};
                    if (!response.client.User.data) response.client.User.data = {};
                    this.currentEntity = response;
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    @action save(values, isAdd) {
        this.updating = true;
        return agent.Clients.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    @action async remove(id) {
        await agent.Clients.remove(id);
        this.lastListLoadTime = new Date();
        this.deleteSuccess = true;
        return 1;
    }
}

export default new ClientStore();
