import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { observer } from 'mobx-react';

import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import './MessageList.css';

@observer
export default class MessageList extends Component {
    messagesEnd = null;

    constructor(props) {
        super(props);
        this.messagesEnd = React.createRef();
        this.state = {
            messages: [],
        };
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.currentRoomId !== nextProps.currentRoomId) {
            this.scrollToBottom();
        }
    }

    scrollToBottom = () => {
        if (this.messagesEnd) this.messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
    };

    getMessages = () => {
        if (this.props.currentRoom && this.props.currentRoom.lastMessages) {
            return this.props.currentRoom.lastMessages.slice().reverse();
        }
        return [];
    };

    renderMessages(propMessages) {
        const MY_USER_ID = this.props.myId;
        let i = 0;
        const messageCount = propMessages.length;
        const messages = [];

        while (i < messageCount) {
            const previous = propMessages[i - 1];
            const current = propMessages[i];
            const next = propMessages[i + 1];
            const isMine = current.author === MY_USER_ID;
            const currentMoment = moment(current.timestamp);
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;

            if (previous) {
                const previousMoment = moment(previous.timestamp);
                const previousDuration = moment.duration(currentMoment.diff(previousMoment));
                prevBySameAuthor = previous.author === current.author;

                if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                    startsSequence = false;
                }

                if (previousDuration.as('hours') < 1) {
                    showTimestamp = false;
                }
            }

            if (next) {
                const nextMoment = moment(next.timestamp);
                const nextDuration = moment.duration(nextMoment.diff(currentMoment));
                nextBySameAuthor = next.author === current.author;

                if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                    endsSequence = false;
                }
            }

            messages.push(
                <Message
                    key={i}
                    isMine={isMine}
                    startsSequence={startsSequence}
                    endsSequence={endsSequence}
                    showTimestamp={showTimestamp}
                    data={current}
                    resolvedUser={this.props.resolveUser(current)}
                />
            );

            // Proceed to the next message.
            i += 1;
        }

        return messages;
    }

    render() {
        const messages = this.getMessages();

        return (
            <div className="message-list">
                <Toolbar
                    title={
                        this.props.currentRoom
                            ? this.props.currentRoom.room.name
                            : `<- ${this.props.t('Please select project')}`
                    }
                    rightItems={[
                        <ToolbarButton key="info" icon="ion-ios-information-circle-outline" />,
                        <ToolbarButton key="video" icon="ion-ios-videocam" />,
                        <ToolbarButton key="phone" icon="ion-ios-call" />,
                    ]}
                />

                <div className="message-list-container">{this.renderMessages(messages)}</div>
                <div ref={this.messagesEnd} />

                {this.props.currentRoom && (
                    <Compose
                        t={this.props.t}
                        sendMessage={text => this.props.sendMessage(text)}
                        rightItems={[
                            <ToolbarButton key="photo" icon="ion-ios-camera" />,
                            <ToolbarButton key="image" icon="ion-ios-image" />,
                            <ToolbarButton key="audio" icon="ion-ios-mic" />,
                            <ToolbarButton key="money" icon="ion-ios-card" />,
                            <ToolbarButton key="games" icon="ion-logo-game-controller-b" />,
                            <ToolbarButton key="emoji" icon="ion-ios-happy" />,
                        ]}
                    />
                )}
            </div>
        );
    }
}
