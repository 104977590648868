import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Modal } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';

import Button from '../../../components/CustomButton/CustomButton.jsx';
import BannerView from './BannerView'

// import VacationsTotals from './VacationsTotals';
// import VacationListFilter from './VacationFilter';

import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import { statusMap } from '~/utils/normalStatuses';
import { serverTimeFormat } from '~/library/core';

// Elements

import customerFeature from '~/utils/customerFeature';
import { minutesToTimeInput } from '~/utils/timeCalc';

@inject('bannerStore', 'userStore', 'timelogStore','commonStore')
@withRouter
@withLocalization
@observer
class BannersList extends Component {
    state = {
        accepting: null,
        acceptanceChanged: false,
        selected: null,
        banner_view:false,
        banner_id:null,
        bannersInfo:[],
    };

    handleTableButtonAction = (id, type) => {
        this.props.onTableAction(id, type);
    };

    // handleTableButtonAction = async (id, type) => {
    //     const{bannerStore,commonStore,t,history}=this.props;
    //     let getBanners = [];
    //     if(type === "view"){

    //         await bannerStore.getBannerById(id).then(data=>{
    //             getBanners.push({
    //                 name:data.banners.name,
    //                 body:data.banners.body,
    //                 banner_readers:data.readers
    //             });
    //         this.setState({bannersInfo:getBanners,banner_view:true,banner_id:id});
    //         })
    //         history.push(`/admin/banner_details/${id}`);
    //     }

    //     if(type === "remove"){
    //         await bannerStore.removeBanner(id).then(response=>{
    //             bannerStore.resetLastListLoadTime();
    //             commonStore.addNotification(t('Deleted'), null, 'error');
    //         })
    //     }
    // };

    renderTableActionButtons = (id, full) => {
        const { t } = this.props;
        const { user_type, id: userId } = this.props.userStore.currentUser;
        // const showStatus = (user_type === 'pm' || user_type === 'admin') && customerFeature('allow_accepting');
        // const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        // let statusText = full.status;
        // if (!statusText) statusText = 'active';
        // statusText = statusMap[statusText] || '';
        return (
            <div className="">
              
                <Button onClick={() => this.handleTableButtonAction(id, 'view')} bsStyle="primary" simple icon>
                    <i className="fa fa-eye" />
                </Button>
                <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>
                {/* {allow && (
                    <Button onClick={() => this.handleTableButtonAction(id, 'remove')} bsStyle="danger" simple icon>
                        <i className="fa fa-times" />
                    </Button>
                )} */}
            </div>
        );
    };

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }


    // closeModal(){
    //     this.setState({banner_view:false});
    // }

    render() {
        const { t,bannerStore } = this.props;
        const {
            loading, filters, appliedFilters, lastListLoadTime,onFilter,resetLastListLoadTime
        } = this.props.bannerStore;
        const { name } = filters;
        const { user_type } = this.props.userStore.currentUser;
        const { accepting, acceptanceChanged,banner_view,banner_id,bannersInfo} = this.state;
        const showStatus = user_type === 'pm' && customerFeature('allow_accepting');
        
        return (
            <Fragment>
                {/* {(user_type === 'member' || user_type === 'pm') && <VacationsTotals />} */}
                <div className="table-list">
                    <div className="table-list__header" style={{marginBottom:'10px'}}>
                    <div className="list-filters__item list-filters__item_grow" style={{padding:'0px',display:'flex',border:'1px solid #cccccc',backgroundColor: '#f9fafc',borderRadius:'5px',height:'42px'}}>
                            <FormControl
                                placeholder={t('Search Banner')}
                                type="text"
                                onChange={(e) => bannerStore.setFilter('name', e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        this.props.bannerStore.onFilter();
                                    }
                                }}
                                value={name}
                                style={{border:'none',backgroundColor: '#f9fafc',padding:'0px 5px'}}
                            />
                            <i className='fa fa-search' style={{paddingRight:'30px',color:'#ccc'}}></i>
                        </div>
                        <Button fill  icon onClick={() => this.props.onAdd()} style={{height:'35px',marginTop:'3px'}}>
                        <i className="fa fa-plus" /> {this.props.t('Add new')}
                        </Button>
                    </div>
                    <GenericList
                        columns={[
                            {
                                Header: 'Created On',
                                id: 'created_at',
                                sortable: false,
                                Cell: ({ original }) => moment(new Date(original.created_at)).format(serverTimeFormat()),
                            },
                            {
                                Header: 'Created By',
                                id: 'created_by',
                                sortable: false,
                                accessor: 'created_by',
                            },
                            {
                                Header: 'Banner Name',
                                id: 'name',
                                accessor: 'name',
                                sortable: false,
                            },
                            {
                                Header: 'Actions',
                                id: 'actions',
                                sortable: false,
                                accessor: full => this.renderTableActionButtons(full.id, full),
                            },
                        ]}
                        filters={appliedFilters}
                        onSelectionChanged={selection => this.onSelectionChanged(selection)}
                        lastListLoadTime={lastListLoadTime}
                        requestData={params => this.props.bannerStore.loadList(params)}
                    />
                </div>
                {
                    banner_view && (
                        <>
                        <BannerView  closeModal={()=>this.closeModal()}  bannersInfo={bannersInfo} banner_view={banner_view}  />
                        </>
                    )
                }
                
               
            </Fragment>
        );
    }
}
export default BannersList;
