import { withRouter } from 'react-router-dom';
import React from 'react';
import { inject, observer } from 'mobx-react';

import LGImages from '../../assets/img/lg/index';
import PrimaryLayout from '../../layouts/PrimaryLayout';
import withLocalization from '../../hoc/withLocalization';
import { Input, Textarea } from '../../elements/Input';
import Button from '../../components/CustomButton/CustomButton';
import Checkbox from '../../components/CustomCheckbox/CustomCheckbox';

@inject('authStore', 'commonStore')
@withLocalization
@withRouter
@observer
export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contactForm: {
                company: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                comment: '',
                accept: false,
            },
        };
    }

    handleFormChange = (name, value) => {
        const contactForm = Object.assign({}, this.state.contactForm);
        contactForm[name] = value;
        this.setState({
            contactForm,
        });
    };

    contactSubmit = (e) => {
        e.preventDefault();
        this.props.commonStore.sendContact(this.state.contactForm).then(() => {
            this.props.commonStore.addNotification(
                this.props.t('Your message has been sent, thank you.'),
                null,
                'success'
            );
            this.setState({
                contactForm: {
                    company: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    comment: '',
                    accept: false,
                },
            });
            alert(this.props.t('Your message has been sent, thank you.'));
        });
    };

    refsForScroll = {};

    setScrollRef = (ref, name) => {
        this.refsForScroll[name] = ref;
    };

    render() {
        const { contactForm } = this.state;
        return (
            <PrimaryLayout {...this.props} refsForScroll={this.refsForScroll}>
                <div className="lp-main" ref={(section) => this.setScrollRef(section, 'home')}>
                    <div className="lp-main__wrapper">
                        <div className="lp-main__content">
                            <h1>
                                {this.props.t(
                                    'Technological competence provider in HR, finance and time records for time sheets for private and public sectors'
                                )}
                            </h1>
                            <p>
                                {this.props.t(
                                    'We develop and deliver complete solutions that help your company work efficiently and not least goal-related.'
                                )}
                            </p>
                            <div className="lp-main__content-buttons">
                                {/*href="https://apps.apple.com/us/app/t-c/id1526323137"
href="https://play.google.com/store/apps/details?id=com.tidogkontroll.timeandcontrol"
                                */}

                                <a
                                    href="https://apps.apple.com/us/app/norsktimeregister/id1490137511"
                                    className="app-link"
                                >
                                    <img src={LGImages.apple_store} alt="Apple Store" />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.tidogkontroll.norsktime"
                                    className="app-link"
                                >
                                    <img src={LGImages.google_play} alt="Google Play" />
                                </a>
                            </div>
                        </div>
                        <div className="lp-main__img">
                            <img src={LGImages.lg_main_img} alt="Image" />
                        </div>
                    </div>
                </div>
                <div
                    className="lp-section lp-section_features"
                    ref={(section) => this.setScrollRef(section, 'features')}
                >
                    <h2 className="lp-section__title" />
                    <h3 className="lp-section__sub-title">{this.props.t('The only app you’ll need')}</h3>
                    <div className="lp-features">
                        <div className="lp-features__item">
                            <div className="lp-features__item-img">
                                <img src={LGImages.cloud_service} alt="Cloud Service" />
                            </div>
                            <h3>{this.props.t('Cloud Service')}</h3>
                            <span>
                                {this.props.t('All data are saved secured and fast in a Microsoft azure cloud server')}
                            </span>
                        </div>
                        <div className="lp-features__item">
                            <div className="lp-features__item-img">
                                <img src={LGImages.no_setup} alt="No Setup" />
                            </div>
                            <h3>{this.props.t('No Setup')}</h3>
                            <span>
                                {this.props.t(
                                    'A self-explanatory system can help HR, management and employees of your company'
                                )}
                            </span>
                        </div>
                        <div className="lp-features__item">
                            <div className="lp-features__item-img">
                                <img src={LGImages.union} alt="Union" />
                            </div>
                            <h3>{this.props.t('Union')}</h3>
                            <span>
                                {this.props.t(
                                    'The own complete module for labor unions in Norway that helps their members anchored in Norwegian law.'
                                )}
                            </span>
                        </div>
                        <div className="lp-features__item">
                            <div className="lp-features__item-img">
                                <img src={LGImages.more_functions} alt="More Functions" />
                            </div>
                            <h3>{this.props.t('More Functions')}</h3>
                            <span>
                                {this.props.t(
                                    'More functions and module can be added in the system if your company need it. Contact us today, we will give a quote for u.'
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="lp-ability" ref={(section) => this.setScrollRef(section, 'about')}>
                    <div className="lp-ability__wrapper">
                        <div className="lp-ability__img">
                            <img src={LGImages.lg_preview_app} alt="App Preview" />
                        </div>
                        <div className="lp-ability__list">
                            <div className="lp-ability-item">
                                <div className="lp-ability-item__img">
                                    <img src={LGImages.lg_clock} alt="Personal Register" />
                                </div>
                                <div className="lp-ability-item__content">
                                    <h4>{this.props.t('Personal Register')}</h4>
                                    <p>
                                        {this.props.t(
                                            'Our app allow both the HR and employee to have correct information. No need to have an extra personal register. We provde full solution for that.'
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="lp-ability-item">
                                <div className="lp-ability-item__img">
                                    <img src={LGImages.lg_puzle} alt="Register working time" />
                                </div>
                                <div className="lp-ability-item__content">
                                    <h4>{this.props.t('Register working time')}</h4>
                                    <p>
                                        {this.props.t(
                                            'Easy to register working hours, overtime, travel expenses, km and take pictures of done work and much more.'
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="lp-ability-item">
                                <div className="lp-ability-item__img">
                                    <img src={LGImages.lg_vacation} alt="Vacation" />
                                </div>
                                <div className="lp-ability-item__content">
                                    <h4>{this.props.t('Vacation')}</h4>
                                    <p>
                                        {this.props.t(
                                            'Our app gives you control and overview of the vacation. No mailer, Excel sheet or paper. Applications, approval, holiday plans, holiday balance and transfer of residual holiday are easily handled via mobile and PC.'
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="lp-ability-item">
                                <div className="lp-ability-item__img">
                                    <img src={LGImages.lg_bed} alt="Register Absence" />
                                </div>
                                <div className="lp-ability-item__content">
                                    <h4>{this.props.t('Register Absence')}</h4>
                                    <p>
                                        {this.props.t(
                                            "Absent and leave functions, an employee's sick leave is registered on the first day of absence by the nearest manager, a defined administrator (such as a switchboard) or the employee himself."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lp-app-for">
                    <div className="lp-app-for__item lp-app-for__item_75 lp-app-for__item_s_100">
                        <div className="lp-app-for-main">
                            <h4>
                                {this.props.t(
                                    'A Perfect app for restaurants, contractors, plumbers, electricians, cleaners, union members'
                                )}
                            </h4>
                            <div className="lp-app-for-main__content">
                                <img src={LGImages.app_for_bg} alt="A Perfect app for peoples" />
                                <span>
                                    {this.props.t(
                                        'We put together a system that your business needs. A cloud based system, comes with dedicated mobile app in addition to web application.'
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="lp-app-for__item lp-app-for__item_25">
                        <img src={LGImages.app_for_1} alt="App for peoples" />
                    </div>
                    <div className="lp-app-for__item lp-app-for__item_50 lp-app-for__item_s_75">
                        <img src={LGImages.app_for_2} alt="App for peoples" />
                    </div>
                    <div className="lp-app-for__item lp-app-for__item_25 lp-app-for__item_s_50">
                        <img src={LGImages.app_for_3} alt="App for peoples" />
                    </div>
                    <div className="lp-app-for__item lp-app-for__item_25 lp-app-for__item_s_50">
                        <img src={LGImages.app_for_4} alt="App for peoples" />
                    </div>
                </div>
                <div
                    className="lp-section lp-section_contacts"
                    style={{ marginTop: '20px' }}
                    ref={(section) => this.setScrollRef(section, 'contacts')}
                >
                    <h2 className="lp-section__title">{this.props.t('Have any question?')}</h2>
                    <h3 className="lp-section__sub-title">{this.props.t('Contact us today!')}</h3>
                    <span className="lp-section__desc">
                        {this.props.t(
                            "Is your company or union digitized? Let's talk, the only thing you need to do is fill out the form and contact you shortly."
                        )}
                    </span>
                    <div className="lp-contacts">
                        <div className="lp-contacts__img">
                            <img src={LGImages.lg_contacts} alt="Contact us" />
                        </div>
                        <form className="lp-contacts__form" onSubmit={this.contactSubmit}>
                            <Input
                                label={this.props.t('Company Name')}
                                value={contactForm.company}
                                onChange={(e) => this.handleFormChange('company', e.target.value)}
                                placeholder={this.props.t('Enter company name here')}
                                className="lp-contacts__form-input"
                                required
                            />
                            <Input
                                label={this.props.t('First Name')}
                                value={contactForm.firstName}
                                onChange={(e) => this.handleFormChange('firstName', e.target.value)}
                                placeholder={this.props.t('Enter first name here')}
                                className="lp-contacts__form-input lp-contacts__form-input_s"
                                required
                            />
                            <Input
                                label={this.props.t('Last Name')}
                                value={contactForm.lastName}
                                onChange={(e) => this.handleFormChange('lastName', e.target.value)}
                                placeholder={this.props.t('Enter last name here')}
                                className="lp-contacts__form-input lp-contacts__form-input_s"
                                required
                            />
                            <Input
                                label={this.props.t('Email')}
                                value={contactForm.email}
                                type="email"
                                className="lp-contacts__form-input"
                                onChange={(e) => this.handleFormChange('email', e.target.value)}
                                placeholder={this.props.t('Enter email address')}
                                required
                            />
                            <Input
                                label={this.props.t('Mobile')}
                                value={contactForm.phone}
                                className="lp-contacts__form-input"
                                onChange={(e) => this.handleFormChange('phone', e.target.value)}
                                placeholder={this.props.t('Enter your mobile number')}
                                required
                            />
                            <Textarea
                                label={this.props.t('Comments')}
                                value={contactForm.comment}
                                className="lp-contacts__form-input"
                                onChange={(e) => this.handleFormChange('comment', e.target.value)}
                                placeholder={this.props.t(
                                    'Feel free to provide a brief description and number of users.'
                                )}
                                required
                            />
                            <div className="lp-contacts__form-footer">
                                <span style={{ lineHeight: '40px', display: 'flex' }}>
                                    <Checkbox
                                        isChecked={contactForm.accept}
                                        onChange={(checked) => this.handleFormChange('accept', checked)}
                                        label={this.props.t('I accept the')}
                                        required
                                    />
                                    <a href="/terms">{this.props.t('terms & conditions')}</a>
                                </span>
                                <Button type="submit" fill>
                                    {this.props.t('Send')}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="lp-footer">
                    <h3>{this.props.t('Get in touch!')}</h3>
                    <div className="lp-footer__main">
                        <div className="lp-footer__main_row">
                            <a href="https://goo.gl/maps/UEuVQFUnfdLzVA6n7" target="_blank">
                                <img src={LGImages.lg_icon_loc} alt="Location" /> Rabekkgata 9, 1523, Moss, Norway
                            </a>
                        </div>
                        <div className="lp-footer__main_row">
                            <a href="mailto:info@rainmakersinnovation.no">
                                <img src={LGImages.lg_icon_mail} alt="Email" />
                                support@tidogkontroll.no
                            </a>
                            <a href="skype:appsbusinesstore" style={{ paddingLeft: '15px' }}>
                                <img src={LGImages.lg_icon_phone} alt="Skype" />
                                appsbusinesstore
                            </a>
                        </div>
                    </div>
                    <div className="lp-footer__bottom" style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.17)' }}>
                        <div className="lp-footer__bottom-wrapper">
                            <a href="/" className="lp-footer__bottom-logo">
                                <img src={LGImages.lg_logo} alt="LOGO" />
                            </a>
                            <div className="lp-footer__bottom-texts">
                                <span>{this.props.t('A Product of Tid & kontroll as')}</span>

                                <span>{this.props.t('Copyrights 2019-2020 - All Rights Reserved')}</span>
                            </div>
                            {/* <div className="lp-footer__bottom-social">
                <a href="#"><img src={LGImages['lg_icon_fb']} alt="Facebook"/></a>
                <a href="#"><img src={LGImages['lg_icon_tw']} alt="Twitter"/></a>
                <a href="#"><img src={LGImages['lg_icon_yt']} alt="YouTube"/></a>
              </div> */}
                        </div>
                    </div>

                    <div className="lp-footer__main" style={{ paddingBottom: '20px' }}>
                        <div style={{ color: 'white' }}>
                            <a href="/privacy-policy" style={{ fontSize: '8px' }}>{this.props.t('Privacy Policy')}</a>
                            <span style={{ fontSize: '8px' }}> |</span>
                            <a href="/docs/databehandleravtale.pdf" style={{ fontSize: '8px' }}>{this.props.t('GDPR')}</a>
                            <span style={{ fontSize: '8px' }}> |</span>
                            <a href="/terms" style={{ fontSize: '8px' }}>{this.props.t('Terms of usage')}</a>
                        </div>
                    </div>
                </div>
            </PrimaryLayout>
        );
    }
}
