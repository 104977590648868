import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales';
import { serverTimeFormat,serverIsAmPmTimeFormat } from '~/library/core';
import { FormControl } from 'react-bootstrap';
import { find, cloneDeep, sortBy } from 'lodash';
import MultiSelect from '@khanacademy/react-multi-select';

import { Input } from '../../../elements/Input';
import ReactQuill from 'react-quill';
import { Row, Col,Modal } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RadioWidget from '../../../components/GenericForm/Widgets/RadioWidget';
import { ManagersWidget } from '../../../components/GenericForm/Widgets/UsersWidget';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget';
import './Banner.css';

import withLocalization from '~/hoc/withLocalization';
import LoadingSpinner from '~/elements/LoadingSpinner';
import { style } from '~/variables/Variables';


@inject('commonStore', 'userStore','bannerStore')
@withRouter
@withLocalization
@observer
class BannerDetails extends Component {
    state = {
        editingRowId: null,
        editorHTML: '',
        theme : 'snow',
        modules: {
            toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        },
        formats: [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
        ],

        placeholder: 'Write Something',
        banner_name:'',
        banner_body:null,
        banner_readers:[],
        banner_readers_dup:[],
        search_name:'',
        no_user_data:false
    };

    async loadData() {
        const { id,bannerStore } = this.props;
        if (!id) return;
            await bannerStore.getBannerById(id).then(data=>{
            this.setState({
                banner_name:data.banners.name,
                banner_body:data.banners.body,
                banner_readers:data.readers,
                banner_readers_dup:data.readers
            });
            })
        
    }

   
    componentDidMount() {
        this.loadData();
    }

    getListUrl() {
        //returns banner list URL//
        return '/admin/banner';
    }

    cancelClick() {
        //redirects pages to list URL//
        const { history } = this.props;
        history.push(this.getListUrl());
    }

    convertToAMPM(date){
        const time = moment(new Date(date)).format(serverIsAmPmTimeFormat()?'D MMMM YYYY, hh:mm a':'HH:mm');
        let [hours, minutes] = time.split(':');
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? minutes : minutes;
        let timewithampm = hours + ':' + minutes + ' ' + ampm;
        return timewithampm;
    }

    handleChange(value){
        const{banner_readers,search_name,banner_readers_dup} = this.state;
        let results = banner_readers || [];
        const filterResults = results.filter((user) => user.reader_name.toLowerCase().indexOf(value.toLowerCase())>=0)
        if(filterResults.length === 0){
            this.setState({no_user_data:true});
        }
        if(filterResults.length > 0){
            this.setState({no_user_data:false });
        }
        this.setState({banner_readers_dup:filterResults});
    }
   

    render() {
        const {
            banner_name,banner_body,banner_readers,banner_readers_dup,no_user_data
        } = this.state;
       
        const{bannerStore,t}=this.props;
        return (
            <div style={{padding:'0px 1em'}}>
                    <div className="page__header" style={{padding:'0px'}}>
                        <p style={{color: '#4c83f7',cursor:'pointer'}} onClick={() => this.cancelClick()}> {t('Go Back')}</p>
                        <h1 className="page__title" style={{fontWeight:400}}>
                            {banner_name}
                        </h1>
                    </div>  
                    <div>
                        <div>
                            <p style={{fontSize:'16px',color: '#8a8a8a'}}> {t('Banner Title')}</p>
                            <div  style={{fontWeight:400,fontSize:'20px',marginBottom:'10px'}}>
                                {banner_name}
                            </div>
                        </div>
                        <div>
                            <p style={{fontSize:'16px',color: '#8a8a8a'}}> {t('Banner Details')}</p>
                            <div>
                            <ReactQuill
                            theme={"bubble"}
                            value={this.state.banner_body}
                            readOnly={true}
                            style={{ border: '0px', minHeight:'200px', fontSize:'20px', color:'#000'}}
                            // preserveWhitespace={true}
                            />
                            </div>
                        </div>
                        <div>
                        <div className="list-filters__item list-filters__item_grow" style={{padding:'0px',display:'flex',border:'1px solid #cccccc',backgroundColor: '#f9fafc',borderRadius:'5px'}}>
                            <FormControl
                                placeholder={t('Search Member')}
                                type="text"
                                onChange={(e) => this.setState({search_name:e.target.value})}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                         this.handleChange(e.target.value)
                                    }
                                }}
                                value={this.state.search_name}
                                style={{border:'none',backgroundColor: '#f9fafc',padding:'0px 5px'}}
                            />
                            <i className='fa fa-search' style={{paddingRight:'30px',color:'#ccc'}}></i>
                        </div>
                        </div>
                        <div>
                        {no_user_data && (
                            <div style={{textAlign:'center',marginTop:'10px'}}>
                                {t('No data found')}
                            </div>
                        )}
                        {
                            banner_readers_dup && banner_readers_dup.length>0 &&(
                                            <div style={{marginTop:'20px'}}>
                                                <table className="table table-hover table-striped project-members-form">
                                                <thead>
                                                    <tr style={{ backgroundColor: '#447df7' }}>
                                                        <th style={{ color: 'white', fontWeight: '900' }}>{t('Member')}</th>
                                                        <th style={{ color: 'white', fontWeight: '900' }}>{t('Status')}</th>
                                                        <th style={{ color: 'white', fontWeight: '900' }}>{t('Time')}</th>
                                                    </tr>
                                                </thead>
                                                    <tbody>
                                                        {banner_readers_dup.map(member => (
                                                            <tr key={`${member.id}`}>
                                                                <td>{member.reader_name}</td>
                                                                <td className={member.status === 'read'?'status_read':'status_unread'}>{member.status}</td>
                                                               <td>{member.read_at!=null && (serverIsAmPmTimeFormat()?moment(member.read_at).format('DD-MM-YYYY hh:mm a') :moment(member.read_at).format('DD-MM-YYYY HH:mm'))}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                                </table>
                                            </div>
                                        )
                                    }
                        </div>
                    </div>
            </div>
          
        );
    }
}

export default BannerDetails;
