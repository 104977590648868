import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import moment from 'moment/min/moment-with-locales';
import { serverTimeFormat } from '~/library/core';

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import GenericList from '~/components/GenericList/GenericList';
import VisitorFilter from './VisitorFilter';

//styles
import '../../../styles/css/styles.css';

//this component for listing visitors//

@inject('userStore', 'commonStore', 'visitorStore')
@withRouter
@withLocalization
@withRemoveDialog
@observer
class VisitorTable extends Component {

    handleTableActionButtonAction = (id, type) => {
        if (type === 'delete') {
            //delete Visitor            
            this.removeVisitor(id);
        }        
    }    

    //calls action from visitor to delete visitor with id//
    removeVisitor(id) {
        const { commonStore, t } = this.props;

        return this.props.handleDelete(id, id => {
            return  this.props.visitorStore.deleteVisitorById(id).then((response) => {                
                if (response) {
                    commonStore.addNotification(t('Visitor Removed'), null, 'error');
                    this.props.visitorStore.resetLastListLoadTime();
                }
                return response;
            })
        });
    }

    renderTableActionButtons = (id) => {
        //renders table edit delete buttons in generic list//                        
        return (
            <div className="actions-center" style={{ justifyContent: 'center' }}>
                {/* <Button onClick={() => this.handleTableActionButtonAction(id, 'view')} bsStyle="primary" simple icon>
                    <i className="fa fa-eye" />
                </Button> */}
                <Button onClick={() => this.handleTableActionButtonAction(id, 'delete')} bsStyle="primary" simple icon>
                    <i className="fa fa-times" />
                </Button>
            </div>
        )
    }

    render() {
        //renders document_template list using generic list along with documentFilter//

        const { filters, loading, appliedFilters } = this.props.visitorStore;
        const { user_type } = this.props.userStore.currentUser;
        const { t } = this.props;
        return (
            <div className="main-content">
                {user_type === 'super-admin' ?
                    <div className="table-list">
                        <div className="row-flex input-area__content" style={{justifyContent:'center'}}>
                            <p className="text-danger">
                                {t('Data Prior To 14 Days Will Be Deleted By The System')}
                            </p>
                        </div>

                        <GenericList
                            columns={[
                                {
                                    Header: 'Date',
                                    id: 'created_at',
                                    accessor: ({ created_at }) => (
                                        <span>{created_at && moment(created_at).format(serverTimeFormat())}</span>
                                    )
                                },
                                {
                                    Header: 'Visitor Name',
                                    id: 'full_name',
                                    accessor: 'full_name'
                                },
                                {
                                    Header: 'Phone no',
                                    id: 'phone',
                                    accessor: ({ country_code, phone }) => (
                                        // <span>{country_code + " " + phone}</span>
                                        <span>{phone}</span>
                                    ),
                                    sortable: false,
                                },
                                {
                                    Header: 'Business name',
                                    id: 'visitor_business_name',
                                    accessor: 'visitor_business_name',
                                    sortable: false,
                                },
                                {
                                    Header: 'Client Name',
                                    id: 'client_name',
                                    accessor: 'client_name',
                                    sortable: false,
                                },                                
                                {
                                    Header: 'Actions',
                                    id: 'actions',
                                    sortable: false,
                                    accessor: (full) => this.renderTableActionButtons(full.id)
                                },
                            ]}

                            filters={appliedFilters}
                            lastListLoadTime={this.props.visitorStore.lastListLoadTime}
                            header={
                                <VisitorFilter
                                    filters={filters}
                                    setFilter={(name, value) => this.props.visitorStore.setFilter(name, value)}
                                    onFilter={() => this.props.visitorStore.onFilter()}
                                    loading={loading}
                                />
                            }
                            requestData={(params) => this.props.visitorStore.getVisitors(params)}
                        />
                    </div>
                    : <div>
                        <div className="row-flex input-area__content" style={{justifyContent:'center'}}>
                            <h4 className="text-danger">
                            {t("Unauthorized access! Please Contact Administrator.")}
                            </h4>
                        </div>                        
                    </div>}
            </div>
        )

    }
}

export default VisitorTable;