import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';

@inject('clientStore', 'commonStore', 'userStore')
@withRouter
@withLocalization
@observer
class kilometerRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kilometersRules : []
        }
        this.loadData = this.loadData.bind(this);   
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extendedRules){
            const extendedRules = Object.assign({}, currentEntity.client.data.extendedRules);
            this.setState({                
                kilometersRules: extendedRules.kilometersRules
            })
        }
    }

    render() {
        const { kilometersRules} = this.state;        
        return (
            <div>
                <TableWidget
                    headers={['Distance / Zone', 'Max KM']}
                    metaData = {[{field:'distance',type:'text',placeholder:'Distance / Zone', required:true},
                                {field:'max_km',type:'text',placeholder:'0', required:false}]}
                    data={kilometersRules}
                    onChange = {data => this.props.handleUserDataChange('kilometersRules',data)}
                />
            </div >
        );
    }
}
export default kilometerRules;
